// axios ajax 配置文件
import store from "@/store/index"
import {Message} from 'element-ui'; // 消息提示
import {getLocal} from '@/utils/util.js'
import { className } from "@antv/x6/lib/registry/highlighter/class";
// nprogress 配置
export const nprogressConfig = {
  showSpinner: false
}

// axios 的默认配置
export const defaultConfig = {
  // timeout:3000000, // 请求超时时间
  withCredentials:false // 是否携带 cookie
  // header:{},
}

// 添加验证请求头配置
export const authorization = {
  isAdd:(config) => true, // 是否添加下面的 header 数据，返回 boolean 值
  header:() => {
    const res = {I18n:transI18n(getLocal('i18nLocal'))}
    if(window.__POWERED_BY_WUJIE__){
      res['Blade-Auth'] = `Bearer ${store.getters.token}`
      res['Sunny-Auth'] = `Bearer ${store.getters.token}`
    }else{
      res['X-Authorization'] = `Bearer ${store.getters.token}`
    }
    return res;
  }
}

// 返回值处理配置
export const responseConfig = {
  statusKey:'status',
  dataKey:'result',
  messageKey:'msg',
  errorMsgKey:'msg',
  statusWhiteList:[], // 状态码 白名单
  returnData(resp){
    const config = resp.config;
    const isBlob = config.responseType === 'blob'
    return isBlob ? resp : resp.data 
  },
  return401(d){
    // const resp = d?.response || d;
    // const url = resp?.config?.url || "";
    // const bool = url.indexOf('/iot-api') != -1 && url.indexOf('/auth/login') == -1;
    // console.log('...................',d,url,bool)
    // bool && store.dispatch('logout');
  }, // 返回 401 状态码时的处理规则
}

// 错误相关配置
export const errorConfig = {
  // 请求发生错误的时候处理方法
  handle(data,resp,emsg,code){
    Message({message:emsg,type:'error'})
  },
}


// api url 常用前缀
export const URI = {
  system:'/iot-api/'
}

// 语言转换
const transI18n = (locale) => {
  switch(locale){
    case 'zh-cn':return 'cn';
    case 'en':return 'en';
  }
  return 'cn'
}


