import { deen } from "./default";

// 导出默认项
export const d = deen;
// 首页
export const homePage = {
  funcIntro: "Function Introduction",
  platformName: "Industrial Internet of things platform",
  desc:
    "The industrial Internet of things platform is a robust, scalable and user-friendly platform, which provides safe and reliable connection and communication capabilities for equipment, connects massive industrial equipment downward, and supports equipment data collection and data analysis; The cloud API is provided upward, and the server sends instructions to the device through remote call to realize remote control. The industrial Internet of things platform also provides other value-added capabilities, such as gateway, equipment management, rule engine, edge computing, visual reports, etc., enabling all kinds of IOT scenarios, industry customers and developers",
  funData1: {
    icon: "#icon-shebeijieru1",
    title1: "Device Access",
    title2:
      "The industrial Internet of things platform supports the connection of massive devices to the cloud, and the devices can communicate with the cloud stably and reliably.",
    content: [
      "Provide a gateway to integrate devices connected to traditional and third-party systems with h-iot.",
      " Provide 2G / 3G / 4G, Nb IOT, lorawan, Wi Fi and other different network equipment access solutions to solve the pain points of enterprise heterogeneous network equipment access management.",
      " The device SDK, which provides mqtt, COAP, http / s, opc-ua and other protocols, meets both the real-time requirements of long connections and the low-power requirements of short connections.",
    ],
  },
  funData2: {
    icon: "#icon-shebeiguanli1",
    title1: "Device Management",
    title2:
      "The industrial Internet of things platform provides complete equipment life cycle management functions, supporting equipment registration, function definition, data analysis, remote configuration, firmware upgrade, remote maintenance, real-time monitoring, group management, equipment deletion and other functions.",
    content: [
      "Provide equipment object model and trigger operation based on equipment life cycle events. For example, if the device is online / offline, create an alert.",
      "Copy telemetry or attributes from the device to related resources so that telemetry can be aggregated. For example, data from multiple devices can be aggregated into related assets.",
      "Provide data storage capacity to facilitate the storage and real-time access of users' massive equipment data.",
      " Provide equipment multi tenant management mechanism, safe and reliable.",
    ],
  },
  funData3: {
    icon: "#icon-shishixiangying",
    title1: "Rule Engine",
    title2:
      "The rule engine of industrial Internet of things platform is an easy-to-use framework for building event based workflow.",
    content: [
      "The operation is simple, and the event based workflow can be configured by dragging.",
      "Powerful nodes with a large number of special functions.",
      "Shortcut operations such as copy, import and export are supported. ",
    ],
  },
  funData4: {
    icon: "#icon-xuanzesuanfa",
    title1: "Edge Calculation",
    title2:
      "The edge computing of industrial Internet of things platform sinks the ability of the cloud to the edge side, and solves the problems encountered in edge real-time, reliability, operation and maintenance economy, etc. For operation and maintenance, the cloud provides integrated operation and maintenance tools, which can centralize operation and maintenance in the cloud, reduce operation and maintenance costs and improve operation and maintenance efficiency.",
    content: [
      "Rapid programming can improve the development of applications through scene rules, function calculation and stream data analysis, and deploy them to edge nodes.",
      "The equipment data can be aggregated, cleaned and graded locally. The processed data enters the big data platform for analysis and storage.",
    ],
  },
};
// 登录页
export const loginPage = {
  userLogin: "User Login",
  uMess: "Please enter user name",
  pMess: "Please enter password",
  login: "Login",
  forgetPass: "Forget Password",
  reAcc: "Registered Account",
  hanYun: {
    copyright:
        "Copyright: Jiangsu XCMG Hanyun Technology Co., Ltd.   Address: No. 6, Keji Road, economic and Technological Development Zone, Xuzhou, Jiangsu Province   Tel: 0516-80582888",
  },
  jiuYuan: {
    copyright:
        "Copyright: China Academy of Engineering Physics Mechanical Manufacturing Process Research Institute",
  },
  XCMG: "© XCMG",
  ICP: "Su ICP Bei 15005495-12",
  sys:
      "",
};

// 设备类型管理
export const clientTypePage = {
  addType: "Add Terminal Type",
  terType: "Terminal Type",
  typeDetail: "Terminal Type Details",
  netMode: "Networking Mode",
  conProt: "Connection Protocol",
  auMode: "Authentication Mode",
  dataFormat: "Data Format",
  max20: "The length cannot exceed 20 characters",
  proType: "Protocol type",
  max50: "The length cannot exceed 50 characters",
  mqtt: "MQTT  Topic List",
  customize: "Custom Parsing",
  property: "Basic Properties",
  addTheme: "Add Theme",
  modifyTheme: "Modify Theme",
  themeTab: "Theme Tab",
  theme: "Theme",
  opeAuth: "Operation Authority",
  basicPro: "Basic Properties",
  themeTabMsg: "Please enter numbers and letters within 20 digits_ combination",
  themeMsg:
    "The input cannot be blank characters such as spaces, tabs and line breaks, and the character length shall not exceed 200",
  max30: "The length cannot exceed 30 characters",
  themeFormat:
    'The topic format must be hierarchically divided by "/". "+" Wildcard for single topic level matching, "#" wildcard for any level in the topic, ${devicesn} universal terminal number.',
  paraCon: "Parameter Configuration",
  addPara: "Add Parameter",
  paraName: "Name",
  paraDes: "Description",
  unit: "Unit",
  paraClass: "Classification",
  editPara: "Edit Parameter",
  category: "category",
  inputLetDasUnder:
    "Only letters, numbers, dashes, and underscores can be entered",
  paraClassMain: "Parameter Classification Maintenance",
  currentLine: "There are currently edit lines",
  cannotEmpty: "Cannot be empty",
  paraClassName: "Parameter Classification Name",
  paraClassNotNull: "Parameter classification name cannot be empty!",
  paraClassLimit: "The parameter classification name cannot exceed 20 characters!"
};

// 设备管理
export const clientManagePage = {
  selTerType: "Please select the terminal type",
  terTotal: "Total Number of Terminals",
  terOnline: "Online Terminal",
  terOff: "Offline Terminal",
  allTerType: "All terminal types",
  addTer: "Add Terminal",
  terNumName: "Terminal No. / terminal name",
  authen: "authentication",
  terNum: "Terminal Number",
  terName: "Terminal Name",
  terType: "Terminal Type",
  accPro: "Access Protocol",
  dataFormat: "Data Format",
  lastOn: "Last online time",
  SIMNum: "SIM card number",
  isGateway: "Gateway",
  detInfor: "Detailed Information",
  attribute: "Attribute",
  latestTel: "Latest Telemetry",
  warning: "Warning",
  event: "Event",
  relation: "Relation",
  auditLog: "Audit Log",
  getListError: "Failed to get the list!",
  tokenError: "The access token was not modified",
  RSAError: "RSA public key not modified",
  succAuth: "Successfully modified authentication",
  basicPro: "Basic Properties",
  authMethod: "Authentication Method",
  accToken: "Access Token",
  max80: "The length cannot exceed 80 characters",
  RSAKey: "RSA Public Key",
  max50: "The length cannot exceed 50 characters",
  terNumMsg: clientTypePage.themeTabMsg,
  manageCer: "Management Certification",
  assetNum: "Asset Number",
  assetName: "Asset Name",
  assetType: "Asset Type",
  max20: "The length cannot exceed 20 characters",
  inCate: "Industry Category",
  inSecCate: "Industry Secondary Category",
  copyID: "Copy Device ID",
  copyToken: "Copy Access Token",
  deviceRange: "Device Attribute Range",
  searchInput: "Please enter the search criteria",
  addAttr: "Add Attribute",
  editAttr: "Edit Attribute",
  delNotNull: "Delete item cannot be empty",
  clientPro: "Client Properties",
  serverPro: "Server Properties",
  sharePro: "Share Property",
  lastUpdate: "Last Update Time",
  key: "Key",
  value: "Value",
  latestTele: "Latest Telemetry",
  activated: "Activated",
  cleared: "Cleared",
  answered: "Answered",
  notAnswered: "Not Answered",
  createTime: "Create Time",
  cause: "Cause",
  severity: "Severity",
  lifeEvents: "Lifecycle Events",
  typeSta: "Type Statistics",
  eventTime: "Event Time",
  server: "Server",
  method: "Method",
  assDir: "Association Direction",
  from: "from",
  to: "to",
  outAss: "Outward Association",
  inAss: "Inward Association",
  enType: "Entity Type",
  enName: "Entity Name",
  searchInputType: "Please enter the type query",
  time: "Time",
  user: "User",
  assType: "Association Type",
  addMess: "Additional information (JSON)",
  notNull: "Cannot be empty",
  addRelation: "add relation",
};
// 资产管理
export const sourceManagePage = {
  selSourceType: "Please select an asset type",
  totalAssets: "Total Assets",
  addAss: "Add Assets",
  assNumName: "Asset No. / Asset Name",
  assNum: "Asset Number",
  assName: "Asset Name",
  assType: "Asset Type",
  basicPro: "Basic Properties",
  max20: clientManagePage.max20,
  inCate: clientManagePage.inCate,
  inSecCate: clientManagePage.inSecCate,
  max50: clientManagePage.max50,
  allAssType: "All asset types",
  detInfor: "Detailed Information",
  attribute: "Attribute",
  latestTel: "Latest Telemetry",
  warning: "Warning",
  event: "Event",
  relation: "Relation",
  auditLog: "Audit Log",
};

// 接入网关管理
export const inGatewayManagePage = {
  addGateway: "Add Gateway",
  gateSerName: "Gateway service name",
  startUp: "start-up",
  startUpMsg: "is start?",
  stop: "stop",
  stopMsg: "is stop ? ",
  server: "server",
  container: "container",
  starting: "starting",
  notStart: "Not started",
  started: "Started",
  proType: "Protocol Type",
  terType: "Terminal Type",
  depLoc: "Deployment Location",
  IpDomain: "Ip/ domain name",
  portNum: "Port Number",
  inputGateName: "Please input the gateway service name",
  inputLetNumUn: "Only letters, numbers and underscores can be entered",
  max50: clientManagePage.max50,
  selProType: "Please select an protocol type",
  selTerType: "Please select the terminal type",
  inFileEncode: "Input file encoding search",
  selFile: "Please select a file",
  SDKFile: "SDK Files",
  selDepLoc: "Please select a deployment location",
  IPAndDomain: "IP (domain name)",
  selIPAndDomain: "Please select IP (domain name)",
  selPortNum: "Please select a port number",
  inExecute: "In execution",
  editGateway: "Edit Gateway",
};

// 规则链管理
export const ruleManagePage = {
  addRule: "Add Rule Chain",
  importRule: "Import Rule Chain",
  yes: "yes",
  no: "no",
  ruleName: "Rule Chain Name",
  isRootRule: "Root rule chain",
  isDebugMode: "Debug mode or not",
  setRoot: "set as root",
  design: "design",
  export: "export",
  inputRuleName: "Please input the rule chain name to search",
  dragJSON: "Drag the JSON file here, or",
  clickImport: "click import",
  name: "Name",
  max15: "The length cannot exceed 15 characters",
  enableDebug: "Enable debug mode",
  success: "Success",
  resetRoot: "Reset root node succeeded",
  fileFailCheck:
    "File data verification failed, Please check whether the data content is correct",
  JSONFailCheck:
    "JSON data conversion failed, please confirm whether the data is in JSON format!",
  editRule: "Edit Rule Chain",
  isRuleChain: "This rule chain is already the root rule chain",
};

// 规则链设计
export const ruleDesignPage = {
  saveData: "Save data",
  clearDebugSave: "Clear debug mode and save",
  nodeAttr: "Node Attributes",
  nodeEvents: "Node Events",
  helpInfo: "Help Information",
  edgeAttrEdit: "[edge] attribute editing",
  testScriptBasic: "Test script basic functions",
  pathOpt: "Path Options",
  noGraph: "No graph instance found!",
  start: "start",
  waveFilter: "Wave Filter",
  attrSet: "Attribute Set",
  trans: "Transformation",
  action: "Action",
  integrate: "Integrate",
  debugMode: "Enable debug mode",
  eventType: "Event Type",
  timeFrame: "Time Frame",
  lastDay: "Last Day",
  today: "Today",
  JSScript: "Please complete the JS script",
  direction: "direction",
  assType: "Association Type",
  dirFrom: "Direction: from",
  assFilter: "Association filter",
  entityType: "Entity type",
  receiveData: "receive data record",
  deviceRange: "Device attribute range",
  key: "key",
  value: "value",
  inputNum: "Only numbers can be entered",
  selIndModel: "Select industrial mechanism model",
  keyName: "key name",
  success: "success",
  fail: "fail",
  debug: "debug",
  typeSta: "type statistics",
  lifeEvents: "lifecycle events",
  error: "error",
  device: "device",
  assets: "assets",
  entView: "entity view",
  tenant: "tenant",
  customer: "customer",
  dashboard: "dashboard",
  acAns: "Activation answered",
  acNotAns: "Activation not answered",
  clearUnac: "Clear unacknowledged",
  clearAck: "Clear acknowledged",
  clientPro: "Client properties",
  serverPro: "Server properties",
  sharePro: "Share property",
  user: "user",
  ruleChain: "rule chain",
  ruleNode: "rule node",
  from: "from",
  to: "to",
  danger: "danger",
  important: "important",
  minor: "minor",
  warning: "warning",
  indeterminacy: "indeterminacy",
  external: "external",
  eventTime: "event time",
  server: "server",
  entity: "entity",
  messID: "message ID",
  messType: "message type",
  reType: "relationship type",
  data: "data",
  metadata: "metadata",
  messPro: "message processing",
  errOcc: "error occurred",
  event: "event",
  method: "method",
  message: "message",
  funBody: "function body",
  returnRes: "return result",
  group: {
    waveFilter: "",
    attrSet: "",
    trans: "",
    action: "",
    integrate: "",
  },
  funMsg: "msg",
  funMet: "metadata",
  funRes: "result",
  run: "run",
  searchNode: "search node",
};

// 审计日志
export const auditLogPage = {
  timeStamp: "Time Stamp",
  entityType: "Entity Type",
  entityName: "Entity Name",
  user: "User",
};

//文件资源管理
export const documentSourceManagePage = {
  documentCode: "Document Code",
  disable: "disable",
  enable: "enable",
  addFile: "Add File",
  fileType: "File Type",
  fileCode: "File Code",
  fileDes: "File Description",
  version: "Version",
  fileSize: "File Size",
  lastModTime: "Last Modification Time",
  inputLetDasUnder:
    "Only letters, numbers, dashes, and underscores can be entered",
  max50: clientManagePage.max50,
  fileVersion: "File Vision",
  fileVersionRule:
    "Enter a combination of letters, numbers, dots, and dashes, such as V1, v1.1, or V-1",
  fileUpload: "File Upload",
  fileUpError: "File not uploaded successfully or not uploaded",
  fileNotNull: "File content cannot be empty!",
  fileSizeEx: "File size cannot exceed 200M",
  editFile: "Edit File",
  uploadFile: "Upload File",
};

// 菜单项
export const sourceMenus = {
  home: "Home",
  terTypeManage: "Terminal Type Management",
  terManage: "Terminal Management",
  assManage: "Asset Management",
  accGateManage: "Access Gateway Management",
  ruleManage: "Rule Chain Management",
  auditLog: "Audit Log",
  fileResManage: "File Resource Management",
  driveManage: "Drive Management",
  modelDisManage: "Model Distribution Management",
  deviceStatusRule: "Device Status Rule Configuration",
  devTypeManage: "Device Type Management",
};

// 字典项
export const DICT = {
  // 设备管理 - 状态栏样式更改
  SYSTEM_COMPANY_STATUS: [
    { key: "0", value: "on line", className: "dot--success" },
    { key: "1", value: "offline", className: "dot--error" },
    // { key: 2, value: "禁用", className: "dot--error" },
    // { key: 3, value: "待审核", className: "dot--default" }
  ],
  SYSTEM_CPMPANY_STYLE: [
    { key: "0", value: "subscribe" },
    { key: "1", value: "publish" },
    { key: "2", value: "subscribe and publish" },
  ],
  SYSTEM_SOURCETYPE: [
    { key: "0", value: "add" },
    { key: "1", value: "synchronization" },
  ],
  DOCUMENT_TYPE: [
    { key: "0", value: "Access gateway jar package" },
    { key: "1", value: "Terminal upgrade package" },
    { key: "2", value: "Controller upgrade package" },
    { key: "3", value: "Display upgrade package" },
    { key: "4", value: "other" },
  ],
  DOCUMENT_STATUS: [
    { key: "0", value: "normal" },
    { key: "1", value: "disable" },
  ],
};

// 规则链节点配置
export const RULE_NODES = {
  Redis: {
    redisType: "redis type",
    standalone: "standalone",
    sharded: "Sharded",
    cluster: "cluster",
    maxTotal: "Number of jedis instances",
    maxIdle: "Maximum idle number",
    maxWaitMillis: "Maximum waiting time",
    testOnBorrow: "Whether the verification link is valid",
    testWhileIdle: "Whether to check if the link is valid when idle",
    password: "password",
    hostAndPortsNodes: "Cluster Configuration",
    HostAndPort: "IP address and port number",
    timeout: "timeout time",
    expireSecond: "Expiration time",
    dataStoreType: "Store Type",
  },
};

// 驱动管理
export const driveManagePage = {
  fileNotNull: "File content cannot be empty!",
  fileSizeEx: "File size cannot exceed 200M",
  driverPro: "driver name or protocol",
  issue: "issue",
  selGateway: "number of gateways selected",
  addDriver: "Add Driver",
  driverName: "driver name",
  protocol: "protocol",
  fileName: "file name",
  programClass: "program class name",
  version: "version",
  lastUpdateTime: "last update time",
  success: "success",
  fail: "fail",
  driveFile: "driver file",
  uploadFile: "upload file",
  fileNotUpload: "file not uploaded successfully or not uploaded",
  gatewayNum: "gateway number",
  gatewayName: "gateway name",
  issuedVersion: "issued version",
  issueResults: "issue results",
  editDriver: "Edit Driver",
  successDel: "Successfully deleted !",
  issueDrive: "issue driver",
  currentVersion: "current version",
  selAGateway: "please select a gateway",
  max50: "The length cannot exceed 50 characters",
  issueCom: "Issue completed",
  conPara: "configuration",
  driveDes: "drive description",
  fileMess: "The file content does not conform to the configuration specification!",
  viewCon: "view configuration"
};

// 模型下发管理
export const modelIssueManagePage = {
  issue: "Issue",
  selDevice: "number of devices selected",
  success: "success",
  fail: "fail",
  issueResults: "issue results",
  currentVersion: "current version",
  modelCNEN: "CN name / EN name",
  conDis: "configure and issue",
  modelConf: "model configuration",
  issueGateway: "issued by the gateway",
  save: "Save",
  cnName: "chinese name",
  enName: "english name",
  version: "version",
  modelDes: "model description",
  lastUpdate: "last updated",
  thrTrigger: "threshold trigger",
  thresholdMess: "Enter at least one lower threshold or upper threshold",
  lowerThr: "lower threshold",
  lowerMess: "Only integers and decimals can be entered",
  upperThr: "upper threshold",
  upperMess:
    "The upper threshold value cannot be less than the lower threshold value",
  thrDuration: "threshold duration",
  durMess: "Only numbers can be entered",
  triInter: "Trigger interface call",
  reqMode: "request mode",
  interAdd: "interface address",
  notNull: "Cannot be empty",
  gateNum: "gateway (device) number",
  gateName: "gateway (device) name",
  pointSel: "point selection",
  disVersion: "Distributed version",
  saveConfig: "Please save the model configuration first",
  selDeviceFir: "Please select a device first",
  operSucc: "operation succeeded",
  refresh: "Refresh",
  max50: "The length cannot exceed 50 characters",
  max30: "The length cannot exceed 30 characters",
  max10: "The length cannot exceed 10 characters",
};

// 设备状态规则配置
export const deviceStatusRulePage = {
  addStatusRule: "Add Status Rule",
  deviceType: "device type",
  deviceName: "device name",
  deviceNum: "device number",
  deviceStatus: "device status",
  confLevel: "config level",
  byDeviceType: "By Device Type",
  byDevice: "By Device",
  devNameNum: "device name(device number)",
  algFormula: "formula",
  auxDuration: "duration(S)",
  durMess: "Only integers and decimals can be entered",
  pointSel: "parameter",
  avaPoint: "parameters",
  addDeviceRule: "Add Device Status Configuration Rules",
  editDeviceRule: "Edit Device Status Configuration Rules",
  max50: "The length cannot exceed 50 characters",
  max10: "The length cannot exceed 10 characters",
  statusMaintenance: "equipment status maintenance",
  addStatus: "Add Status",
  addSubordinates: "Add Subordinates",
  calMethod: "calculation method",
  accFormula: "according to formula",
  byParentSibling: "by parent/sibling",
  byChild: "by child",
  add: "add",
  subStatus: "subordinate status",
  editStatus: "edit status",
  deviceNameNum: "device name/device number",
};
