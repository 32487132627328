import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
//
import i18n from "../i18n";
//
import {
  createRouterConfig,
  createDynamicRoutes,
  loginPath,
} from "@/config/router";
//
Vue.use(VueRouter);
// 路由实例创建方法
const createRouter = (routes) =>
  new VueRouter({ ...createRouterConfig, routes });
// 创建路由实例
const router = createRouter(createDynamicRoutes(store.getters.sourceMenus));
// 创建 重构路由方法并挂载到路由实例的原型链上
// 重置跳转到登陆页
router.__proto__.$goLogin = function () {
  router.push(loginPath);
};
router.__proto__.$rebuild = function () {
  const routes = createDynamicRoutes(store.getters.sourceMenus);
  const nr = createRouter(routes);
  this.matcher = nr.matcher;
};
// 重写 push 方法 避免 promise 对象异常
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) {
    return originalPush.call(this, location, resolve, reject);
  }
  return originalPush.call(this, location).catch((e) => {});
};
// 路由守护
router.beforeEach((to, from, next) => {
  console.log('====Router BeforeEach=====',to,from,router.getRoutes())
  if (to.matched.length == 0) {
    next("/");
    return;
  }
  // console.log('+++++')
  window.document.title = process.env.VUE_APP_NOT_SHOW_LOGO?i18n.t("d.jiuYuanTitle"):i18n.t("d.webTitle");
  // console.log(to,from)
  if (to.params && to.params.lang && to.params.lang != i18n.locale) {
    if (to.params.lang == ":lang") {
      next(to.fullPath.replace("/:lang", "/" + i18n.locale));
    } else {
      i18n.locale = to.params.lang;
      window.document.title = i18n.t("d.webTitle");
      next();
    }
    return;
  }

  if (to.fullPath === from.fullPath) {
    return;
  }
  console.log('=======next to==========',to.fullPath)
  next();
});

export default router;


