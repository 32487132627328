import {
  getContext,
  getInitialValue,
  supportVModelTag,
  getElFormItemProps,
  getChildTag,
} from "./formUtils";

export default {
  name: "item-table-item",
  props: {
    value:[Object,String,Array,Number,Boolean],
    scope: Object,
    formData: Object,
    formItemAttrs: Object,
    //以下为内部属性
    formItemData: [Object, Array],
  },
  data: () => ({
    itemValue: undefined,
  }),
  computed: {
    isFormItem() {
      const attr = this.formItemAttrs;
      const types = this.itemTypes;
      return !!attr.prop || !!attr.name || types.indexOf("formItem") !== -1;
    },
    isVModel() {
      const tag = this.itemTag;
      return supportVModelTag(tag);
    },
    itemTag() {
      const attr = this.formItemAttrs || {};
      const tag = attr.tag || "el-input";
      return tag;
    },
    itemProp() {
      const attr = this.formItemAttrs;
      return attr.prop || attr.name;
    },
    itemText() {
      const attr = this.formItemAttrs;
      return attr.text;
    },

    itemTypes() {
      const attr = this.formItemAttrs;
      const type = attr.formType || "";
      return type.split(",");
    },

    //
    childs() {
      const attr = this.formItemAttrs || {};
      const ptag = this.itemTag;
      const cls = attr.children || attr.cls || [];
      cls.forEach((child) => {
        child.tag = child.tag || this.utilTag(ptag);
        child.ctx = this.utilChildCtx(child);
      });
      return cls;
    },
  },
  methods: {
    init() {
      if (this.isFormItem) {
        // 初始化 value
        this.initValue();
      }
    },
    // 初始化 itemValue 保持强一致性
    initValue() {
      // this.itemValue = this.formItemAttrs.value ?? undefined;
      // const prop = this.itemProp;
      if (this.value != this.itemValue) {
        this.itemValue = this.value;
      }
      // console.log('=====',this.itemValue,this.formData,this.value)
    },
    // 表单元素触发的 handle Input 事件 仅支持 v-model 指令
    handleInput(v) {
      // console.log('-------------------------')
      // console.log(v)
      //
      this.itemValue = v;
      //
      const prop = this.itemProp || undefined;
      const d = { name: prop, value: v };
      this.emitFormItemChange(d);
    },
    // 表单元素触发的 blur 事件，用来支持单元素校验
    handleBlur() {
      const prop = this.itemProp;
      const value = this.itemValue;
      const d = { name: prop, value };
      this.emitFormItemBlur(d);
    },
    //
    // 对于普通 item 元素 不需要添加事件！
    // 需要添加事件的是  form-item 元素以及最终的表单元素
    // form-item 添加的是传递事件
    // 表单元素添加的是 v-model 绑定事件 和 blur 事件
    itemCtx() {
      //
      const attr = this.formItemAttrs;
      const { root, attrs, props, style, domProps } = getContext(attr);
      if (this.isVModel) {
        props.value = this.itemValue;
        root.on = root.on || {};
        root.on = {
          ...root.on,
          input: this.handleInput,
          blur: this.handleBlur,
        };
      }
      //
      return { ...root, attrs, props, style, domProps };
    },
    // form-item 触发的 handle form-item-change  事件
    handleFormItemChange(d) {
      if (this.isFormItem && this.itemProp) {
        const name = this.itemProp;
        const types = this.itemTypes || [];
        const type = types.indexOf("array") !== -1 ? "array" : "object";
        this.emitFormItemChange({ name, value: d, type });
      } else {
        this.emitFormItemChange(d);
      }
    },
    handleFormItemBlur(d) {
      if (this.isFormItem && this.itemProp) {
        const name = this.itemProp;
        const types = this.itemTypes || [];
        const type = types.indexOf("array") !== -1 ? "array" : "object";
        this.emitFormItemBlur({ name, value: d, type });
      } else {
        this.emitFormItemBlur(d);
      }
    },
    emitFormItemChange(d = {}) {
      const scope = this.scope || {};
      const attr = this.formItemAttrs || {};
      this.$emit('input',d.value)
      this.$emit("form-item-change", d, scope, attr);
    },
    emitFormItemBlur(d = {}) {
      const scope = this.scope || {};
      const attr = this.formItemAttrs || {};
      this.$emit("form-item-blur", d, scope, attr);
    },
    utilTag(ptag) {
      return getChildTag(ptag) || "el-input";
    },
    utilChildCtx(child) {
      // console.log('child - ctx =====')
      let ctx = {
        on: {
          "form-item-change": this.handleFormItemChange,
          "form-item-blur": this.handleFormItemBlur,
        },
      };
      // 默认属性
      let props = {};
      props.formData = this.formData;
      props.formItemAttrs = child;
      props.formItemData = this.formData;
      if (this.isFormItem && this.itemProp) {
        const prop = this.itemProp;
        const form = this.formData || {};
        const fd = form[prop];
        if (fd !== null && typeof fd === "object") {
          props.formItemData = fd;
        }
      }

      ctx.props = props;
      // slot
      const { root } = getContext(child);
      if (root.slot) {
        ctx.slot = root.slot;
      }
      if (root.scopeSlots) {
        ctx.scopeSlots = root.scopeSlots;
      }
      return ctx;
    },
  },
  created() {
    this.init();
  },
  mounted(){
    this.$nextTick(() => {
      if(this.isFormItem && this.itemProp && this.itemValue == undefined && this.formItemAttrs.value != undefined){
        this.handleInput(this.formItemAttrs.value)
      }
    })
  },
  watch: {
    value() {
      this.initValue();
    },
  },
  render(h) {
    // console.log('render ====== ')
    // 判断是否有 childs 渲染 childs
    let cds = this.itemText || undefined;
    const childs = this.childs;
    if (childs.length > 0) {
      cds = [];
      childs.forEach((child) => {
        cds.push(h("item-table-item", child.ctx));
      });
    }
    // 渲染 item
    const tag = this.itemTag;
    const ctx = this.itemCtx();
    return h(tag, ctx, cds);
  },
};
