// export const getObjType = (obj) => {
//   var toString = Object.prototype.toString;
//   var map = {
//     "[object Boolean]": "boolean",
//     "[object Number]": "number",
//     "[object String]": "string",
//     "[object Function]": "function",
//     "[object Array]": "array",
//     "[object Date]": "date",
//     "[object RegExp]": "regExp",
//     "[object Undefined]": "undefined",
//     "[object Null]": "null",
//     "[object Object]": "object",
//   };
//   if (obj instanceof Element) {
//     return "element";
//   }
//   return map[toString.call(obj)];
// };

// // 两对象深度合并
// export const concatObject = (o1 = {}, o2 = {}) => {
//   let res = { ...o1, ...o2 };
//   let arr = [{ obj1: o1, obj2: o2, robj: res }];
//   while (arr.length > 0) {
//     let { obj1, obj2, robj } = arr.pop();
//     let keys = Object.keys(obj1);
//     //
//     keys.forEach((k) => {
//       if (Object.prototype.hasOwnProperty.call(obj2, k)) {
//         let t1 = getObjType(obj1[k]);
//         let t2 = getObjType(obj2[k]);
//         if (t1 === t2 && (t1 === "array" || t1 === "object")) {
//           if (t1 === "array") {
//             robj[k] = Array.from(new Set([...obj1[k], ...obj2[k]]));
//           }
//           if (t1 === "object") {
//             robj[k] = { ...obj1[k], ...obj2[k] };
//             arr.push({ obj1: obj1[k], obj2: obj2[k], robj: robj[k] });
//           }
//         }
//       }
//     });
//   }
//   return res;
// };

// 树的遍历 - 递归 深度优先遍历
export const treeTraversal = (data, callback, cn = ["children", "cls"]) => {
  // 判断数据是否符合要求
  if (!Array.isArray(data) || data.length == 0) {
    return data;
  }
  // 解析 data
  for (let i = 0, len = data.length; i < len; i++) {
    const d = data[i];
    let isNext = true;
    if (callback && typeof callback === "function") {
      const res = callback(d, i, data);
      isNext = res === true || res === undefined;
    }
    if (!isNext) {
      break;
    }
    // 获取 子节点
    const ks = Object.keys(d);
    const ck = cn.find((k) => ks.indexOf(k) > -1);
    if (ck) {
      const c = d[ck];
      // 递归遍历
      treeTraversal(c, callback, cn);
    }
  }
};

// 解析属性，构造 context
const styleKeys = ["width","height","min-width","min-height","max-width","max-height","font-size","color","padding","margin","border","background","display","position"];
const attrKeys = ["id",'placeholder'];
const rootKeys = ["key","ref","refInFor","class","style","scopedSlots","directives","on","nativeOn","domProps","props","attrs","slot"];
const extsKeys = ["style", "attrs", "props", "tag", "children", "cls",'slotItem','formType','domProps','elFormItem'];
export const getContext = (attr = {}) => {
  const keys = Object.keys(attr);
  if(keys.length == 0){
    return {}
  }
  let root = {};
  let style = attr.style || {};
  let attrs = attr.attrs || {};
  let props = attr.props || {};
  let domProps = attr.domProps || {};
  for(let i = 0,len = keys.length;i<len;i++){
    const k = keys[i]
    const v = attr[k]
    if (extsKeys.indexOf(k) !== -1) {
      continue;
    }
    // style
    if (styleKeys.indexOf(k) !== -1) {
      style[k] = v;
      continue;
    }
    // attr
    if (attrKeys.indexOf(k) !== -1) {
      attrs[k] = v;
      continue;
    }
    // 非 props 属性
    if (rootKeys.indexOf(k) !== -1) {
      root[k] = v;
      continue;
    }
    //
    props[k] = v
  }
  return { root, style, attrs, props,domProps };
}

// 表单元素初始默认值
export const getInitialValue = (tag = 'el-input',attr = {}) => {
  let res = undefined;
  switch(tag){
    case 'el-input':res = '';break;
    case 'el-radio':res = '';break;
    case 'el-checkbox':res = '';break;
    case 'el-checkbox-group':res = [];break;
    case 'el-input-number':res = 0;break;
    case 'el-select':res = attr.multiple?[]:'';break;
    case 'el-cascader':res = [];break;
    case 'el-switch':res = undefined;break;
    case 'el-slider':res = 0;break;
    case 'el-rate':res = 0;break;
    case 'el-color':res = '';break;
    case 'el-transfer':res = [];break;
    // case 'el-time-picker':res = undefined;break;
    // case 'el-date-picker':res = undefined;break;
  }
  return res;
}

// 支持 v-model 指令的表单元素
const elTags = ['el-input','el-autocomplete','el-radio','el-radio-group','el-checkbox','el-checkbox-group','el-input-number','el-select','el-cascader','el-switch','el-slider','el-time-picker','el-time-select','el-date-picker','el-rate','el-color-picker','el-transfer']
const selfTags = ['self-select','upload-avatar','item-table','els-monaco-editor']
export const supportVModelTag = (tag) => {
  return elTags.includes(tag) || selfTags.includes(tag);
}

// 获取 el-form-item 的属性
const elFormItemProps =  ['label','prop','labelWidth','required','rules','error','showMessage','inlineMessage','size']
const elFormItemRoots = ["key","ref","refInFor","class","style","scopedSlots","directives","on","nativeOn","domProps","props","attrs","slot"];
const elFormItemAttrs = ["id",'placeholder'];
export const getElFormItemContext = (attr = {}) => {
  const d = attr.elFormItem || {}
  let props = d.props || {};
  let attrs = d.attrs || {};
  let roots = {};
  elFormItemProps.forEach(k => {
    if(Object.prototype.hasOwnProperty.call(attr,k)){
      props[k] = attr[k]
    }
    if(Object.prototype.hasOwnProperty.call(d,k)){
      props[k] = attr[k]
    }
    if(attr.elFormItemLabel && typeof attr.elFormItemLabel === 'string'){
      props.label = attr.elFormItemLabel
    }
  })
  Object.keys(d).forEach(k => {
    if(elFormItemRoots.indexOf(k) !== -1){
      roots[k] = d[k]
    }
    if(elFormItemAttrs.indexOf(k) !== -1){
      attrs[k] = d[k]
    }
  })
  return {...roots,props,attrs};
}

// 通过父元素 tag 标签匹配 子元素标签
const parentTags = {
  'el-radio-group':'el-radio',
  'el-checkbox-group':'el-checkbox',
  'el-select':'el-option',
  'ul':'li',
  'ol':'li'
}
export const getChildTag = (parentTag) => {
  if(Object.prototype.hasOwnProperty.call(parentTags,parentTag)){
    return parentTags[parentTag]
  }
  return undefined
}

// 表单数据解析
// 
export const getFormValue = (d = {},form = {}) => {
  let p = form
  let t = d;
  const has = Object.prototype.hasOwnProperty
  let condition = true;
  while(condition){
    const k = t.name;
    const v = t.value;
    const tp = t.type || 'object'
    const isNext = v != undefined && typeof v === 'object' && has.call(v,'name') && has.call(v,'value')
    if(isNext){
      const isHas = has.call(p,k) && typeof p[k] === 'object'
      if(!isHas){
        p[k] = tp === 'array'?[] : {}
      }
      p = p[k]
      t = v;
    }else if(v != undefined){
      const isArray = Array.isArray(p);
      if(isArray){
        if(k && /^\d+$/.test(k)){
          p[Number(k)] = v
        }
        else if(k){
          let tv = {}
          tv[k] = v
          p.push(tv)
        }else{
          p.push(v)
        }
      }else{
        p[k] = v
      }
      condition = false;
    }else{
      // 当 v == undefined || null 时 删除属性
      delete p[k]
    }
  }
}

// 通过 prop 获取 rule 规则、
export function getPropByPath(obj, path, strict) {
  let tempObj = obj;
  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');

  let keyArr = path.split('.');
  let i = 0;
  for (let len = keyArr.length; i < len - 1; ++i) {
    if (!tempObj && !strict) break;
    let key = keyArr[i];
    if (key in tempObj) {
      tempObj = tempObj[key];
    } else {
      if (strict) {
        throw new Error('please transfer a valid prop path to form item!');
      }
      break;
    }
  }
  return {
    o: tempObj,
    k: keyArr[i],
    v: tempObj ? tempObj[keyArr[i]] : undefined
  }
}