<template>
  <span :class="[className || '',{'dict-item--link':link,'dict-item--path':path}]" @click.stop="handleClick">
    <i v-if="icon" :class="icon"></i>
    <span v-if="label || defaultValue">{{ label || defaultValue || '' }}</span>
  </span>
</template>

<script>
export default {
  name: 'dict-item',
  props: {
    label: [String, Number],
    value: [String, Number, Boolean, Function, Object, Array, Symbol],
    defaultValue: [String, Number, Boolean],
    className: String,
    link: String,
    path: Object,
    icon: String,
  },
  methods: {
    handleClick() {
      const link = this.link
      if (link) {
        window.open(link)
      }
      const path = this.path
      if (path) {
        this.$router.push({ ...path })
      }
    }
  },
  created() {
   // console.log(this.label)
  }
}
</script>