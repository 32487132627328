import http from "@/axios/index"
import { URI } from "@/config/axios"
import Mock from "mockjs-x";
import i18n from "@/i18n";

export const login = (d = {}) => {
  return http({
    url: URI.system + 'api/auth/login',
    method: 'post',
    data: d
  })
}

export const getMenu = async () => {
  // return http({
  //   url: URI.system + 'api/get-menu',
  //   method: 'get',
  // })
  
  const result = await mockMenu(Mock)
  const res = { status: 200, success: true, msg: "操作成功",result}
  return res;
}

export const getUserInfo = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
  const obj = JSON.parse(window.atob(base64));
  const uid = obj.userId;
  return http({
    url: URI.system + 'api/user/' + uid,
    method: 'get',
  })
}

export const getVerifyCode = () => {
  return http({
    url: URI.system + 'api/get-verify-code',
    method: 'get',
  })
}

export const getAuthInfo = () => {
  return http({
    url: URI.system + 'api/auth/user',
    method: 'get',
  })
}

const mockMenu = async ( Mock) => [
  {
    id: Mock.mock("@id"),
    type: "menu",
    path: "/:lang/hanyun-iot",
    name: "hanyunIot",
    redirect: "/:lang/hanyun-iot/home",
    component: "views/container/base-container",
    children: [
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: i18n.messages[i18n.locale]["sourceMenus"]["home"],
        path: "home",
        name: "Home",
        icon: "el-icon-house",
        component: "views/home/home",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel:
          i18n.messages[i18n.locale]["sourceMenus"]["terTypeManage"],
        path: "client-type",
        icon: "el-icon-mobile-phone",
        name: "clientType",
        component: "views/manager/client-type-manager",
        meta: { keepAlive: false },
      },
      // {id:Mock.mock('@id'),type:'menu',show:false,menuLabel:'终端详情',path:'client-type/client-desc',icon:'el-icon-menu',name:'clientDesc',component:'views/manager/client-desc-type'},
      // {id:Mock.mock('@id'),type:'menu',show:false,menuLabel:'新增终端型号',path:'client-type/client-add',icon:'el-icon-menu',name:'clientAdd',component:'views/manager/client-manager-add'},
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: i18n.messages[i18n.locale]["sourceMenus"]["terManage"],
        path: "client",
        name: "client",
        icon: "el-icon-monitor",
        component: "views/manager/client-manager",
        meta: { keepAlive: false },
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: i18n.messages[i18n.locale]["sourceMenus"]["assManage"],
        path: "source",
        name: "source",
        icon: "el-icon-coin",
        component: "views/manager/source-manager",
        meta: { keepAlive: false },
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel:
          i18n.messages[i18n.locale]["sourceMenus"]["accGateManage"],
        path: "in-gateway",
        name: "inGateway",
        icon: "el-icon-cpu",
        component: "views/manager/in-gateway-manager",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: i18n.messages[i18n.locale]["sourceMenus"]["ruleManage"],
        path: "rules",
        name: "rules",
        icon: "el-icon-connection",
        component: "views/manager/rules-manager",
        meta: { keepAlive: false },
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: "规则链设计",
        show: false,
        path: "rule-design",
        name: "ruleDesignPage",
        icon: "el-icon-connection",
        component: "views/manager/rule-design-page",
        meta: { keepAlive: false },
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: i18n.messages[i18n.locale]["sourceMenus"]["auditLog"],
        path: "audit",
        name: "audit",
        icon: "el-icon-tickets",
        component: "views/logs/audit-logs",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel:
          i18n.messages[i18n.locale]["sourceMenus"]["fileResManage"],
        path: "document-source-manager",
        name: "documentSource",
        icon: "el-icon-s-data",
        component: "views/manager/document-source-manager",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: i18n.messages[i18n.locale]["sourceMenus"]["driveManage"],
        path: "drive-manager",
        name: "driveManager",
        icon: "el-icon-bangzhu",
        component: "views/manager/drive-manager",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel:
          i18n.messages[i18n.locale]["sourceMenus"]["modelDisManage"],
        path: "model-issue-manager",
        name: "modelIssueManager",
        icon: "el-icon-sold-out",
        component: "views/manager/model-distribution-manager",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel:
          i18n.messages[i18n.locale]["sourceMenus"]["deviceStatusRule"],
        path: "device-status-rule",
        name: "deviceStatusRule",
        icon: "el-icon-notebook-2",
        component: "views/manager/device-status-rule",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: "设备告警规则配置",
        path: "device-alarm-rule-config",
        name: "deviceAlarmRuleConfig",
        icon: "el-icon-box",
        component: "views/manager/alarm-rule-config",
      },
      {
        id: Mock.mock("@id"),
        type: "menu",
        menuLabel: "告警码管理",
        show: false,
        path: "device-alarm-code-manage",
        name: "deviceAlarmCodeManage",
        icon: "el-icon-box",
        component: "views/manager/alarm-code",
      },
    ],
  },
]


