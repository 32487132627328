export const nodeShapes = {
  "ACTION-TbSynchronizationBeginNode": "",
  "ACTION-DataReciveNode": "",
  "ACTION-TbMsgAttributesNode": "",
  "ACTION-TbCreateRelationNode": "",
  "ACTION-TbMsgGeneratorNode": "",
  "ACTION-TbMsgDelayNode": "",
  "ACTION-TbCreateAlarmNode": "",
  "ACTION-Xm2mParserNode": "",
  "ACTION-TbLogNode": "",
  "ACTION-TbUnassignFromCustomerNode": "",
  "ACTION-TbCopyAttributesToEntityViewNode": "",
  "ACTION-TbClearAlarmNode": "",
  "ACTION-TbSaveToCustomCassandraTableNode": "",
  "ACTION-TbCheckpointNode": "",
  "ACTION-LogDeviceUpDownNode": "",
  "ACTION-TbGpsGeofencingActionNode": "",
  "ACTION-OfflineRequestsSendToDeviceNode": "",
  "ACTION-TbSendRPCRequestNode": "",
  "ACTION-TbSendRPCReplyNode": "",
  "ACTION-TbMsgCountNode": "",
  "ACTION-TbAckNode": "",
  "ACTION-TbSynchronizationEndNode": "",
  "ACTION-TbMsgTimeseriesNode": "",
  "ACTION-TbAssignToCustomerNode": "",
  "ACTION-TbDeleteRelationNode": "",
  "FILTER-TbJsFilterNode": "",
  "FILTER-TbGpsGeofencingFilterNode": "",
  "FILTER-TbCheckAlarmStatusNode": "",
  "FILTER-TbMsgTypeSwitchNode": "",
  "FILTER-TbMsgTypeFilterNode": "",
  "FILTER-TbJsSwitchNode": "",
  "FILTER-TbCheckMessageNode": "",
  "FILTER-TbOriginatorTypeFilterNode": "",
  "FILTER-TbOriginatorTypeSwitchNode": "",
  "FILTER-TbCheckRelationNode": "",
  "ENRICHMENT-TbGetTenantDetailsNode": "",
  "ENRICHMENT-TbGetOriginatorFieldsNode": "",
  "ENRICHMENT-TbGetRelatedAttributeNode": "",
  "ENRICHMENT-TbGetAttributesNode": "",
  "ENRICHMENT-TbGetCustomerAttributeNode": "",
  "ENRICHMENT-TbGetTenantAttributeNode": "",
  "ENRICHMENT-TbGetTelemetryNode": "",
  "ENRICHMENT-TbGetDeviceAttrNode": "",
  "ENRICHMENT-TbGetCustomerDetailsNode": "",
  "EXTERNAL-TbRabbitMqNode": "",
  "EXTERNAL-pubsub.TbPubSubNode": "",
  "EXTERNAL-TbSendEmailNode": "",
  "EXTERNAL-TbKafkaNode": "",
  "EXTERNAL-sqs.TbSqsNode": "",
  "EXTERNAL-TbShuMengNode": "",
  "EXTERNAL-TbMqttNode": "",
  "EXTERNAL-TbRestApiCallNode": "",
  "EXTERNAL-azure.TbAzureIotHubNode": "",
  "EXTERNAL-sns.TbSnsNode": "",
  "TRANSFORMATION-TbMsgToEmailNode": "",
  "TRANSFORMATION-TbChangeOriginatorNode": "",
  "TRANSFORMATION-TbTransformLongNode": "",
  "TRANSFORMATION-TbTransformMsgNode": "",
  "ACTION-LoraParserNode": "",
};
