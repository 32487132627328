const state = {
  row: "new ",
  isOnload: false
}

const getters = {
  getRow: state => state.row,
}

const mutations = {
  setRow(state, val) { state.row = val },
  changeOnload(state, val) { state.isOnload = val }
};


export default { namespaced: true, state, getters, mutations }