// 菜单树解析规则方法
import {treeAllPath,treeFilter} from "./tree"
// 从菜单树中剥离出 buttons 并生成按钮权限数据 利用 path 生成 key 
export const getButtons = (tree) => {
  const paths = treeAllPath(tree);
  const buttons = paths.filter(items => items[0].type === 'button')
  const res = {}
  buttons.forEach(items => {
    const btn = items[0]
    const name = btn.name;
    const ps = [];
    for(let i = 1,l = items.length; i < l;i++){
      const {path} = items[i]
      ps.push(path)
    }
    ps.push(name)
    const k = ps.join('.')
    res[k] = btn;
  })
  return res;
}
// 从菜单树中提取出菜单并按照要求返回对应的菜单数据

export const getMenus = (tree) => {
  const fn = (n) => n.type === 'menu'
  return treeFilter(tree,fn)
}