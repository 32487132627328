import { nodeIcons } from "./icons";
import { nodeShapes } from "./shapes";
import { GROUPS, createNode ,DEFAUT_EDGE_EDIT,DEFAUT_EDGE_STATIC,DEFAULT_INPUT_ID} from "./cells";
import { createList } from "./nodeList";
import {names} from "./nodeNames"
//
import { merge } from "lodash";
import i18n from "../../../i18n"
const lan = i18n.messages[i18n.locale]

export const parseNodes = (nodes) => {
  const LABELS = {
    FILTER: i18n.messages[i18n.locale]['ruleDesignPage']['group']['waveFilter'] + '  FILTER',
    ENRICHMENT: i18n.messages[i18n.locale]['ruleDesignPage']['group']['attrSet'] + '  ENRICHMENT',
    TRANSFORMATION: i18n.messages[i18n.locale]['ruleDesignPage']['group']['trans'] + '  TRANSFORM',
    ACTION: i18n.messages[i18n.locale]['ruleDesignPage']['group']['action'] + ' ACTION',
    EXTERNAL:i18n.messages[i18n.locale]['ruleDesignPage']['group']['integrate'] + ' EXTERNAL',
  }
  const res = {ruleForm:{},ruleNodes:[],ruleList:[],ruleDirective:{}}
  const temp = {};
  nodes.forEach((node) => {
    const {
      type,
      name,
      label,
      clazz,
      actions,
      configurationDescriptor,
      id,
    } = node;
    const key = clazz;
    const shape = (nodeShapes[key] || "edit") + "-" + key;
    const nodeDefinition = configurationDescriptor.nodeDefinition;
    const configDirective = nodeDefinition.configDirective;
    //
    const icon = nodeIcons[key] || "el-icon-s-help";
    // 节点
    const cell = createNode(key, name, type, nodeDefinition);
    //
    res.ruleNodes.push({ name: shape, cell });
    //
    if(!res.ruleDirective[configDirective]){
      res.ruleDirective[configDirective] = []
    }
    res.ruleDirective[configDirective].push(`${type}.${name}`)
    // 节点列表
    const btn = createList(name, shape, icon, type,label || name);
    if (!temp[type]) {
      temp[type] = [];
    }
    temp[type].push(btn);
    // 表单原数据
    res.ruleForm[shape] = {
      relationTypes: nodeDefinition.relationTypes,
      ...node,
    };
  });
  res.ruleList = GROUPS.map((k) => ({
    type: "group",
    label: LABELS[k],
    children: temp[k],
  }));

  //
  return res;
}

const inputNode = {
  id:DEFAULT_INPUT_ID,
  shape: "input-static",
  label: lan['ruleDesignPage']['start'],
  icon: "el-icon-arrow-right",
  data:{static:true}
}
export const parseMetadata = (data) => {
  const res = {edges:[],nodes:[inputNode],form:{}}
  const {connections=[],firstNodeIndex,nodes,ruleChainConnections,ruleChainId} = data;
  if(!nodes || nodes.length == 0) {return res;}
  const idTemp = []
  const edgeTemp = {}
  // 生成 nodes
  nodes.forEach((node,ins) => {
    const {additionalInfo,configuration,debugMode,type,name,id} = node;
    const {layoutX:x,layoutY:y,description} = additionalInfo
    // const shape = (nodeShapes[type] || "edit") + "-" + type;
    const shape = "edit" + "-" + type;
    const tn = names[type]
    const info = tn && tn != name ? tn : ''
    //
    idTemp.push(id.id)
    //
    res.nodes.push({id:id.id,x,y,shape,label:name,info,data:{events:true}})
    //
    res.form[id.id] = {configuration,debugMode,description}
  })
  //
  if(connections){
    connections.forEach(conn => {
      const {fromIndex,toIndex,type} = conn;
      const key = `${fromIndex}-${toIndex}`
      if(edgeTemp[key]){edgeTemp[key].labels.push(type)}
      else{edgeTemp[key] = {fromIndex,toIndex,labels:[type]}}
    })

    Object.keys(edgeTemp).forEach(k => {
      const {fromIndex,toIndex,labels} = edgeTemp[k]
      //
      const inId = idTemp[toIndex];
      const outId = idTemp[fromIndex]
      const id = `edge-${outId}-${inId}`
      const shape = DEFAUT_EDGE_EDIT
      //
      const label = Array.from(new Set(labels))
      res.edges.push({source:{cell:outId,port:'port-out'},target:{cell:inId,port:'port-in'},id,shape,label:label.join(',')})
      res.form[id] = {label:[...label]}
    })
  }



  if(firstNodeIndex != undefined){
    const outId = DEFAULT_INPUT_ID;
    const inId = idTemp[firstNodeIndex]
    const shape = DEFAUT_EDGE_STATIC;
    //
    res.edges.push({source:{cell:outId,port:'port-out'},target:{cell:inId,port:'port-in'},shape})
  }
  //
  // console.log('====',res)
  return res
}

export const parseGraphData = (data,forms,debugModel) => {
  const {nodes,edges,firstNode,ruleChainId} = data;
  //
  const metadata = {nodes:[],connections:[],firstNodeIndex:null,ruleChainConnections:null,ruleChainId:{entityType:'RULE_CHAIN',id:ruleChainId}}
  const ids = []
  //
  if(!nodes || nodes.length == 0) return metadata;
  nodes.forEach((node,ins) => {
    const {id,x,y,label,clazz} = node;
    const form = forms[id] || {configuration : {}};
    const debugMode = debugModel == null ? form.debugMode || false : debugModel;
    const description = form.description || ''
    //
    const configuration = merge({},form.configuration)
    //
    const additionalInfo = {layoutX:x,layoutY:y,description}
    //
    const mn = {additionalInfo,type:clazz,name:label,debugMode,configuration}
    //
    ids.push(id)
    //
    metadata.nodes.push(mn)
    //
    if(firstNode === id) { metadata.firstNodeIndex = ins }
  })

  if(edges){
    edges.forEach(edge => {
      const {id,source,target} = edge;
      const fromIndex = ids.indexOf(source);
      const toIndex = ids.indexOf(target)
      const labels = forms[id].label;
      if(labels && labels.length > 0){
        labels.forEach(type => {
          metadata.connections.push({fromIndex,toIndex,type})
        })
      }
    })
  }

  //console.log('---------',data,forms,metadata)
  return metadata;
}


export const arrayToTree = (list = [],level = []) => {
  const len = level.length;
  // 值缓存
  const tls = level.map(() => []);
  // 节点引用缓存
  const nds = level.map(() => []);
  //
  const tree = [];
  //
  list.forEach(item => {
    const n = {...item};

    const ls = level.map(k => {
      const v = n[k]
      delete n[k]
      return v;
    })
    //
    let pn = {children:tree}
    ls.forEach((v,ins) => {
      if(tls[ins].includes(v)){
        const i = tls[ins].indexOf(v);
        pn = nds[ins][i]
      }
      else{
        const k = level[ins];
        const node = ins == len -1 ? {...n} : {children:[]}
        // node[k] = v;
        node.label = v;
        node.value = v;
        // node.key = k;
        //
        tls[ins].push(v);
        nds[ins].push(node)
        //
        if(ins == 0){
          pn = {children:tree}
          pn.children.push(node);
        }else{
          pn.children.push(node)
        }
        pn = node;
      }
    })
  })
  //
  return tree;
}
