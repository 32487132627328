export default {
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 15,
        pageSizes: [5, 10, 15, 20, 30, 50, 100],
        total: 0,
        layouts: ['total, sizes, prev, pager, next', 'total,prev, pager, next', 'prev, pager, next']
      },
      query: {},
      mixinsTableOption: {
        border: true,
        stripe: true,
        highlightCurrentRow: true,
        'headerCellStyle': { height: '53px' },
        'rowStyle': { height: '48px' },
        cellStyle: { padding: '0px', },
      },
      mixinsColumnOption: {
        showOverflowTooltip: true,
        headerAlign: 'left',
        resizable: false
      }
    }
  },
  computed: {

  },
  methods: {
    // 交给实际组件实现的方法
    onLoad(page = this.page, params = {}) { },
    //
    handleCurrentChange(n) {
      // this.page.pageNum = n;
      //
      this.onLoad({ pageNum: n, pageSize: this.page.pageSize }, this.query);
    },
    handleSizeChange(size) {
      this.page.pageSize = size;
      const total = this.page.total
      const max = Math.ceil(total / size);
      if (this.page.pageNum > max) {
        this.page.pageNum = max;
      }
      //
      this.onLoad(this.page, this.query)
    },
    mixinsIndex(ins) {
      const n = this.page.pageNum;
      const s = this.page.pageSize;
      return (n - 1) * s + ins + 1
    }
  }
}