//  通用，零散 工具方法
import { saveAs } from "file-saver";
// 判断是否是 url
export const isURL = (s) => /^http[s]?:\/\/.*/.test(s);

// localstorage 保存数据
export const saveLocal = (key, val) => {
  const t = typeof val;
  if (t === "object" && val != undefined) {
    localStorage.setItem(key, JSON.stringify(val));
  } else {
    localStorage.setItem(key, val);
  }
};

// 获取 localstorage 保存的数据 type 可以用来对数据做初步类型转换 目前仅支持 json对象转换
export const getLocal = (key, type) => {
  const r = localStorage.getItem(key) || undefined;
  if (type == "object" && r) {
    return JSON.parse(r);
  }
  return r;
};

// 清空 localstorage
export const clearLocal = () => {
  localStorage.clear();
};

const getType = (o) => {
  const t = Object.prototype.toString.call(o);
  return /^\[object (.*)\]$/.exec(t)[1].toLowerCase();
};
// 对象深度克隆
export const clone = (obj = {}) => {
  const f = (o) => {
    const t = getType(o);
    const r = t == "array" ? [] : {};
    if (t == "array") {
      o.forEach((v) => {
        const t = getType(v);
        if (t == "object" || t == "array") {
          r.push(f(v));
        } else {
          r.push(v);
        }
      });
    } else if (t == "object") {
      Object.keys(o).forEach((k) => {
        const v = o[k];
        const t = getType(v);
        if (t == "object" || t == "array") {
          r[k] = f(v);
        } else {
          r[k] = v;
        }
      });
    }
    //
    return r;
  };

  return f(obj);
};

// 对象深度合并
export const combine = (m = {}, n = {}) => {
  //
  const res = clone(m);
  const has = Object.prototype.hasOwnProperty;

  const f = (r, s) => {
    const is = typeof r == typeof s && typeof r == "object";
    if (!is) return s;
    const t = Array.isArray(r) ? "array" : "object";
    //
    if (t == "array") {
      return [...r, ...s];
    } else {
      Object.keys(s).forEach((k) => {
        const b = has.call(r, k);
        const v = s[k];
        if (!b) {
          r[k] = v;
        } else {
          r[k] = f(r[k], v);
        }
      });
      return r;
    }
  };
  //
  return f(res, n);
};
// 防抖
export const debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};

// 获取对象属性
export const getObjValue = (key = "", obj = {}) => {
  if (!key) return undefined;
  const ks = key.split(".");
  if (ks.length == 0) return undefined;
  let res = obj;
  ks.forEach((k) => {
    if (res && typeof res == "object") {
      res = res[k];
    } else {
      res = undefined;
    }
  });
  return res;
};

// 从 v-bind:key 或者 :key 中获取值
export const getBindValue = (key, val, obj) => {
  const reg = /^\s?(v-bind)?:(\w+)\s?$/;
  let res = [];
  if (reg.test(key)) {
    const k = reg.exec(key)[2];
    const v = getObjValue(val, obj);
    res = [k, v];
  } else {
    res = [key, val];
  }
  return res;
};

// 解析 token
export const parseToken = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replaceAll("-", "+").replaceAll("_", "/");
  const obj = JSON.parse(window.atob(base64));
  return obj;
};

export const isAdmin = (userInfo) => {
  return userInfo.scopes[0] == "SYS_ADMIN";
};

/* 下载文件 使用 axios  */
const FILENAME_REG = /filename\s*=\s*['"]?([\w.]+)['"]?\s*/;
export const downloadFile = async (call,args = [],defName = '') => {
  const res = await call(...args);
  const fileInfo = res.headers["content-disposition"] || "";
  const name = defName || FILENAME_REG.exec(fileInfo)?.[1];
  const blob = res.data;
  //
  downloadBlob(blob,name)
  // saveAs(url, name);

};

export const downloadFileWithName = async (call, name, ...args) => {
  const res = await call(...args);
  const blob = new Blob([res]);
  // saveAs(blob, name);
  //
  downloadBlob(blob,name)
};

export const downloadBlob = (blob,name) => {
  const url = URL.createObjectURL(blob);
  downloadFromUrl(url,name)
}

export const downloadFromUrl = (url,name) => {
  const ea = document.createElement('a')
  ea.href=url;
  ea.download = name //file.name
  ea.style.display = 'none'
  ea.click();
}


//
import { get } from "loadsh";
const TEMP_REG = /\{\s*([\w.-]+)\s*\}/g;
export const tempReplace = (temp, source) => {
  let res = temp;
  let t;
  while ((t = TEMP_REG.exec(temp)) != null) {
    const [s, k] = t;
    const v = get(source, k);
    if (v != undefined) {
      res = res.replace(s, v);
    }
  }
  return res;
};


