<template>
    <div class="self-upload-simple">
        <!--    <el-input v-model="path" :size="size" disabled></el-input>-->
        <el-upload
                :action="uploadAction"
                class="self-upload--"
                :headers="headers"
                :accept="accept" :file-list="uploadFileList" :on-preview="handlePreview"
                :before-upload="handleBeforeUpload" :limit="limit"
                :on-error="handleError" :on-remove="handleRemove"
                :disabled="loading" :http-request="handleUploadFile" :on-exceed="handleExceed"
                :on-success="uploadSuccess"
        >
          <div class="_button">
            <el-button type="primary" :size="size" :icon="btnIcon" :loading="loading">
                {{ btnText }}
            </el-button>
            <div>
              <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage" status="success"></el-progress>
            </div>

          </div>

            <div slot="tip" class="el-upload__tip">{{this.fileLimitMess}}</div>
        </el-upload>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import {uploadFile, ftpUploadFile} from "@/api/com/iot-file.js"

    export default {
        name: "self-upload",
        props: {
            value: String,
            action: {
                type: String,
                default: ""
            },
            accept: {type: String, default: ".jar"},
            btnText: {type: String, default: "上传文件"},
            btnIcon: {type: String, default: 'el-icon-folder-opened'},
            size: String,
            dataKey: {type: String, default: "result.url"},
            beforeUpload: Function,
            limitSize: Number, // MB
            fileList: {type: Array, default: () => []},
            limit: {type: Number, default: 1},
            fileLimitMess: {type: String, default: ""},
            resultKey:{type:String,default:"fileName"},
            ftpRequest: {type: Boolean, default: false},
            uploadSuccess: Function,
        },
        data() {
            return {
                uploadAction: this.action || "",
                path: this.value || "",
                loading: false,
                uploadFileList: [],
                percentage:0,
            };
        },
        computed: {
            ...mapState({
                token: state => state.token
            }),
            headers() {
                return {
                    Authorization: "Bearer " + this.token
                };
            }
        },
        methods: {
            handleSuccess(res) {
                this.loading = false;
                if (res.msg) {
                    this.$message({type: "success", message: res.msg});
                }
                const ks = this.dataKey.split(".");
                let temp = res;
                ks.forEach(k => {
                    if (temp[k]) {
                        temp = temp[k];
                    }
                });
                if (typeof temp === "string") {
                    // temp.replace('http://10.')
                    this.path = temp;
                    this.$emit("input", temp);
                } else {
                    // console.log(res);
                    // console.log(temp);
                }
            },
            handleError(err) {
                this.loading = false;
                this.$message({
                    type: "error",
                    message: String(err)
                });
            },
            handleBeforeUpload(file) {
                // console.log(file);
                if (this.beforeUpload) {
                    const b = this.beforeUpload(file);
                    if (b) {
                        this.loading = true;
                    }
                    return b;
                }
                if (file.size === 0) {
                    this.$message.error("文件内容不能为空！");
                    return false;
                }
                if (this.accept) {
                    const name = file.name || "";
                    const suffix =
                        "." +
                        name
                            .split(".")
                            .pop()
                            .toLowerCase();
                    if (this.accept.split(",").indexOf(suffix) == -1) {
                        this.$message.warning("仅支持这些文件格式 -- " + this.accept);
                        return false;
                    }
                }

                // const size = file.size / 1024 / 1024;
                // const limitSize = this.limitSize || 10;
                // if (size <= limitSize) {
                //   this.loading = true;
                //   return true;
                // }
                // return false; //  默认 最大 10 MB
            },
            handleUploadFile(params) {
                this.$nextTick(() => {
                    const itemName = document.getElementsByClassName('el-upload-list__item-name')[0];
                    if(itemName) {
                        itemName.title = itemName.innerText;
                    }
                });
                this.loading = true;
                const form = new FormData();
                form.append("file", params.file);
                if(this.ftpRequest) {
                    ftpUploadFile(form,e => {this.percentage = Math.round((e.loaded / e.total) * 100)})
                        .then((res) => {
                            const n = res.result[this.resultKey];
                            this.$emit("input", n);
                            this.uploadFileList = [{url: n, name: params.file.name, result:res.result}];
                            this.uploadSuccess(res.result);
                        })
                        .catch((e) => {
                            this.uploadSuccess();
                            this.uploadFileList = [];
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                }else {
                    uploadFile(form,e => {this.percentage = Math.round((e.loaded / e.total) * 100)})
                        .then((res) => {
                            const n = res.result[this.resultKey];
                            this.$emit("input", n);
                            this.uploadFileList = [{url: n, name: params.file.name}];
                        })
                        .catch((e) => {
                            console.error('upload error',e)
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                }
            },
            handleRemove(file) {
                this.$emit("input", '');
                this.uploadFileList = []
            },
            handlePreview(file) {
                if (file.url && (file.url.indexOf("http://") !== -1)) {
                    let a = document.createElement('a')
                    a.href = file.url;
                    a.click();
                }
            },
            //文件超出限制格式
            handleExceed() {
                this.$message.warning("最多只能上传" + this.limit + "个文件");
            },
        },
        watch: {
            value(v) {
                this.path = v;
                //
                const urls = this.uploadFileList.map(item => item.url)
                // if(v){
                //     this.uploadFileList = [].concat({url:v,name:v.split('/').reverse()[0]})
                // }
                if (this.fileList.length !== 0 && urls.length == 0) {
                    this.uploadFileList = [].concat(this.fileList);
                }
                if (!v) {
                    this.uploadFileList = []
                }
            },
            uploadFileList: {
                deep: true,
                handler(newList, oldList) {
                    if (newList && newList.length > 0) {
                        this.$nextTick(() => {
                            const itemName = document.getElementsByClassName('el-upload-list__item-name')[0];
                            if(itemName) {
                                itemName.title = itemName.innerText;
                            }
                        });
                        const this1 = this;
                        setTimeout(function () {
                            this1.$nextTick(() => {
                                const itemName = document.getElementsByClassName('el-upload-list__item-name')[0];
                                if(itemName) {
                                    itemName.title = itemName.innerText;
                                }
                            })
                        }, 2000)
                    }
                },
            }
        },
        mounted() {
            this.path = this.value;
            this.uploadFileList = [].concat(this.fileList)
            //
            if(this.path && this.uploadFileList.filter(e => e.url == this.path).length == 0){
                this.uploadFileList = this.uploadFileList.concat({url:this.path,name:this.path.split('/').reverse()[0]})
            }
        }
    };
</script>

<style scoped lang="scss">
    .self-upload-simple {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .self-upload--{
          width:100%;
        }
        ._button{
          display:flex;
          align-items: center;
          >div{
            margin-left:16px;
            flex:1;
          }
        }
        .el-input {
            pointer-events: none;
            cursor: default;
        }

        ::v-deep .el-upload {
            width:100%;
            button {
                // margin-left: 1em;
            }
        }

        ::v-deep .el-upload-list {
            max-width: 300px;
        }
    }
</style>
