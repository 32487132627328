export const nodeIcons = {
"org.thingsboard.rule.engine.transaction.TbSynchronizationBeginNode":"",
"org.thingsboard.rule.engine.updown.DataReciveNode":"",
"org.thingsboard.rule.engine.telemetry.TbMsgAttributesNode":"",
"org.thingsboard.rule.engine.action.TbCreateRelationNode":"",
"org.thingsboard.rule.engine.debug.TbMsgGeneratorNode":"",
"org.thingsboard.rule.engine.delay.TbMsgDelayNode":"",
"org.thingsboard.rule.engine.action.TbCreateAlarmNode":"el-icon-sunrise",
"org.thingsboard.rule.engine.protocol.Xm2mParserNode":"",
"org.thingsboard.rule.engine.action.TbLogNode":"",
"org.thingsboard.rule.engine.action.TbUnassignFromCustomerNode":"",
"org.thingsboard.rule.engine.action.TbCopyAttributesToEntityViewNode":"",
"org.thingsboard.rule.engine.action.TbClearAlarmNode":"el-icon-sunset",
"org.thingsboard.rule.engine.action.TbSaveToCustomCassandraTableNode":"",
"org.thingsboard.rule.engine.flow.TbCheckpointNode":"",
"org.thingsboard.rule.engine.updown.LogDeviceUpDownNode":"",
"org.thingsboard.rule.engine.geo.TbGpsGeofencingActionNode":"el-icon-map-location",
"org.thingsboard.rule.engine.offline.OfflineRequestsSendToDeviceNode":"",
"org.thingsboard.rule.engine.rpc.TbSendRPCRequestNode":"",
"org.thingsboard.rule.engine.rpc.TbSendRPCReplyNode":"",
"org.thingsboard.rule.engine.action.TbMsgCountNode":"",
"org.thingsboard.rule.engine.flow.TbAckNode":"",
"org.thingsboard.rule.engine.transaction.TbSynchronizationEndNode":"",
"org.thingsboard.rule.engine.telemetry.TbMsgTimeseriesNode":"",
"org.thingsboard.rule.engine.action.TbAssignToCustomerNode":"",
"org.thingsboard.rule.engine.action.TbDeleteRelationNode":"",
"org.thingsboard.rule.engine.filter.TbJsFilterNode":"el-icon-tickets",
"org.thingsboard.rule.engine.geo.TbGpsGeofencingFilterNode":"el-icon-place",
"org.thingsboard.rule.engine.filter.TbCheckAlarmStatusNode":"el-icon-sunrise-1",
"org.thingsboard.rule.engine.filter.TbMsgTypeSwitchNode":"el-icon-guide",
"org.thingsboard.rule.engine.filter.TbMsgTypeFilterNode":"el-icon-chat-line-square",
"org.thingsboard.rule.engine.filter.TbJsSwitchNode":"el-icon-tickets",
"org.thingsboard.rule.engine.filter.TbCheckMessageNode":"el-icon-document-checked",
"org.thingsboard.rule.engine.filter.TbOriginatorTypeFilterNode":"el-icon-notebook-2",
"org.thingsboard.rule.engine.filter.TbOriginatorTypeSwitchNode":"el-icon-set-up",
"org.thingsboard.rule.engine.filter.TbCheckRelationNode":"el-icon-s-claim",
"org.thingsboard.rule.engine.metadata.TbGetTenantDetailsNode":"el-icon-user-solid",
"org.thingsboard.rule.engine.metadata.TbGetOriginatorFieldsNode":"el-icon-s-grid",
"org.thingsboard.rule.engine.metadata.TbGetRelatedAttributeNode":"el-icon-c-scale-to-original",
"org.thingsboard.rule.engine.metadata.TbGetAttributesNode":"el-icon-s-order",
"org.thingsboard.rule.engine.metadata.TbGetCustomerAttributeNode":"el-icon-tickets",
"org.thingsboard.rule.engine.metadata.TbGetTenantAttributeNode":"el-icon-user",
"org.thingsboard.rule.engine.metadata.TbGetTelemetryNode":"el-icon-map-location",
"org.thingsboard.rule.engine.metadata.TbGetDeviceAttrNode":"el-icon-s-promotion",
"org.thingsboard.rule.engine.metadata.TbGetCustomerDetailsNode":"",
"org.thingsboard.rule.engine.rabbitmq.TbRabbitMqNode":"",
"org.thingsboard.rule.engine.gcp.pubsub.TbPubSubNode":"",
"org.thingsboard.rule.engine.mail.TbSendEmailNode":"el-icon-message",
"org.thingsboard.rule.engine.kafka.TbKafkaNode":"",
"org.thingsboard.rule.engine.aws.sqs.TbSqsNode":"",
"org.thingsboard.rule.engine.tcp.TbShuMengNode":"",
"org.thingsboard.rule.engine.mqtt.TbMqttNode":"",
"org.thingsboard.rule.engine.rest.TbRestApiCallNode":"",
"org.thingsboard.rule.engine.mqtt.azure.TbAzureIotHubNode":"",
"org.thingsboard.rule.engine.aws.sns.TbSnsNode":"",
"org.thingsboard.rule.engine.mail.TbMsgToEmailNode":"el-icon-message",
"org.thingsboard.rule.engine.transform.TbChangeOriginatorNode":"el-icon-s-open",
"org.thingsboard.rule.engine.transform.TbTransformLongNode":"el-icon-s-marketing",
"org.thingsboard.rule.engine.transform.TbTransformMsgNode":"el-icon-s-tools"
};
