// 配置 左侧节点列表数据
/*
nodeList:[
        {type:'group',label:'测试分组',children:[
          {name:'my-rect',label:'节点一'},
          {name:'icon-rect',label:'节点二'},
        ]},
        {type:'group',label:'测试分组2',children:[
          {name:'edit-rect',label:'节点三'},
          {name:'rect',label:'节点四'},
        ]}
      ]
*/
import {COLORS} from "./cells"
import i18n from "../../../i18n"

const lan = i18n.messages[i18n.locale];

// 依据 components 生成 list
export const LABELS = {
  FILTER:'滤波器 FILTER',
  ENRICHMENT:'属性集 ENRICHMENT',
  TRANSFORMATION:'变换 TRANSFORM',
  ACTION:'动作 ACTION',
  EXTERNAL:'集成 EXTERNAL',
}


export const list = [
  {type:'group',name:'FILTER',label:'滤波器',children:[]},
  {type:'group',name:'ENRICHMENT',label:'属性集',children:[]},
  {type:'group',name:'TRANSFORMATION',label:'变换',children:[]},
  {type:'group',name:'ACTION',label:'动作',children:[]},
]
export const nodeList = {
  FILTER:{type:'group',name:'FILTER',label:'滤波器',children:[]},
  ENRICHMENT:{type:'group',name:'ENRICHMENT',label:'属性集',children:[]},
  TRANSFORMATION:{type:'group',name:'TRANSFORMATION',label:'变换',children:[]},
  ACTION:{type:'group',name:'ACTION',label:'动作',children:[]},
}

export const createList = (name,shape,icon,group,label) => {
  const colors = COLORS[group]
  return {label,name:shape,icon,style:{borderColor:colors[0],background:colors[1],fontWeight:'bold'}}
}
