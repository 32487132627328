// 全局组件注册配置

import elsElem from "@/components/els-elem/main.js"
import itemTable from "@/components/els-form/item-table/item-table.vue"
import selfUpload from "@/components/els-form/self-upload/main.vue"
import elsButtonGroup from "@/components/els-button-group/main.vue"
import elsFormGroup from "@/components/els-form-group/main.vue"
import elsForm from "@/components/els-form/main.vue"
import elsDrawer from "@/components/els-drawer/main.vue"
import elsTable from "@/components/els-table/main.vue"
import elsFileData from "@/components/els-file-data/main.vue"
import elsMonacoEditor from "@/components/els-monaco-editor/main.vue"
import elsTextCard from "@/components/els-text-card/main.vue"
function install(Vue){
  Vue.component('els-elem',elsElem)
  Vue.component('item-table',itemTable)
  Vue.component('self-upload',selfUpload)
  Vue.component('els-button-group',elsButtonGroup)
  Vue.component('els-form-group',elsFormGroup)
  Vue.component('els-form',elsForm)
  Vue.component('els-drawer',elsDrawer)
  Vue.component('els-table',elsTable)
  Vue.component('els-monaco-editor',elsMonacoEditor)
  Vue.component('els-text-card',elsTextCard)
  Vue.component('els-file-data',elsFileData)
}

//
export default install;