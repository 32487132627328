// 路由配置文件
import {isURL} from "@/utils/util"
import {treeReset} from "@/utils/tree"

// 动态路由配置项
// 基础路由 常规如登陆，首页，注册，密码找回 等不需要用户登陆的页面
export const baseRoutes = [
  {path: '/',redirect:`/zh-cn/${window.__POWERED_BY_WUJIE__ ? 'empty' : 'login'}`},
  //{path: '/home',name: 'Home',component: () => import ('/src/views/base/home.vue')},
  {path:'/:lang/empty',name:'Empty',component:() => import('/src/views/base/empty.vue')},
  {path:'/:lang/login',name:'Login',component:() => import('/src/views/base/login.vue')},
  {path:'/:lang/register',name:'Register',component: () => import('/src/views/base/register.vue')},
  {path:'/:lang/404',name:'404',component:() => import('/src/views/base/error-404.vue')},
  {path:'/404',redirect:"/:lang/404"},
  {path:'/401',redirect:"/"},
]

export const loginPath = '/login'

// 重定向路由，放在路由表最后 默认进入 404 页面
const finalRoutes = [
  {path:'*',redirect:'/404'}
]
// 动态懒加载 路由页面
const loadPage = (p) => p? () => import(`@/${p}.vue`):undefined;
// 动态数据映射规则，即 api 获取的数据和路由数据 key 名称的映射关系,对象中 key 为目标值，value 为 数据原始值 或者方法，如果是方法，传入对象为整个数据的节点数据n,list,i,pn,ins
const props = {
  path:'path',
  name:'name',
  redirect:'redirect',
  alias:'alias',
  meta:'meta',
  component:(node) => loadPage(node.component)
  // component:(node) => components[node.component]
}

// 基础路由 路由链路地址默认根目录 /src/

// 创建路由配置项
export const createRouterConfig = {
  mode:'history',
  base: '',
}



// 动态路由表生成规则 动态路由数据可通过 api 获取
export const createDynamicRoutes = (tree) => {
  // console.log(".....",tree)
  // 将 keys 提到 f 的外层，避免在循环中
  const keys = Object.keys(props)
  // 定义 路由节点 生成方法
  const f = (n,l,i,pn) => {
    const node = {}
    keys.forEach(k => {
      const v = props[k];
      const t = typeof v;
      if(t === 'string'){
        node[k] = n[v]
      }
      else if(t === 'function'){
        node[k] = v(n,l,i,pn);
      }
    })
    if(n.menuLabel){
      node.meta = node.meta ? {title:n.menuLabel,...node.meta} : {title:n.menuLabel}
    }
    if(!pn && !/^\//.test(node.path)){node.path = '/' + node.path}
    // 返回 undefined 会删除节点
    return !isURL(node.path)?node:undefined;
  }
  // 利用树工具方法构建路由表
  const routes = tree ? treeReset(tree,f) : []
  return [...baseRoutes,...routes,...finalRoutes]
}
