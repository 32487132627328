export default {
    data:() => ({
        PAGE_NAME:''
    }),
    computed:{
        lang(){
            const d = this.$t('d') || {}
            const p = this.$t(this.PAGE_NAME) || {}
            return {...d,...p}
        }
    }
}
