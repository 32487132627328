<!--
  表单小组件 -- 子表单组件 - 可编辑的表格 - 数据格式为 数组 json
  保持数据一致性
  支持批量操作
  不支持树形数据
  构造数据格式
  支持插槽
  column:[
    {prop:'',label:'',form:{tag:'',[prop,rules,value]}}
  ]
-->

<template>
  <div class="item-table els-form--item-table">
    <div class="t--header"></div>
    <div class="t--body" :class="{ 'no-pagination': !showPagination }">
      <el-table :data="pageList" v-bind="tableOption">
        <!-- show selection -->
        <!-- <el-table-column v-if="showSelection" type="selection"></el-table-column> -->
        <!-- index show Index -->
        <el-table-column
          v-if="showIndex"
          type="index"
          align="center"
          label="序号"
          :index="tableIndex"
          width="50"
        >
          <template v-if="mode == 'simple' && !isDisabled" slot="header">
            <el-tooltip content="新增一条数据" placement="top">
              <span class="add-inpus" @click="handleAddData(1)"
                ><i class="el-icon-plus"></i
              ></span>
            </el-tooltip>
          </template>
          <template v-if="mode == 'simple'" slot-scope="scope">
            <div
              @mouseenter="row.index = scope.$index"
              @mouseleave="row.index = -1"
            >
              <span v-show="scope.$index !== row.index || isDisabled">{{
                tableIndex(scope.$index)
              }}</span>
              <el-tooltip
                v-if="!showMenu && !isDisabled"
                v-show="scope.$index == row.index"
                content="删除"
                placement="top"
              >
                <span @click="handleDeleteRow(scope)" class="del-plus"
                  ><i class="el-icon-delete"></i
                ></span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <!-- column -->
        <template v-for="(col, ins) in column">
          <el-table-column :key="ins" v-bind="utilGetColumnAttr(col)">
            <template slot="header">
              <!-- 是否必填字段 -->
              <b v-if="utilIsRequired(col)" class="is--required">
                <el-tooltip placement="top" content="必填项">
                  <i class="el-icon-star-on"></i>
                </el-tooltip>
              </b>
              <!-- 是否支持 icon  -->
              <i v-if="col.icon" :class="col.icon"></i>
              <!-- 表头文字 -->
              <span v-if="col.label">{{ col.label }}</span>
              <!-- 操作按钮 -->
              <el-button
                v-if="col.handle"
                type="text"
                :icon="col.buttonIcon"
                @click.stop="col.handle"
              ></el-button>
              <!-- slot -->
              <template v-if="col.slot">
                <slot :name="col.prop + 'Header'" v-bind="{ ...col }"></slot>
              </template>
            </template>
            <!-- 元素内容 -->
            <template slot-scope="scope">
              <template v-if="!col.form">
                <template v-if="col.slot">
                  <slot :name="col.prop" v-bind="{ ...scope, col }"></slot>
                </template>
                <template v-else>
                  <span>{{ scope.row[col.prop] }}</span>
                </template>
              </template>
              <template v-else>
                <el-tooltip
                  :disabled="!scope.row.$edit?false:
                    (!scope.row.$checkData ||
                    !scope.row.$checkData[col.prop] ||
                    scope.row.$checkData[col.prop].check)
                  "
                  :content="!scope.row.$edit?scope.row[col.prop]:
                    (scope.row.$checkData && scope.row.$checkData[col.prop]
                      ? scope.row.$checkData[col.prop].message
                      : '')
                  "
                  placement="top"
                >
                  <div
                    :class="[
                      'item-table--elem 111',
                      scope.row.$checkData && scope.row.$checkData[col.prop]
                        ? scope.row.$checkData[col.prop].check
                          ? 'is-success'
                          : 'is-error'
                        : 'is-normal',
                      utilHaveBorder(col) ? 'has-border' : '',
                      { 'hide-border': !scope.row.$edit },
                    ]"
                  >
                    <!-- 查看模式 -->
                    <span class="e--item" v-show="!scope.row.$edit">
                      {{ scope.row[col.prop] }}
                    </span>
                    <template>
                      <!-- <els-elem v-show="scope.row.$edit" :elem="initElem(col,scope.row)"></els-elem> -->
                      <item-table-item
                        v-show="scope.row.$edit"
                        v-model="scope.row[col.form.prop || col.prop]"
                        :formData="scope.row"
                        :formItemAttrs="utilFormAttrs(col)"
                        :scope="scope"
                        @form-item-blur="handleRowDataBlur"
                        @form-item-change="handleRowDataChange"
                      ></item-table-item>
                    </template>
                  </div>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
        </template>
        <!-- 操作 -->
        <el-table-column v-if="showMenu" :label="lang.operate">
          <template slot-scope="scope">
            <el-tooltip
              v-if="showSaveBtn"
              :content="scope.row.$edit ? lang.save : lang.edit"
              placement="top"
            >
              <el-button
                @click="handleRowEdit(scope)"
                :icon="scope.row.$edit ? 'el-icon-check' : 'el-icon-edit'"
                type="text"
                size="mini"
                >{{ scope.row.$edit ? lang.save : lang.edit }}</el-button
              >
            </el-tooltip>

            <el-tooltip :content="lang.delete" placement="top">
              <el-button
                @click="handleDeleteRow(scope)"
                icon="el-icon-delete"
                type="text"
                size="mini"
                >{{ lang.delete }}</el-button
              >
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div ref="footerRef" class="t--footer" v-if="showPagination">
      <div></div>
      <el-pagination
        :total="page.total"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="page.pageSizes"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :layout="page.layouts[0]"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import AsyncValidator from "async-validator";
import itemTableItem from "./item.js";
import mixinsI18n from "@/mixins/i18n.js";
import { chunk } from "loadsh"
export default {
  name: "item-table",
  components: { itemTableItem },
  mixins: [mixinsI18n],
  props: {
    value: [Array, Object, String],
    column: Array,
    prop: String, //
    rules: [Object, Array],
    disabled: Boolean,
    initData: { type: Object, default: () => ({}) },
    defaultData: { type: Object, default: () => ({}) },
    options: { type: Object, default: () => ({}) },
    mode: { type: String, default: "simple" },
    autoToLastitem: { type: Boolean, default: true }, // 是否自动翻页到最新添加的元素
    showIndex: { type: Boolean, default: true },
    showMenu: { type: Boolean, default: false },
    showPagination: { type: Boolean, default: true },
    showSaveBtn: { type: Boolean, default: true },
    autoEdit: { type: Boolean, default: true },
    required: { type: Boolean, default: undefined },
    errorMessage: { type: String, default: "数据输入不正确！" },
    valueType: { type: String, default: "object" },
    initLine: { type: Number, default: 1 },
    limit: Number,
    limitMessage: {type:String,default:"已达到最大数量！"},
    format: Function,
    beforeRequest: Function, // 保存、删除之前是否需要调用接口
  },
  data: () => ({
    PAGE_NAME: "",
    list: [],
    cache: undefined,
    row: {
      index: -1,
    },
    page: {
      pageNum: 1,
      pageSize: 5,
      total: 0,
      pageSizes: [5, 10, 15, 20, 30, 50, 100],
      layouts: [
        "total, sizes, prev, pager, next",
        "total,prev, pager, next",
        "prev, pager, next",
      ],
    },
    validateState: "",
    validateMessage: "",
  }),
  computed: {
    isDisabled() {
      const f = this.form || {};
      return this.disabled || f.disabled || false;
    },
    pageList() {
      if (!this.showPagination) {
        return this.list;
      }
      const list = chunk(this.list,this.page.pageSize);
    //  console.log(list,this.page.pageNum)
      return list[this.page.pageNum -1];
      // const len = list.length;
      // const pn = this.page.pageNum;
      // const ps = this.page.pageSize;
      // const s = (pn - 1) * ps;
      // const e = s + ps < len ? s + ps : len;
      // //
      // return s < len ? list.slice(s, e) : [];
    },
    tableOption() {
      const o = this.options.table || this.$attrs.table || {};
      const d = {
        border: true,
        headerCellStyle: { height: "48px" },
        rowStyle: { height: "48px" },
        cellStyle: { padding: "0px" },
      };
      return { ...d, ...o };
    },
    form() {
      let parent = this.$parent;
      let parentName = parent.$options.componentName;
      while (parentName !== "ElForm" && parent !== undefined) {
        // if (parentName === "ElFormItem") {
        //   this.isNested = true;
        // }
        parent = parent.$parent;
        parentName = parent && parent.$options.componentName;
      }
      if (parentName == "ElForm") {
        return parent;
      }
      return undefined;
    },
    formItem() {
      const p = this.prop;
      let parent = this.$parent;
      let parentName = parent.$options.componentName;
      while (
        parentName !== "ElFormItem" &&
        parent !== undefined &&
        parent.prop != p
      ) {
        // if (parentName === "ElFormItem") {
        //   this.isNested = true;
        // }
        parent = parent.$parent;
        parentName = parent && parent.$options.componentName;
      }
      if (parentName == "ElFormItem") {
        return parent;
      }
      return undefined;
    },
    // 利用计算属性缓存 rules
    formRules() {
      const ft = this.formItem;
      const cols = this.column;
      const rs = ft && ft.getRules ? ft.getRules() : [];
      const r = this.rules;
      // console.log("=====",r,rs)
      return [].concat(r || rs);
    },
    formAllRules() {
      const rules = this.formRules;
      const res = this.utilGetFilterRules("", rules);
      return res;
    },
    formBlurRules() {
      const rules = this.formRules;
      const res = this.utilGetFilterRules("blur", rules);
      // console.log('t-----------------')
      return res;
    },
    formChangeRules() {
      const rules = this.formRules;
      const res = this.utilGetFilterRules("change", rules);
      return res;
    },
  },
  methods: {
    //
    init() {
      // 初始化 cache
      this.cache = this.value;
      // 初始化 data
      let l = this.format ? this.format(this.value) : this.value;
      if(typeof l == 'string'){
        l = JSON.parse(l);
      }
      if (l && !Array.isArray(l)) {
        l = Object.keys(l).map((k) => ({ key: k, value: l[k] }));
      }
      //
      let list = l ? JSON.parse(JSON.stringify(l)) : [];
      //
      if (this.valueType === "array" && Array.isArray(list[0])) {
        list = list.map((item) => this.utilArr2Obj(item));
      }
      // 初始化 $edit
      list.forEach((li) => (li.$edit = this.autoEdit));
      // 初始化 this.list
      this.list = [...list];
      this.page.total = this.list.length;
      //
    },
    initElem(col, row) {
      // 提取属性 label,prop,tag,form
      const { label, prop, tag, form = {} } = col;
      const p = form.prop || prop;
      const v = row[p] || undefined;
      return { ...col, ...row };
    },
    // index
    tableIndex(ins) {
      const n = this.page.pageNum;
      const s = this.page.pageSize;
      return (n - 1) * s + ins + 1;
    },
    // 新增数据
    handleAddData(n = 1) {
      if (this.limit > 0 && this.list.length >= this.limit) {
        this.$message.warning(this.limitMessage);
        return;
      }
      const d = { ...this.defaultData, $edit: true,value:undefined };
      const list = [];
      for (let i = 0; i < n; i++) {
        list.push(d);
      }
      this.list = this.list.concat(list);
      //
      this.page.total = this.list.length;
      //
      if(this.showPagination && this.autoToLastitem){
        this.page.pageNum = Math.ceil(this.page.total / this.page.pageSize);
      }
      //
      this.emitInput();
      this.$emit("row-add", list);
    },
    // 删除数据
    async handleDeleteRow(scope) {
      scope.row.$delete = true;
      if(this.beforeRequest) {
       const res = await this.beforeRequest(scope.row);
       if(!res) {
         return
       }
      }

      const { row, $index } = scope;
      const ins = this.utilGetRowIndex(row, $index);
      if (ins > -1) {
        this.list = this.list.filter((r, i) => i !== ins);
      }

      //
      this.page.total = this.list.length;
     // console.log(this.pageList)
      // 当当前页没有数据时，页码自动减一 （当页码大于 1 时）
      const isSub =
        this.showPagination &&
        this.page.pageNum > 1 &&
        (!this.pageList || this.pageList.length == 0)
      if (isSub) {
        this.page.pageNum--;
      }
      this.emitInput();
      this.$emit("row-delete", scope);
    },
    //
    handleCurrentChange(n) {
      this.page.pageNum = n;
    },

    //
    handleSizeChange(s) {
      this.page.pageSize = s;
    },
    //
    async handleRowEdit(scope) {
      if(this.beforeRequest&&scope.row.$edit) {
        const res = await this.beforeRequest(scope.row);
        if(!res) {
          return
        }
      }
      const row = scope.row;
      const index = scope.$index;
      const ins = this.utilGetListIndex(index);
      const ev = !row.$edit;
      //
      const li = this.list[ins];
      this.$set(li, "$edit", ev);
      // if (!ev) {
      this.$emit("row-save", scope);
      // }
    },
    //
    handleRowDataChange(d, scope, col) {
      // console.log('...............',d,JSON.stringify(this.list),this.list)
      const n = d.name;
      const v = d.value;
      const i = this.utilGetRowIndex(scope.row, scope.$index);
      if (i > -1) {
        this.list[i][n] = v;
      }
      this.emitInput();
    },
    handleRowDataBlur(d, scope, col) {
      this.validateElem("blur", d, scope.row);
    },
    //
    emitInput() {
      const isTrans = this.valueType === "array";
      const list = this.list;
      const res = list.map((item) => {
        const ks = Object.keys(item);
        const r = {};
        ks.forEach((k) => {
          // console.log(k)
          if (k.indexOf("$") == -1) {
            r[k] = item[k];
          }
        });
        return isTrans ? this.utilObj2Arr(r) : r;
      });

      this.cache = this.format ? this.format(res) : res;
      this.$emit("input", this.cache);
      // console.log('.............',this.prop,list,res,this.list,JSON.stringify(this.cache))
    },
    //
    utilGetListIndex(i) {
      if (!this.showPagination) {
        return i;
      }
      const list = this.list;
      const len = list.length;
      const pn = this.page.pageNum;
      const ps = this.page.pageSize;
      //
      const ins = (pn - 1) * ps + i;
      return ins;
    },
    //
    utilGetColumnRules(cols) {},
    utilFormAttrs(col) {
      const f = { ...col.form, size: "mini" };
      if (f.prop === undefined) {
        f.prop = col.prop;
      }

      return f;
    },
    utilIsRequired(col) {
      const p = col.prop;
      const rule = this.formRules[0] || {};
      const rs = rule[p] || [];
      const isRequired = rs.filter((r) => r.required).length > 0;
      return isRequired;
    },
    utilGetRowIndex(row, ins) {
      if (!this.showPagination) {
        return ins;
      }
      const pn = this.page.pageNum;
      const ps = this.page.pageSize;
      const i = (pn - 1) * ps + ins;
      if (this.list[i] === row) {
        return i;
      }
      return -1;
    },
    utilFilterList(list = this.list) {},
    utilGetColumnAttr(col) {
      let r = {};
      const ks = Object.keys(col);
      const ex = ["label", "prop", "form"];
      ks.forEach((k) => {
        if (ex.indexOf(k) == -1) {
          r[k] = col[k];
        }
      });
      return r;
    },
    //
    utilHaveBorder(col) {
      const f = col.form || {};
      const t = f.tag || "el-input";
      const b = col.formBorder || true;
      const d = [
        "el-input",
        "el-autocomplete",
        "el-input-number",
        "el-select",
        "el-cascader",
        "el-time-select",
        "el-time-picker",
        "el-date-picker",
      ];
      return b && d.indexOf(t) > -1;
    },
    // value 数组 转对象
    utilArr2Obj(arr) {
      let res = {};
      arr.forEach((t, i) => {
        const k = "" + i;
        res[k] = t;
      });
      return res;
    },
    // value 对象 转数组
    utilObj2Arr(obj) {
      const keys = Object.keys(obj);
      let res = [];
      keys.forEach((k) => {
        if (/\d+/.test(k)) {
          const ins = Number(k);
          res[ins] = obj[k];
        }
      });
      return res;
    },
    //
    // 支持 el-form 验证
    // 将该对象添加到 el-form 对象中
    utilAddField() {
      const ft = this.formItem;
      if (ft && ft.dispatch) {
        // 移除父级 el-form-item 元素的验证
        ft.$nextTick(() => {
          ft.dispatch("ElForm", "el.form.removeField", [ft]);
          ft.removeValidateEvents();
          this.addValidateEvents();
        });
      }
      const prop = this.prop;
      // setSourceMenus;
      const elf = this.form;
      if (elf == undefined || !prop) {
        return;
      }
      // 将this 对象添加到 elform 实例的 fields 数组中，追加验证
      elf.fields.push(this);
    },
    // 过滤 rules
    utilGetFilterRules(trigger = "") {
      const rules = [...this.formRules];
      const rule = rules.length > 0 ? rules[0] : {};
      const ks = Object.keys(rule);
      let o = {};
      ks.forEach((k) => {
        const rs = rule[k];
        const a = rs
          .filter((r) => {
            if (!r.trigger || trigger === "") return true;
            if (Array.isArray(r.trigger)) {
              return r.trigger.indexOf(trigger) > -1;
            } else {
              return r.trigger === trigger;
            }
          })
          .map((r) => {
            const t = { ...r };
            if (t.trigger) {
              delete t.trigger;
            }
            return t;
          });
        if (a.length > 0) {
          o[k] = a;
        }
      });
      return [o];
    },
    //

    // 验证数据格式
    validate(trigger, callback = () => {}) {
      // console.log("--------------------------- validate");
      // 获取 rules
      let rules = [];
      switch (trigger) {
        case "blur":
          rules = this.formBlurRules;
          break;
        case "change":
          rules = this.formChangeRules;
          break;
        case "":
          rules = this.formAllRules;
          break;
        default:
          rules = this.utilGetFilterRules(trigger);
      }
      if (rules.length == 0) {
        callback();
        return true;
      }

      // 初始化校验项
      const rule = rules[0];
      const ks = Object.keys(rule);
      let vs = [];
      ks.forEach((k) => {
        let descriptor = {};
        if (rule[k].length > 0) {
          descriptor[k] = rule[k];
          const validator = new AsyncValidator(descriptor);
          vs.push(validator);
        }
      });
      // 开始校验
      let infs = {};
      let status = true;
      const list = this.list;
      for (let j = 0, len = list.length; j < len; j++) {
        const li = list[j];
        const cs = {};
        for (let i = 0, l = ks.length; i < l; i++) {
          const o = {};
          const k = ks[i];
          const validator = vs[i];
          const m = { ...li };
          // m[k] = li[k];
          validator.validate(
            m,
            { firstFields: true },
            (errors, invalidFields) => {
              // const validateState = !errors ? "success" : "error";
              // const validateMessage = errors ? errors[0].message : "";
              o["state"] = !errors ? "success" : "error";
              o["message"] = errors ? errors[0].message : "";
              o["check"] = !errors;
              status = status && !errors;
              infs = errors ? { ...infs, ...invalidFields } : infs;
            }
          );
          cs[k] = o;
        }
        list[j] = { ...li, $checkData: cs };
      }
      this.validateState = status ? "success" : "error";
      this.validateMessage = status ? "" : this.errorMessage;
      //
      callback(
        this.validateMessage,
        Object.keys(infs).length > 0 ? infs : null
      );
      this.form &&
        this.form.$emit(
          "validate",
          this.prop,
          status,
          this.validateMessage || null
        );
      // 刷新列表
      this.list = [...this.list];
    },
    // 单一元素校验
    validateElem(trigger, data, row) {
      // 获取 rules
      let rules = [];
      switch (trigger) {
        case "blur":
          rules = this.formBlurRules;
          break;
        case "change":
          rules = this.formChangeRules;
          break;
        case "":
          rules = this.formAllRules;
          break;
        default:
          rules = this.utilGetFilterRules(trigger);
      }
      if (rules.length == 0) {
        return true;
      }
      // 获取该元素的校验项
      const name = data.name;
      const rule = rules[0][name];
      if (!rule || rule.length == 0) {
        return true;
      }
      // 校验开始
      let check = {}; // 校验结果集
      const model = { ...row }; // 校验数据
      // model[name] = data.value;
      //
      let descriptor = {};
      descriptor[name] = rule;
      //
      const validator = new AsyncValidator(descriptor);
      validator.validate(
        model,
        { firstFields: true },
        (errors, invalidFields) => {
          check["state"] = !errors ? "success" : "error";
          check["message"] = errors ? errors[0].message : "";
          check["check"] = !errors;
        }
      );
      // 判断 row 中是否已经存在 $checkData 并更新 checkData
      let checkData = { ...row.$checkData } || {};
      checkData[name] = check;
      this.$set(row, "$checkData", checkData);
      // row.$checkData = checkData;
    },
    // 清除校验结果
    clearValidate() {
      // console.log("--------------------------- clearValidate");
      const list = this.list;
      let res = [];
      for (let i = 0, l = list.length; i < l; i++) {
        let li = { ...list[i] };
        delete li.$checkData;
        res[i] = li;
      }
      this.list = res;
    },
    // 重设字段
    resetField() {
      // console.log("--------------------------- resetField");
      this.clearValidate();
    },
    // 增加验证事件
    addValidateEvents() {
      // console.log("--------------------------- addValidateEvents");
    },
    // 移除验证事件
    removeValidateEvents() {
      // console.log("--------------------------- removeValidateEvents");
    },
    //
  },
  created() {
    this.init();
  },
  mounted() {
    this.utilAddField();
  },
  watch: {
    list() {
      if (this.list.length == 0 && this.initData) {
        if (this.initLine > 0) {
          const list = [];
          for (let i = 0; i < this.initLine; i++) {
            const d = typeof this.initData == "object" ? this.initData : {};
            const data = { ...d, $edit: true };
            list.push(data);
          }
          this.list = list;
          this.page.total = list.length;
        }
      }
    },
    value() {
      if (
        this.value !== this.cache &&
        JSON.stringify(this.value) != JSON.stringify(this.cache)
      ) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss">
.item-table {
  .t--body {
    &.no-pagination {
      height: 100%;
    }
    .add-inpus {
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background-color: #4dd04d;
      border-radius: 50%;
      color: #fff;
    }
    .del-plus {
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background-color: red;
      border-radius: 50%;
      color: #fff;
    }
    .is--required {
      color: #f56c6c;
      margin-right: 5px;
      i {
        font-size: 10px;
      }
    }
    .item-table--elem {
      display: flex;
      align-items: center;
      &.has-border {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
      }
      &.hide-border {
        border-color: transparent;
        &.is-success {
          border-color: transparent;
          .e--status {
            display: none;
          }
        }
      }
      &.is-success {
        border-color: #67c23a;
      }
      &.is-error {
        border-color: #f56c6c;
      }

      .e--status {
        margin: 0 5px;
        &.el-icon-warning {
          color: #f56c6c;
        }
        &.el-icon-success {
          color: #67c23a;
        }
      }
      .e--item {
        flex: 1;
        height: 28px;
        line-height: 28px;
        padding: 0 15px;
        font-size: 12px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap;
      }
      .el-input__inner {
        // border: none;
      }
      .el-input-number {
        width: 100%;
      }
      .el-select {
        width: 100%;
      }
      .el-autocomplete {
        width: 100%;
      }
    }

    .color--danger {
      color: #f56c6c;
    }
  }
  .t--footer {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ::deep .el-pagination {
      padding: 0;
    }
  }
}
</style>
