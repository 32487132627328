import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
//
import { saveLocal, getLocal, clearLocal } from "@/utils/util";
//
import clientType from "./modules/clientType";
import ruleDesign from "./modules/ruleDesign";
//
import router from "@/router/index.js";
//
import { getMenu, getUserInfo ,getAuthInfo} from "@/api/base/login";
import { getButtons, getMenus } from "@/utils/menuTree";
import { buildMenus } from "@/config/menus.js";
//
Vue.use(Vuex);
//
let loginFlag = false;
//
export default new Vuex.Store({
  modules: { clientType, ruleDesign },
  state: {
    token: "",
    platformToken: "", // 平台token
    menus: undefined, // 需要渲染的菜单数据
    sourceMenus: undefined, // 源菜单
    buttons: undefined,
    userInfo: undefined,
    breadMenus: undefined,
    initRouteData: undefined,
    noLoginFlag: false,
    hasRouter: false,
    refreshToken:"",
  },
  getters: {
    token: (state) => state.token || getLocal("token"),
    platformToken: (state) => state.platformToken || getLocal("platformToken"),
    menus: (state) => state.menus || getLocal("menus", "object"),
    sourceMenus: (state) =>
      state.sourceMenus || getLocal("sourceMenus", "object"),
    buttons: (state) => state.buttons || getLocal("buttons", "object"),
    userInfo: (state) => state.userInfo || getLocal("userInfo", "object"),
    hasRouter: (state) => state.hasRouter,
    refreshToken: (state) => state.refreshToken,
  },
  mutations: {
    setToken(state, val) {
      state.token = val;
      saveLocal("token", val);
    },
    setPlatformToken(state, val) {
      state.platformToken = val;
      saveLocal("platformToken", val);
    },
    setMenus(state, val) {
      state.menus = val;
      saveLocal("menus", val);
    },
    setSourceMenus(state, val) {
      state.sourceMenus = val;
      saveLocal("sourceMenus", val);
    },
    setButtons(state, val) {
      state.buttons = val;
      saveLocal("buttons", val);
    },
    setUserInfo(state, val) {
      state.userInfo = val;
      saveLocal("userInfo", val);
    },
    setBreadMenus(state, val) {
      state.breadMenus = [...val];
    },
    setInitRouteData(state, val) {
      state.initRouteData = val;
    },
  },
  actions: {
    // 登出时可以调用，清空缓存的用户数据
    async resetUserData({ state }, isClear = true) {
      // 清除登陆后保存的数据
      state.token = "";
      state.platformToken = "";
      state.menus = undefined;
      state.buttons = undefined;
      state.userInfo = undefined;
      //
      isClear && clearLocal();
    },
    // 登出 logout
    async logout({ dispatch }) {
      loginFlag = false;
      // 清除用户状态，包括本地缓存
      await dispatch("resetUserData");
      // 跳转到登陆页面
      router.$goLogin();
    },
    async getUserInfo({ commit, state, getters }, token) {
      const { result: tree } = await getMenu();
      if (window.__POWERED_BY_WUJIE__) {
        const info = await getAuthInfo();
        commit("setUserInfo", info);
        // commit("setUserInfo", {
        //   tenantId: {
        //     type:"wujie",
        //     entityType: "TENANT",
        //     id: "38bec200-5417-11ed-8e33-b5586e6ed149",
        //   },
        // });
      } else {
        const userInfo = await getUserInfo(token || getters.token);
        commit("setUserInfo", userInfo);
      }
      // const [{ result: tree }] = await Promise.all([
      //   getMenu(),
      //   // getUserInfo(token || getters.token),
      // ]);

      // 解析数据 并存入 store 中
      const buttons = getButtons(tree);
      const sourceMenus = getMenus(tree);
      const menus = buildMenus(sourceMenus);
      commit("setButtons", buttons);
      commit("setSourceMenus", sourceMenus);
      commit("setMenus", menus);
      router.$rebuild();
      // console.log("========routes==========", router.getRoutes());
      if (state.initRouteData) {
        router.push(state.initRouteData);
      }
      state.hasRouter = true;
      loginFlag = true;
    },
    async noLogin({ commit, dispatch, state }, data = {}) {
      // console.log("-------****----------", state.noLoginFlag, data);

      let d = data;
      if (typeof data === "string") {
        d = { token: data };
      }
      const { token, refreshToken } = d;
      if (token) {
        commit("setToken", token);
        if(window.__POWERED_BY_WUJIE__){
          commit("setPlatformToken",token)
        }
        if (loginFlag !== true) {
          dispatch("getUserInfo", token);
        }
      }
    },
  },
  plugins: [persistedState()],
});
