import http from "@/axios/index"
import { URI } from "@/config/axios"

// GET /api/dictionary/type/
export const uploadFile = (file,progress = e => console.log(e)) => {
    return http({
        url: URI.system + 'api/minio/upload',
        method: 'post',
        data: file,
        onUploadProgress: (e) => { progress(e) }
    })
}

//ftp文件上传
export const ftpUploadFile = (file,progress = e => console.log(e)) => {
    return http({
        url: URI.system + 'api/ftp/driver/upload',
        method: 'post',
        data: file,
        onUploadProgress: (e) => { progress(e) }
    })
}
