// 针对元素属性进行基础分类~创建元素 context 对象
const styleKeys = ["width","height","color","padding","margin","border","background","display","position"];
const attrKeys = ["id",'placeholder'];
const rootKeys = ["key","ref","refInFor","class","style","scopedSlots","directives","on","nativeOn","domProps","props","attrs","slot"];
const extsKeys = ["style", "attrs", "props", "tag", "children", "cls","hooks",'slotItem','domProps',"slot-scope","slotScope"];

const has = Object.prototype.hasOwnProperty;

export const getElemContext = (elem) => {
  const keys = Object.keys(elem);
  if(keys.length == 0){
    return {}
  }
  let root = {};
  let style = elem.style || {};
  let attrs = elem.attrs || {};
  let props = elem.props || {};
  let domProps = elem.domProps || {};
  for(let i = 0,len = keys.length;i<len;i++){
    const k = keys[i]
    const v = elem[k]
    if (extsKeys.indexOf(k) !== -1) {
      continue;
    }
    // style
    if (styleKeys.indexOf(k) !== -1) {
      style[k] = v;
      continue;
    }
    // attr
    if (attrKeys.indexOf(k) !== -1) {
      attrs[k] = v;
      continue;
    }
    // 非 props 属性
    if (rootKeys.indexOf(k) !== -1) {
      root[k] = v;
      continue;
    }
    // 如果 props 中没有该属性，则添加该属性到 props 中。
    if(!has.call(props,k)){
      props[k] = v
    } 
    
  }
  return { ...root, style, attrs, props,domProps };
}


export const getType = (o) => {
  const t = Object.prototype.toString.call(o);
  return /^\[object (.*)\]$/.exec(t)[1].toLowerCase();
}

// tag 处理方法
export const tagOptions = {
  'slot':{
    render(h){
      const name = this.elem.name || 'default'
      const ctx = this.$_createContext();
      const cds = this.$_createChilds(h);
      const f = this.context.$scopedSlots[name]
      // console.log('::::::::::::::::',ctx,f,f(ctx))
      if(f){
        return f(ctx).filter(node => node.tag)[0]
      }else if(cds.length == 1){
        return cds[0];
      }else{
        return h('div',ctx,cds);
      }
    }
  },
  'template':{
    render(h){
      return [...this.$_childs].map(c => this.$_createChild(c,h))
    }
  }
}

// export const defaultHooks = {
//   created(){
//     // 对 scoped 进行处理和赋值
//     const scd = this.$_scoped;
//     Object.keys(scd).forEach(k => {
//       const v = scd[k]
//       if(!this[k]){this[k] = v}
//       if(this.$(k) === undefined){
//         this.$(k,v)
//       }
//     })
//   }
// }
