<template>
  <!-- 动画 -->
  <transition :name="transitionName">
    <!-- 遮罩层 -->
    <div
      class="els-drawer"
      :class="`d--${directionData}`"
      v-show="visibleData"
      v-bind="markOption"
      @click.self="handleClose"
    >
      <!-- 内容层 -->
      <div class="els-drawer--content d--content" v-bind="contentOption">
        <!-- header -->
        <div v-if="showHeader" class="c--header">
          <div class="h--title" :title="title">
            <slot name="title">{{ title || '' }}</slot>
          </div>
          <el-button
            v-if="showClose"
            class="h--close"
            size="mini"
            icon="el-icon-close"
            circle
            plain
            type="danger"
            @click.stop="handleClose"
          ></el-button>
        </div>
        <div class="c--body" :class="{ 'no--header': !showHeader }">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'els-drawer',
  props: {
    options: { type: Object, default: () => ({}) },
    visible: Boolean,
    showMark: Boolean,
    showHeader: { type: Boolean, default: true }, // 是否显示默认的头部 包含标题和关闭图标
    showClose: { type: Boolean, default: true }, // 是否显示默认的关闭按钮
    direction: { type: String, default: "right" }, // 抽屉打开方向 取值 'right','left','up','down'
    title: String,
    beforeClose: Function,
  },
  data: () => ({
    visibleData: false,
  }),
  computed: {
    directionData() {
      // 限定方向名词只能是下面几个
      const lib = ['right', 'left', 'up', 'down']
      const d = this.direction;
      if (lib.indexOf(d) == -1) {
        return 'right'
      }
      return d;
    },
    transitionName() {
      const d = this.directionData;
      return `els-drawer-transition--${d}`;
    },
    commonStyle() {
      const d = this.directionData;
      const sm = this.showMark;
      return {};
    },
    markOption() {
      const o = this.$attrs.mark || this.options.mark || {};
      const d = {};
      return { ...d, ...o }
    },
    contentOption() {
      const o = this.$attrs.content || this.options.content || {};
      const d = {}
      return { ...d, ...o }
    }
  },
  methods: {
    handleClose() {
      if (this.beforeClose) {
        this.beforeClose(() => { this.visibleData = false; })
      } else {
        this.visibleData = false;
      }

    }
  },
  created() {
    this.visibleData = !!this.visible;
  },
  watch: {
    visible(v) {
      this.visibleData = v;
    },
    visibleData(v) {
      this.$emit('update:visible', v);
    },
  }
}
</script>

<style scoped lang="scss">
$preName: ".els-drawer-transition--";
$map: (
  right: translateX(100%),
  left: translateX(-100%),
  up: translateY(-100%),
  down: translateY(100%)
);
@each $key, $value in $map {
  #{$preName}#{$key}-enter,
  #{$preName}#{$key}-leave-to {
    opacity: 0;
    transform: $value;
  }
}
//
.els-drawer {
  transition: all 0.5s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  // background:#00000029;
  &.d--right {
    &,
    & .els-drawer--content {
      top: 0;
      right: 0;
    }
  }
  &.d--left {
    &,
    & .els-drawer--content {
      top: 0;
      left: 0;
    }
  }
  &.d--up {
    &,
    & .els-drawer--content {
      left: 0;
      top: 0;
    }
  }
  &.d--down {
    &,
    & .els-drawer--content {
      bottom: 0;
      left: 0;
    }
  }
  .els-drawer--content {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    .c--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      height: 60px;
      padding: 0 10px;
      border-bottom: 1px solid #ddd;
      background: #f0f2f5;
      .h--title {
        line-height: 60px;
        flex: 1;
        font-weight: 600;
        padding-right: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
      .h--close {
        padding: 2px;
      }
    }
    .c--body {
      // padding:20px;
      // box-sizing: border-box;
      height: calc(100% - 60px);
      overflow: auto;
      &.no--header {
        height: 100%;
      }
    }
  }
}
</style>