import "./wujie.config";
import Vue from "vue";
import ElementUI from "element-ui";
import elLocal from "element-ui/lib/locale/lang/zh-CN";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//
import socket from "./socket/index";
import plugins from "./config/plugins.js";
// 国际化
import i18n from "./i18n";
// 引用主题样式
import "./assets/css/themes-wangping.scss";
import VueClipboard from "vue-clipboard2";
// icon-font
import "./assets/icon-font/iconfont.css";
require("./assets/icon-font/iconfont");
//
//
Vue.use(VueClipboard);
// dev 环境下启用
// require('./mock/index')

elLocal.el.pagination = {
  goto: i18n.messages[i18n.locale]["d"]["goTo"],
  pageClassifier: i18n.messages[i18n.locale]["d"]["page"],
  pagesize: i18n.messages[i18n.locale]["d"]["itemPage"],
  total: i18n.messages[i18n.locale]["d"]["totalItem"],
};
Vue.use(ElementUI);
//
Vue.use(socket);
// 注册全局组件
Vue.use(plugins);
//
Vue.config.productionTip = false;
//
if (window.__POWERED_BY_WUJIE__) {
  let instance;
  window.__WUJIE_MOUNT = () => {
    // console.log(".........", instance?.$router, Vue.$router);
    // if (instance?.$router || Vue.$router) {
    //   console.log("...is has $router...");
    // }

    instance = new Vue({
      store,
      i18n,
      router,
      render: (h) => h(App),
    }).$mount("#app");
    // console.log("=========iot mount=========");
  };
  window.__WUJIE_UNMOUNT = () => {
    // instance.$destroy();
  };
  //
  /* 
  由于vite是异步加载，而无界可能采用fiber执行机制
    所以mount的调用时机无法确认，框架调用时可能vite
    还没有加载回来，这里采用主动调用防止用没有mount
    无界mount函数内置标记，不用担心重复mount
   */
  window.__WUJIE.mount();
  //
} else {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}
