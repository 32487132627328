// 默认链接桩组配置

const portAttrs = {
  circle: {
    magnet: true,
    r: 10,
    fill: "white",
    strokeWidth: 2,
  },
  rect: {
    width: 10,
    height: 10,
    rx: 2,
    strokeWidth: 2,
    fill: "white",
    xAlign: "middle",
    yAlign: "middle",
  },
};

export const portIn = {
  circle: {
    ...portAttrs.circle,
    magnet: "passive",
    stroke: "#6fb755",
  },
  rect: {
    ...portAttrs.rect,
    magnet: "passive",
    stroke: "#6fb755",
  },
}

export const portOut = {
  circle: {
    ...portAttrs.circle,
    magnet: true,
    stroke: "#f0a502",
  },
  rect: {
    ...portAttrs.rect,
    magnet: true,
    stroke: "#f0a502",
  },
}

export const portInOut = {
  circle: {
    ...portAttrs.circle,
    magnet: true,
    stroke: "#59a4d1",
  },
  rect: {
    ...portAttrs.rect,
    magnet: true,
    stroke: "#59a4d1",
  },
}

export const groups = {
  // 入边 绿色
  in: {
    position: "left",
    attrs: portIn
  },
  // 出边
  out: {
    position: "right",
    attrs: portOut
  },
  // 可出可入边
  in_out: {
    attrs: portInOut
  },
  // 单入边
  inOne: {
    position: "left",
    attrs: {...portIn,fill:'#409EFF'}
  },
  // 单出边
  outOne: {
    position: "right",
    attrs: {
      rect:{...portOut.rect,fill:'#409EFF'},
      circle:{...portOut.circle,fill:'#909399'}
    }
  },
  // 上入边
  inTop:{
    position:'top',
    attrs:portIn,
  },
  outBottom:{
    position:'bottom',
    attrs:portOut
  }
}