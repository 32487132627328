// import { renderSync } from "node-sass";
import AsyncValidator from "async-validator";
// 支持 v-model 指令的表单元素 有效表单元素
const elTags = ['el-input','el-autocomplete','el-radio','el-radio-group','el-checkbox','el-checkbox-group','el-input-number','el-select','el-cascader','el-switch','el-slider','el-time-picker','el-time-select','el-date-picker','el-rate','el-color-picker','el-transfer']
const selfTags = ['self-select','upload-avatar','item-table','els-monaco-editor','self-upload','els-file-data']
const pTags = ['el-radio-group','el-checkbox-group']
export const isFormElem = (tag,pTag = '') => {
  const b1 = elTags.includes(tag) || selfTags.includes(tag);
  return b1 && !pTags.includes(pTag)
}

// 父元素和子元素 tag 映射
const parentTags = {'el-radio-group':'el-radio','el-checkbox-group':'el-checkbox','el-select':'el-option'}
export const isHasChildTag = (tag = '') => {
  if(Object.prototype.hasOwnProperty.call(parentTags,tag)){ return parentTags[tag]}
  return false;
}

// 深度遍历对象所有属性，并删除 空值（[] | {}），无效值( undefined null )，同时设置 属性值，该属性值无论是何值，都不会被删除 默认不会改变原对象，而是会生成一个新对象
export const resetObj = (obj,keys,value) => {
  const d = clone(obj)
  const key = [...keys].pop();
  const len = keys.length;
  const f = (o,i = 0) => {
    const vk = i < len ? keys[i] : undefined;
    const ks = Object.keys(o)
    !ks.includes(vk) && vk && ks.push(vk)
    ks.forEach(k => {
      const rb = keys.includes(k)
      const v = o[k] == undefined ? (o[k] = (k == key?value:{})) : o[k];
      const r = (typeof v === 'object' && k != key) ? f(v,i + 1) : rb;
      r === false && (Object.keys(v).length == 0 || !r) && (delete o[k])
      //
      return rb
    })
  }
  //
  f(d)
  //
  return d;
}

const getType = (o) => {
  const t = Object.prototype.toString.call(o);
  return /^\[object (.*)\]$/.exec(t)[1].toLowerCase();
}
//
const isObject = (o) => {
  const t = getType(o);
  return ['object','array'].includes(t)
}

// 对象深度克隆
export const clone = (obj = {}) => {
  //
  const f = (o) => {
    if(!isObject(o)) return o;
    const t = getType(o);
    const r = t == 'array' ? [] : {};
    if(t == 'array'){
      o.forEach(v => {
        if(isObject(v)){
          r.push(f(v))
        }else{
          r.push(v)
        }
      })
    }
    else if(t == 'object'){
      Object.keys(o).forEach(k => {
        const v = o[k]
        if(isObject(v)){
          r[k] = f(v);
        }else{
          r[k] = v
        }
      })
    }
    //
    return r;
  }

  return f(obj);
}

// 对象深度合并
export const combine = (m = {},n = {}) => {
  //
  const res = clone(m);
  const has = Object.prototype.hasOwnProperty;

  const f = (r,s) => {
    const is = typeof r == typeof s && typeof r == 'object'
    if(!is) return s;
    const t = Array.isArray(r) ? 'array' : 'object'
    //
    if(t == 'array'){
      return [...r,...s]
    }else{
      Object.keys(s).forEach(k => {
        const b = has.call(r,k);
        const v = s[k]
        if(!b){
          r[k] = v;
        }else{
          r[k] = f(r[k],v)
        }
      })
      return r;
    }
  }
  //
  return f(res,n);
}

// 树型数据公共方法 tree = []
// 遍历
export const getChildren = (obj,cks = ['children','cls']) => {
  if(!Array.isArray(cks)){cks = [cks]}
  for(let i = 0,l = cks.length;i < l ; i++){
    const k = cks[i];
    if(typeof k == 'string' && obj[k] != undefined){
      return obj[k]
    }
  }
  return undefined
}
// 利用元素信息构造 rules
const CHANGE_TAG = ['self-select','el-select','el-cascader','el-checkbox-group']
export const buildRules = (elem = {},isFilter = false,lang = {msgPrefix:'请输入'}) => {

  // required message trigger rules
  const {tag,required,message = `${lang.msgPrefix} [ ${elem.label || elem.prop} ]`,trigger,rules} = elem
  //
  const eks = ExcludeKeys[tag] || [];
  if(!required && !rules) return undefined;
  //
  const res = []
  if(required){
    const isChange = CHANGE_TAG.includes(tag)
    res.push({required:true,trigger:trigger ? trigger : isChange ? 'change' : 'blur',message})
  }
  // if(pattern){
  //   res.push({pattern,message,trigger})
  // }
  if(rules && !eks.includes('rules')){
    res.push(...rules)
  }
  // filter
  if(isFilter){

    ['required','message','trigger','rules'].filter(k => !eks.includes(k)).forEach(k => delete elem[k])
  }
  //
  return res;
}


// 数据依旧采用直接绑定 els-form 数据，不需要冒泡解析
/*
 label prop && tag = form tag => add el-form-item
 label prop && !tag => add el-form-item && tag = el-input
 prop && !tag => tag = el-input
 tag = el-select && !child.tag => child.tag = el-options
 tag = el-radio-group && !child.tag => child.tag = el-radio
 tag = el-checkbox-group && !child.tag => child.tag = el-checkbox

*/
const elFormItemKeys = ['label','prop','labelWidth','rules','error','showMessage','inlineMessage','size']
const ExcludeKeys = {
  "item-table":["rules"]
}
export const hooks = {
  created(){
    const e = this.elem;
    const b = e.elFormItem == undefined ? true : !!e.elFormItem;
    this.tag = e.tag || this.defaultTag
    if(isFormElem(this.tag) && e.prop && b){
      this.hasElFormItem = true;
      const props = {}
      const eks = ExcludeKeys[this.tag] || []
      elFormItemKeys.forEach(k => {
        if(Object.prototype.hasOwnProperty.call(e,k) && !eks.includes(k)){
          props[k] = e[k]
        }
      })
      this.elFormItemContext = getType(e.elFormItem) == 'object' ? {props:{},...e.elFormItem} :{}
      // console.log('el-form-item-------------',this,this.props,this.context)
      this.elFormItemContext.props = {...props,...this.elFormItemContext.props}
    }
  },
  beforeContext(attrs){
    delete attrs.elformItem
  },
  render(h,tag,ctx,cds){
    if(this.hasElFormItem){
      // console.log('el-form-item-------------',tag,this.rules,ctx,cds,this.elFormItemContext.props)
      return h('el-form-item',{...this.elFormItemContext,props:{...this.elFormItemContext.props,rules:this.rules}},[h(tag,ctx,cds)])
    }else{
      return h(tag,ctx,cds)
    }
  }
}
//
export const options = {
  'el-select':{
    createChild(c){
      !c.tag && (c.tag = 'el-option')
    }
  },
  'el-radio-group':{
    createChild(c){
      !c.tag && (c.tag = 'el-radio')
    }
  },
  'el-checkbox-group':{
    createChild(c){
      !c.tag && (c.tag = 'el-checkbox')
    }
  },
  'el-form-item':{
    created(){
      // 初始化 props
      const parents = this.context.parents || [];
      const props = parents.filter((e) => e.elem.prop).map((e) => e.elem);
      this.elem.prop && props.push(this.elem);
      this.props = props;
      this.prop = props.length > 0 ?props.map(item => item.prop).join('.') : '';
      // 初始化 rules
      this.rules = this.context.source.buildRules(this)
    },
    context(ctx){
      delete ctx.props.required;
      ctx.props = {...ctx.props,rules:this.rules}
    },
    mounted(){
      if(!this.prop){ return ;}
      const elsForm = this.context.source;
      this.elFormItem = this.$children[0]
      // this.elFormItem.$on('el.form.blur', () => {console.log('.......')});
      this.elFormItem.validate = (...args) => {
        this.elFormItem.value = this.context.source.getValueByProp(this.prop);
        validate.call(this.elFormItem,elsForm,...args);
      }
      // this.context.source.fields.push(this.elFormItem)
    }
  }

}
// get value from object
export const getValueFormObject = (obj = {},keys = []) => {
  if(Object.keys(obj).length == 0){ return undefined }
  let tmp = obj;
  for(let i = 0,l = keys.length;i < l;i++){
    const k = keys[i];
    if(Object.prototype.hasOwnProperty.call(tmp,k)){
      tmp = tmp[k]
    }else{
      return undefined;
    }
  }
  return tmp;
}

export const validate = function(elsForm,trigger,callback){

  var _this = this;

  callback = callback || function(){};

  this.validateDisabled = false;
  var rules = this.getFilteredRule(trigger);
  if ((!rules || rules.length === 0) && this.required === undefined) {
    callback();
    return true;
  }

  this.validateState = 'validating';

  var descriptor = {};
  if (rules && rules.length > 0) {
    rules.forEach(function (rule) {
      delete rule.trigger;
    });
  }
  descriptor[this.prop] = rules;

  var validator = new AsyncValidator(descriptor)
  var model = {$data:elsForm.formData};

  model[this.prop] = this.value;
  validator.validate(model, { firstFields: true }, function (errors, invalidFields) {
    _this.validateState = !errors ? 'success' : 'error';
    _this.validateMessage = errors ? errors[0].message : '';
    callback(_this.validateMessage, invalidFields);
    _this.elForm && _this.elForm.$emit('validate', _this.prop, !errors, _this.validateMessage || null);
  });
}


