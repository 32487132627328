<template>
  <div class="els-text-card" @click="handleClick" :style="`width:${width}px;height:${height}px;`">
    <!-- 背景 -->
    <!-- 背景图，如果有的化 -->
    <img v-if="isBackImage" class="tc--background" :src="backImg" />
    <div v-if="isBackColor" class="tc--background" :style="`background-color:${backImg}`"></div>
    <!-- 图文 -->
    <div class="tc--content" :class="`layout-${layout}`" :style="`justify-content:${justify};align-items:${align}`">
      <!-- 图 -->
      <div v-if="image" :style="`width:${imageWidth}`" class="c--image">
        <img :src="image" />
      </div>
      <!-- 文 -->
      <div v-if="title || title2 || desc" class="c--text">
        <div v-if="title" class="t--title" :style="`font-size:${titleFontSize}px;color:${titleColor};`">
          <span class="text">{{title}}</span>
        </div>
        <div v-if="title2" class="t--title2" :style="`font-size:${title2FontSize}px;color:${title2Color};`">
          <span class="text">{{title2}}</span>
        </div>
        <div v-if="desc" class="t--desc" :style="`font-size:${descFontSize}px;color:${descColor};`">
          <span class="text">{{desc}}</span>
        </div>
        <div class="t--button" v-if="linkButtonConfig.enabled" v-bind="linkButtonConfig.options">
          <span @click="handleClick"> {{linkButtonConfig.text}} </span>
        </div>
      </div>
    </div>

    <!-- 子节点 -->
    <div class="tc--childs" v-if="children" :style="`justify-content:${cJustify};align-items:${cAlign}`">
      <template v-for="(child,ins) in children">
        <els-text-card :key="ins" v-bind="child"></els-text-card>
      </template>
    </div>
  </div>
</template>

<script>
import {merge} from "loadsh"

export default {
  name:'els-text-card',
  props:{
    options:Object, // 样式 或者其他扩展属性
    backImg:String, // 背景图或者颜色 默认 透明
    //
    image:String, //  图链接
    title:String, // 一级标题
    title2:String, // 二级标题
    desc:String, // 简介文本
    //
    link:String,
    linkButton:[Object,Boolean],
    params:Object,
    //
    children:Array, // 子卡片数据集合
    //
    width:{type:String,default:'100%'},
    height:{type:String,default:'100%'},
    layout:{type:String,default:'left-right'}, // 图文布局 left-right top-bottom right-left bottom-top
    justify:{type:String,default:'space-around'}, // 主轴对齐方式
    align:{type:String,default:'center'}, // 副轴对其方式
    //
    // 子卡片布局
    cJustify:{type:String,default:'space-around'},
    cAlign:{type:String,default:'center'}, // 副轴对其方式
    //
    imageType:{type:String,default:'rect'}, // 图片类型 rect | circle
    imageWidth:{type:[String],default:'20%'}, // 图片宽度，高度会按比例缩放
    titleFontSize:{type:Number,default:32}, //  一级标题字体大小
    title2FontSize:{type:Number,default:24}, //  一级标题字体大小
    descFontSize:{type:Number,default:14}, //  一级标题字体大小
    titleColor:{type:String,default:'#333'},
    title2Color:{type:String,default:'#666'},
    descColor:{type:String,default:'#999'},
  },
  computed:{
    isBackImage(){
      const bi = this.backImg || '';
      if(!bi) return false;
      //
      return /^http/.test(bi);
    },
    isBackColor(){
      const bi = this.backImg || '';
      return bi && /^#/.test(bi);
    },
    cardOptions(){
      const ops = this.options || {}
      const d = {}
      return merge({},d,ops)
    },
    linkButtonConfig(){
      if(typeof this.linkButton === 'boolean' || this.linkButton == undefined){
        return this.linkButton ?  {enabled:true,text:'详情 >>>',options:{}} : {enabled:false,options:{}}
      }
      else{
        return this.linkButton;
      }
    }
  },
  methods:{
    linking(){
      const link = this.link
      if(!link) return;
      this.$emit('link',this.params)
      if(/^http/.test(link)){
        window.open(link)
      }
      if(/^\//.test(link) && this.$router){
        this.$router.push(link,this.params)
      }
    },
    handleClick(){
      this.linking();
    }
  },
}
</script>

<style lang="scss" scoped>
.els-text-card {
  position:relative;
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  //
  .tc--background {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;

  }
  .tc--content{
    position:relative;
    width:100%;
    height:100%;
    flex:1;
    display:flex;
    &.layout-left-right{
      flex-direction: row;
    }
    &.layout-right-left{
      flex-direction: row-reverse;
    }
    &.layout-top-bottom{
      flex-direction: column;
    }
    &.layout-bottom-top{
      flex-direction: column-reverse;
    }
    .c--image{
      max-width: 50%;
      img{
        width:100%;
      }
    }
    .c--text{
      max-width: 50%;
      .t--button{
        margin-top:1em;
        >*{
          cursor: pointer;
        }
      }
    }
  }
  .tc--childs{
    display:flex;
  }
}
</style>

