import { getType, getElemContext, tagOptions } from "./util.js";

// v2 版本 解决 slot 和 slot-scope 指令问题 优化了 hooks 的调用方式
export default {
  name: "els-elem",
  componentName: 'elsElem',
  props: {
    elem: Object,
    context: { type: Object, default: () => ({}) },
    defaultTag: { type: String, default: 'div' }
  },
  data: () => ({
    // $_time:new Date().getTime()
    elsElem__scopedSlots: {},
    elsElem__data: {},
  }),
  computed: {
    $_if(){return this.$('vif') == undefined || this.$('vif');},
    $_tag() {
      return this.elem.tag || this.defaultTag;
    },
    $_hooks() {
      const ch = this.context.hooks || {};
      const eh = this.elem.hooks || {};
      const res = { ...eh };
      Object.keys(ch).forEach(k => {
        res[k] = res[k] ? [].concat(res[k], ch[k]) : ch[k]
      })
      return res;
    },
    $_options() {
      const tag = this.$_tag;
      const ops = this.context.options || {};
      const o = { ...tagOptions, ...ops };
      const t = o[tag] || {};
      return { ...t };
    },
    $_childs() {
      const d = this.$('children') || this.$('cls') || []
      const childs = this.elem.children || this.elem.cls || [];
      return [].concat(childs, d);
    },
    $_scoped() {
      if (!this.context.$scoped) return {};
      const d = this.context.$scoped;
      const sc = this.elem.slotScope || this.elem["slot-scope"];
      //
      if (sc) {
        // 支持解构赋值 {key:vk,key}
        const res = {};
        const b = /^\{/.test(sc)
        if (b) {
          const scs = sc.replace(/[{}]/g, '').split(',')
          scs.forEach(key => {
            const keys = key.split(':');
            const sk = keys[0]
            const vk = keys[1] ? keys[1] : keys[0];
            res[vk] = d[sk]
          })
        } else {
          res[sc] = d;
        }
        return res;
      }
      return d;
    }
  },
  methods: {
    $_defaultInit() {
      // 对 scoped 进行处理和赋值
      const scd = this.$_scoped;
      Object.keys(scd).forEach(k => {
        const v = scd[k]
        if (!this[k]) { this[k] = v }
        if (this.$(k) === undefined) {
          this.$(k, v)
        }
      })
    },
    $_utilHook(n, ...args) {
      let f = this.$_options[n] || [];
      let h = this.$_hooks[n] || [];
      //
      const fns = [].concat(f, h);
      if (fns.length === 0) {
        return undefined;
      }
      fns.forEach((f) => {
        if (typeof f === "function") {
          f.call(this, ...args);
        }
      });
      //
      return undefined;
    },
    $_createContext() {
      const scopedSlots = this.elem.scopedSlots ? { ...this.elsElem__scopedSlots, ...this.elem.scopedSlots } : this.elsElem__scopedSlots;
      const attr = { ...this.elem, scopedSlots }
      this.$_utilHook("beforeContext", attr);
      const ctx = getElemContext(attr);
      this.$_utilHook("context", ctx);
      return ctx;
    },
    $_createChild(c, h,index) {
      if (getType(c) === 'object') {
        this.$_utilHook('createChild', c, h)
        const ctx = {key:index + '-' + (c?.key || '')}
        const context = { ...this.context, parent: this, parents: [...(this.context.parents || []), this] }
        ctx.props = { elem: c, defaultTag: this.defaultTag, context };
        // 解析 slot 和 slot-scope
        if (c.slot) { ctx.slot = c.slot }
        if (c.slotScope || c['slot-scope']) {
          const ct = h("els-elem", { ...ctx, slot: c.slot })
          this.elsElem__scopedSlots[c.slot] = (p) => {
            context.$scoped = p;
            return ct;
          };
          return ct;
        }
        return h("els-elem", ctx);
      }
      return c;
    },
    $_createChilds(h) {
      return [...this.$_childs].map((c,index) => this.$_createChild(c, h,index));
    },
    $(k, ...args) {
      if (args.length === 0) {
        return this.elsElem__data[k]
      } else {
        const v = args[0];
        this.$set(this.elsElem__data, k, v)
      }
    }
  },
  created() {
    this.$_defaultInit()
    this.$_utilHook("created", this);
  },
  beforeMount() {
    this.$_utilHook("beforeMount", this);
  },
  mounted() {
    this.$_utilHook("mounted", this);
  },
  beforeUpdate() {
    this.$_utilHook("beforeUpdate", this);
  },
  updated() {
    this.$_utilHook("updated", this);
  },
  beforeDestroy() {
    this.$_utilHook("beforeDestroy", this);
  },
  destroyed() {
    this.$_utilHook("destroyed", this);
  },
  render(h) {
    // console.log("render ======>>>>> ", this.$_tag, this.elem,new Date().getTime());
    if(!this.$_if){return undefined;}
    // render 函数替换
    const r = this.$_options.render;
    if (r && typeof r === 'function') {
      return r.call(this, h);
    }
    //
    const tag = this.$_tag;
    //
    const cds = this.$_createChilds(h);
    //
    const ctx = this.$_createContext();
    //

    //
    const rd = [].concat(this.$_hooks.render);
    if (rd.length > 0 && typeof rd[0] == 'function') {
      return rd[0].call(this, h, tag, ctx, cds)
    } else {
      return h(tag, ctx, cds);
    }
  },
};
