<template>
  <div class="els-file-data">
    <div class="efd--file-name">
      <span>{{name}}</span>
    </div>
    <el-upload class="efd--upload" drag action='' :auto-upload="false" :on-change="handleUploadChange" :show-file-list="false">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击导入</em></div>
    </el-upload>
    
  </div>
</template>

<script>
export default {
  name:'els-file-data',
  props:{
    value:{Object,default:() => ({})},
    keys:{type:Object,default:() => ({name:'name',data:'data'})},
    filter:Function,
  },
  data:() => ({
    name:'',
    data:'',
    flag:false,
  }),
  computed:{},
  methods:{
    handleUploadChange(file){
      const _this = this;
      var reader = new FileReader();
      this.name = file.name;
      reader.readAsText(file.raw);
      reader.onload = function(){
        _this.data = this.result;
        if(_this.filter){
          _this.data = _this.filter(d);
        }
        //
        _this.onChange();
      }
    },
    onChange(){
      const nk = this.keys.name;
      const dk = this.keys.data;
      if(nk && typeof nk === 'string'){
        this.$emit('input',this.name,nk);
      }
      if(dk && typeof dk === 'string'){
        this.$emit('input',this.data,dk)
      }
      this.flag = true;
    },
  },
  watch:{
    value(v = {}){
      if(this.flag) {this.flag = false; return ;}
      const nk = this.keys.name;
      const dk = this.keys.data;
      //
      this.name = v[nk]
      this.data = v[dk]
    }
  },
  mounted(){
    const v = this.value;
    const nk = this.keys.name;
    const dk = this.keys.data;
      //
      this.name = v[nk] || ''
      this.data = v[dk] || ''
  }
}
</script>

<style lang="scss" scoped>
.els-file-data{
  width:100%;
  background-color:#fff;
  display:flex;
  
  ::v-deep .efd--upload{
    min-width:300px;
    .el-upload{
      display:block;
      .el-upload-dragger{
        width:100%;
        height:40px;
        display:flex;
        justify-content: center;
        align-items: center;
        >i{
          margin:0;
          font-size:40px;
          margin-right:8px;
        }
      }
    }
  }
  .efd--file-name{
    height:40px;
    flex:1;
    max-width:calc(100% - 300px);
    box-sizing: border-box;
    display:flex;
    align-items: center;
    padding:0 24px;
    >span{
      color:#333;
      overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
  }
}
</style>