<template>
  <div class="els-button-group" v-bind="group">
    <template v-for="(el, ins) in list">
      <template v-if="el.type === 'delimiter'">
        <el-divider
          v-if="el.divider"
          :key="ins"
          direction="vertical"
          class="g--divider g--delimiter"
        ></el-divider>
        <i v-else :key="ins" class="g--delimiter">{{ el.text }}</i>
      </template>
      <template v-else>
        <template v-if="el.data.tooltip">
          <el-tooltip :key="ins" v-bind="el.data.tooltip">
              <el-button
              class="g--item"
              :class="{'no-text':!el.text,'is-selected':currentActiveName && currentActiveName === utilGetActiveName(el.data)}"
              :key="ins"
              v-bind="el.props"
              @click.stop="handleClick($event,{params,data:el.data})"
              @mousedown.native="handleMousedown($event,{params,data:el.data})"
              @mouseup.native="handleMouseup($event,{params,data:el.data})"
            >
              <template v-if="el.text">
                <slot name="text" v-bind="el.data">{{ el.text }}</slot>
              </template>
              
            </el-button>  
          </el-tooltip>
        </template>
        <template v-else>
          <el-button
            class="g--item"
            :class="{'no-text':!el.text,'is-selected':currentActiveName && currentActiveName === utilGetActiveName(el.data)}"
            :key="ins"
            v-bind="el.props"
            @click.stop="handleClick($event,{params,data:el.data})"
            @mousedown.native="handleMousedown($event,{params,data:el.data})"
            @mouseup.native="handleMouseup($event,{params,data:el.data})"
          >
            <template v-if="el.text">
                <slot name="text" v-bind="el.data">{{ el.text }}</slot>
            </template>
          </el-button>
        </template>
      </template>
      
    </template>
    <slot v-if="!slotText" v-bind="params"></slot>
  </div>
</template>

<script>
import {getBindValue} from "@/utils/util.js"
/* 
:::
* 通用组件属性规则
* * 公共属性 options 主要用于补充主要功能或者次要功能的属性配置
* * 隐藏属性 通过 $attrs.[属性名] 获取，不会出现在 props 中的属性 主要用于次要功能的属性配置，之所以设计影藏属性就是为了方便使用
* * 主要属性 配置在 props 中的属性 options 也是配置在 props 中的，但是这里 主要属性不包括 options 属性，主要属性是组件必须的属性，没有它组件就无法运行
* * 影藏属性 优先级最高，其次是公共属性，隐藏属性和公共属性一般都是完整的配置，主要属性中的配置一般都是为了方便而设定的，主要属性主要是为了完成主要功能的属性配置，次要功能的属性一般可通过主要属性中的字段自动生成，次要功能完整的配置可通过 公共属性以及隐藏属性配置，一旦次要功能的属性在 options 或者 隐藏属性中配置了，将自动忽略自动生成的属性配置
* * 所有能通过 $attrs.[属性名] 配置的属性都可以通过 options.[属性名] 配置 因此在设计公共属性的时候注意命名冲突
* * 针对事件的配置，如果触发了事件，首先搜索有没有该事件的处理方法，有则调用该事件的处理方法，没有则将事件传递出去，事件属性名规范: {'@submit':(form,o,e) => {}}
::: 
*/

export default {
  name: "els-button-group",
  props: {
    options: { type: Object, default: () => ({}) }, // 公共属性
    column: Array,
    params:[Object,Array,String,Boolean], // 触发事件需要传递的额外参数
    active:[Boolean,Object,String], // 配置按钮选中状态
    currentActive:String, // 当前选中的值 通过 v-model
    
  },
  data:() => ({
    currentActiveName:'',
  }),
  computed: {
    group(){
      const o = this.$attrs.group || this.options.group || {};
      const d = {};
      return { ...d, ...o };
    },
    attrs() {
      const o = this.$attrs.attrs || this.options.attrs || {};
      const d = {};
      return { ...d, ...o };
    },
    type() {
      const o = this.$attrs.type || this.options.type || 'default';
      const b = typeof o === "string";
      return b ? { type: o } : {};
    },
    size() {
      const o = this.$attrs.size || this.options.size;
      const b = typeof o === "string";
      return b ? { size: o } : {};
    },
    icon() {
      const o = this.$attrs.icon || this.options.icon;
      const b = typeof o === "string";
      return b ? { icon: o } : {};
    },
    // 按钮权限 ['add','edit','view'] || {add:true,edit:}
    permissions() {
      const p = this.$attrs.permissions || this.options.permissions;
      const b = typeof o === "object";
      if (!p || !b) {
        return undefined;
      }
      const isArr = Array.isArray(p);
      let r = [];
      if (!isArr) {
        const keys = Object.keys(p);
        keys.forEach((k) => {
          if (p[k]) {
            r.push(k);
          }
        });
      } else {
        r = [...p];
      }
      return r;
    },
    delimiter() {
      let o = this.$attrs.delimiter || this.options.delimiter;
      if (!o) {
        return undefined;
      }
      if (o === "divider") {
        o = { divider: true };
      }
      const t = typeof o;
      let d = { type: "delimiter" };
      switch (t) {
        case "string":
          d = { ...d, text: o };
          break;
        case "object":
          d = { ...d, ...o };
          break;
        case "boolean":
          d = { ...d,text:"" };
          break;
        default:
          d = { ...d, text: "|" };
      }
      return d;
    },
    slotText(){
      const sd = this.$slots.default;
      const t = sd && sd.length === 1 && sd[0] && sd[0].text;
      return t;
    },
    buttons() {
      const text = this.slotText || 'button';
      const column = this.column || [{text}];
      const attrs = this.attrs;
      const type = this.type;
      const size = this.size;
      const icon = this.icon;
      const d = { ...type, ...size,...icon,...attrs };
      //
      const p = this.permissions;
      // 权限过滤
      const cols = column.filter((col) => {
        if (!p) {
          return true;
        }
        // 某些不需要权限控制的按钮可以用这个属性脱离权限控制
        if(col.noPermissions === true){
          return true;
        }
        const k = col.key || col.prop || col.name;
        return p.indexOf(k) !== -1;
      });
      const btns = cols.map((col) => ({
        data:col,
        props: { ...d, ...this.utilGetColProp(col) },
        text: col.text || col.label || this.utilGetColProp(col).text,
        handle: col.handle,
      }));
      return btns;
    },
    list() {
      const temp = this.delimiter;
      const btns = this.buttons;
      let list = [...btns];
      if (temp && btns.length > 1) {
        list = [];
        for (let i = 0, l = btns.length; i < l; i++) {
          const b = btns[i];
          list.push(b);
          if (i < l - 1) {
            list.push(temp);
          }
        }
      }
      return list;
    },
    actived(){
      if(!this.active){
        return {is:false};
      }
      const t = typeof this.active;
      // name:key 的名称 默认 key value : 初始选中的值 is:是否开启选中模式
      const d = {name:'key',value:'',is:true}
      if(t === 'string'){
        return {...d,name:this.active}
      }
      if(t === 'object'){
        return {...d,...this.active}
      }
      return {...d}
    },
  },
  methods: {
    handleClick(e,params = {}){
      const col = params.data;
      const an = this.utilGetActiveName(col);
      if(an !== undefined){this.currentActiveName = an;}
      const k = col.event || col.key || col.prop || col.name;
      if(col.handle && typeof col.handle === 'function'){
        col.handle(params,e)
      }
      else if(k){
        this.$emit(k,params,e)
      }
      this.$emit('click',params,e)
    },
    handleMousedown(e,params = {}){
      this.$emit('mousedown',params,e)
    },
    handleMouseup(e,params = {}){
      this.$emit('mouseup',params,e)
    },
    utilGetColProp(col = {}) {
      const extKeys = ["text", "handle","tooltip",'attrs'];
      const keys = Object.keys(col);
      let r = {};
      keys.forEach((k) => {
        if (extKeys.indexOf(k) === -1) {
          const [key,val] = getBindValue(k,col[k],this)
          r[key] = val;
        }
      });
      if(col.attrs && typeof col.attrs === 'function'){
        const er = col.attrs.call(this,this.params,this.options) || {};
        r = Object.assign(r,er)
      }
      return r;
    },
    utilGetActiveName(col = {}){
      const o = this.actived;
      if(!o.is || col.active === false){return undefined;}
      const n = o.name || 'key';
      return col[n];
    },
  },
  watch:{
    currentActive(v){
      if(v === this.currentActiveName){
        return ;
      }
      this.currentActiveName = v;
    },
    currentActiveName(v){
      this.$emit('update:currentActive',v);
    }
  },
  created(){
    if(this.actived){
      this.currentActiveName = this.currentActive || this.actived.value;
    }
  }
};
</script>

<style scoped lang="scss">
.els-button-group{
  position: relative;
}
.el-button.is-selected{
  box-shadow:0 0 15px 0 #00000088 inset;
}
</style>