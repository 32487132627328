<template>
  <div class="els-form-group">
    <fieldset class="g--fieldset">
      <legend class="f--legend">{{ title }}</legend>
      <div class="f--content">
        <slot></slot>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name:"els-form-group",
  props:{
    title:String
  },
}
</script>

<style scoped lang="scss">
.els-form-group{
  &:not(:last-child){
    margin-bottom:10px;
  }
  .g--fieldset{
    height:100%;
    border-color:#f0f2f5;
    border-radius: 8px;
    border-style: dashed;
    padding:10px;
    background-color:#00000005;
    .f--legend{
      margin-left:10px;
      color:#999;
      font-size:14px;
    }
    .f--content{
      height:100%;
    }
  }
}
</style>