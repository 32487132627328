// 自定义元素扩展
import { Shape, ObjectExt } from "@antv/x6";
//
import { groups } from "./ports";
//
const statusIcons = {
  success: "el-icon-success",
  error: "el-icon-error",
  warning: "el-icon-warning",
};
// 矩形节点 链接桩再创建节点时自行添加
export const myRect = Shape.Rect.define({
  constructorName: "my-rect",
  overwrite: true,
  width: 200,
  height: 40,
  //
  attrs: {
    root: {
      // 不允许直接连线到节点
      magnet: false,
    },
    body: {
      rx: 8,
      stroke: "#5755a1",
      fill: "#5755a1",
    },
    label: {
      textAnchor: "middle",
      textVerticalAnchor: "middle",
      fill: "#333",
      stroke: "#333",
      strokeWidth:0.2,
      fontSize: 14,
      refWidth: 14,
      refHeight: 14,
      textWrap: {
        width: -8,
        height: -8,
        ellipsis: true,
        breakWord: true,
      },
    },
  },
  // 链接桩配置
  ports: { groups },
});

export const iconRect = myRect.define({
  markup: [
    { tagName: "rect", selector: "body", ns: "http://www.w3.org/2000/svg" },
    {
      tagName: "foreignObject",
      selector: "iconElem",
      ns: "http://www.w3.org/2000/svg",
      children: [
        {
          tagName: "div",
          selector: "iconContainer",
          ns: "http://www.w3.org/1999/xhtml",
          children: [
            {
              tagName: "i",
              selector: "icon",
              ns: "http://www.w3.org/1999/xhtml",
            }
          ],
        },
      ],
    },
    {tagName:'text',selector:'info',ns:'http://www.w3.org/2000/svg'},
    { tagName: "text", selector: "label", ns: "http://www.w3.org/2000/svg" },
    {
      tagName: "foreignObject",
      selector: "statusElem",
      ns: "http://www.w3.org/2000/svg",
      children: [
        {
          tagName: "div",
          selector: "statusContainer",
          ns: "http://www.w3.org/1999/xhtml",
          children: [
            {
              tagName: "i",
              selector: "status",
              ns: "http://www.w3.org/1999/xhtml",
            },
          ],
        },
      ],
    },
    {
      tagName: "foreignObject",
      ns: "http://www.w3.org/2000/svg",
      children: [
        { tagName: "div", selector: "tip", ns: "http://www.w3.org/1999/xhtml" },
      ],
    },
  ],
  attrs: {
    iconElem: {
      refX: 5,
      width: 40,
      refHeight: "100%",
    },
    iconContainer: {
      style: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#333",
        fontSize: "24px",
      },
    },
    icon: {
      class: "el-icon-edit",
      xAlign: "middle",
      yAlign: "middle",
    },
    info: {
      text: "",
      textAnchor: "left",
      textVerticalAnchor:'top',
      refX:45,
      refY:4,
      fontSize:12,
      fill:'#999',
      textWrap: {
        ellipsis: true,
        breakWord: false,
      },
    },
    label: {
      refX: 45,
      textAnchor: "left",
      height:"20%",
      textWrap: {
        width: -78,
        height: 20,
        ellipsis: true,
        breakWord: false,
      },
    },
    statusElem: {
      refX: "100%",
      refX2: -5,
      width: 20,
      refHeight: "100%",
      xAlign: "right",
    },
    statusContainer: {
      // class:'status--success',
      style: {
        fontWeight: "bold",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    status: {
      // class:'el-icon-success',
    },
  },
  // propHooks(metadata){ console.log('.....',metadata) ;return metadata;}
  propHooks: {
    icon(metadata) {
      const { icon, ...others } = metadata;
      if (icon != null) {
        ObjectExt.setByPath(others, "attrs/icon/class", icon);
      }
      return others;
    },
    info(metadata) {
      const { info, ...others } = metadata;
      //console.log('++++++++',info)
      if (info != null) {
        ObjectExt.setByPath(others, "attrs/info/text", info);
        ObjectExt.setByPath(others, "attrs/label/refY2", info?6:0);
      }
      return others;
    },
    status(metadata) {
      const { status, ...others } = metadata;
      if (status != null) {
        ObjectExt.setByPath(
          others,
          "attrs/statusContainer/class",
          "status--" + status
        );
        ObjectExt.setByPath(
          others,
          "attrs/status/class",
          statusIcons[status] || status
        );
      }
      return others;
    },
  },
});

export const editRect = iconRect.define({
  ports: {
    items: [
      { id: "port-in", group: "in" },
      { id: "port-out", group: "out" },
    ],
  },
});
// 自定义边
export const defaultEdge = Shape.Edge.define({
  shape: "edit-edge",
  overwrite: true,
  // 边属性
  attrs: {
    line: {
      strokeWidth: 2,
      stroke: "#1890ff",
    },
  },
  // router: { name: "metro",args:{startDirections: ['right'],endDirections: ['left'],} },
  connector:{name:'smooth'},
  // connector: { name: "jumpover" },
  // 标签属性
  defaultLabel: {
    markup: [
      {
        tagName: "rect",
      },
      {
        tagName: "text",
        selector: 'label',
      },
    ],
    attrs: {
      text: {

        fill: "#fff",
        // stroke:"#fff",
        // strokeWidth:1,
        fontSize: 14,
        textAnchor: "middle",
        textVerticalAnchor: "middle",
        pointerEvents: "none",
      },
      rect: {
        ref: "text",
        event: 'cell:edit',
        fill: "#409EFF",
        stroke: "#7ab9ca",
        strokeWidth: 0,
        rx: 4,
        refWidth: "100%",
        refWidth2:4,
        refHeight: "100%",
        refHeight2:4,
        xAlign: "middle",
        yAlign: "middle",
      },
    },
    position: {
      distance: 0.5,
      // options: {
      //   keepGradient: true,
      //   ensureLegibility: true,
      // },
    },
  },
});

// 蚁行线
export const antEdge = defaultEdge.define({
  shape: "ant-edit-edge",
  overwrite: true,
  //
  attrs: {
    line: {
      strokeDasharray: [20,2],
      targetMarker: "classic",
      style: {
        animation: "ant-line 30s infinite linear",
      },
    },
  },
});

export const cells = {
  edges: {
    "edge-default": defaultEdge,
    "edge-static": antEdge,
    "edge-edit": defaultEdge,
    "edge-ant": antEdge,
    "edge-edit-ant": antEdge,
  },
  nodes: {
    "my-rect": myRect,
    "edit-rect": editRect,
    "icon-rect": iconRect,
  },
};

// 自动注册 API
export const register = (Graph) => {
  if (!Graph) return;
  const { edges, nodes } = cells;
  //
  Object.keys(edges).forEach((name) => {
    const ce = edges[name];
    Graph.registerEdge(name, ce, true);
  });
  //
  Object.keys(nodes).forEach((name) => {
    const ce = nodes[name];
    Graph.registerNode(name, ce, true);
  });
};
