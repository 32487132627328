<template>
  <div class="self-table els-table" ref="boxRef">
    <el-table
      ref="elTableRef"
      v-bind="tableOption"
      :header-row-style="{height:'50px'}"
      :data="dataList"
      @sort-change="sortChange"
      @filter-change="filterChange"
      :height="tableHeight"
      :empty-text="$t('d.noData')"
      @cell-click="rowClick"
    >
      <!-- 展开行 -->
      <template v-if="showExpand">
        <el-table-column
          type="expand"
          :fixed="isFixed"
          v-bind="mixinsColumnOption"
        >
          <template slot-scope="scope">
            <slot name="expand" v-bind="scope"></slot>
          </template>
        </el-table-column>
      </template>
      <!-- 索引 -->
      <template v-if="showIndex">
        <el-table-column
          class="self-table--index"
          :index="mixinsIndex"
          :label="lang.index"
          v-bind="mixinsColumnOption"
          width="80px"
          align="center"
          headerAlign="center"
          :fixed="isFixed"
        >
        <template slot-scope="{$index, row}">
        <slot name="index" v-bind="{$index, row, index: dataList.indexOf(row)+1}">
          <span>{{ mixinsIndex($index) }}</span>
        </slot>
         </template>
        </el-table-column>
      </template>
      <el-table-column
        v-if="showCheckbox"
        type="index"
        width="50"
        align="center"
      >
        <template slot="header" slot-scope="s">
          <el-checkbox
            :indeterminate="selectionData.indeterminate"
            v-model="selectionData.check"
            @change="handleSelectAll(s)"
          ></el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            :indeterminate="scope.row.$indeterminate"
            v-model="scope.row.$check"
            @change="handleSelect(scope)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <!-- <template v-if="showCheckbox">
        <el-table-column type="selection" width="55"> </el-table-column>
      </template> -->
      <!-- 自定义 -->
      <!-- <template v-for="(col, ins) in column" > -->
      <el-table-column
        v-for="(col, ins) in column"
        v-bind="{ ...mixinsColumnOption, ...col }"
        :key="'table-item-' + ins + col.prop"
      >
        <!-- <template slot="header" slot-scope="scope" v-if="list.length > 0">
          <el-tooltip
            :content="scope.column.label"
            placement="top"
            popper-class="self-tooltip-popper--mike"
          >
            <span
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              {{ scope.column.label }}
            </span>
          </el-tooltip>
        </template> -->
        <!-- <slot v-if="col.hasSlot" :name="col.prop" slot-scope="scope" v-bind="scope"></slot> -->
        <template slot-scope="scope" v-if="list.length > 0">
          <slot v-if="col.hasSlot" :name="col.prop" v-bind="scope"></slot>
          <template v-else>
            <template v-if="col.elsElem">
              <els-elem
                :elem="col.elem"
                :context="{ ...elemContext, ...scope, col }"
              ></els-elem>
            </template>
            <template v-else-if="col.dict">
              <div class="t--dict" @click.stop="handleRowClick(col, scope)">
                <dict-item
                  v-bind="utilDict(col.prop, scope, col.dict)"
                ></dict-item>
              </div>
              <!-- <span class="t--dict">{{
                  utilDict(col.prop, scope, col.dict)

                }}</span> -->
            </template>
            <template v-else-if="col.valueFilter">
              <span
                class="t--filter"
                @click.stop="handleRowClick(col, scope)"
                >{{ col.valueFilter(scope, col) }}</span
              >
            </template>
            <template v-else>
              <span class="t--default" @click.stop="handleRowClick(col, scope)">
                {{ utilFormat(scope.row[col.prop], col, scope.row) }}</span
              >
            </template>
          </template>
        </template>
      </el-table-column>
      <!-- </template> -->
      <!-- menu -->
      <template v-if="showMenu">
        <el-table-column
          :label="lang.operator"
          :fixed="isFixed ? 'right' : false"
          :width="menuWidth"
          :align="menuAlign"
          v-bind="mixinsColumnOption"
          :show-overflow-tooltip="false"
          header-align="center"
        >
          <template slot-scope="scope">
            <template v-for="(item, ins) in menuList">
              <template v-if="ins > 0 && showMenuDivider">
                <el-divider :key="ins" direction="vertical"></el-divider>
              </template>
              <template v-if="menuModel == 'icon'">
                <el-tooltip
                  :key="ins"
                  :content="item.label"
                  placement="top"
                  popper-class="self-tooltip-popper--mike"
                >
                  <el-button
                    class="self-table--menu-btn model--icon"
                    size="mini"
                    circle
                    plain
                    :icon="item.icon"
                    :type="item.type"
                    @click="item.handle(scope)"
                  ></el-button>
                </el-tooltip>
              </template>
              <template v-if="menuModel == 'icon-text'">
                <el-button
                  class="self-table--menu-btn model--icon-text"
                  :key="ins"
                  plain
                  size="mini"
                  :type="item.type"
                  :icon="item.icon"
                  @click="item.handle(scope)"
                  >{{ item.label }}</el-button
                >
              </template>
              <template v-if="menuModel == 'text'">
                <el-button
                  class="self-table--menu-btn model--text"
                  :key="ins"
                  size="mini"
                  :type="item.type"
                  @click="item.handle(scope)"
                  >{{ item.label }}</el-button
                >
              </template>
            </template>
            <slot name="menu" v-bind="{ ...scope }"></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div v-if="isLoading" class="c--loading">
      <p>
        <i class="el-icon-loading"> </i>
        <span> {{ $t("d.loading") }}</span>
      </p>
    </div>
    <div ref="footerRef" class="c--pagination" v-if="showPagination">
      <el-pagination
        background
        :total="page.total"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="page.pageSizes"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :layout="page.layouts[0]"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import mixinsPage from "./page";
import dictItem from "./dict-item";
import { get, has, chunk } from "loadsh";
import { tempReplace } from "@/utils/util.js";
const dateFormat = (date, format) => {
  format = format || "yyyy-MM-dd hh:mm:ss";
  if (date !== "Invalid Date") {
    let o = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (let k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return format;
  }
  return "";
};

export default {
  name: "els-table",
  mixins: [mixinsPage],
  components: { dictItem },
  props: {
    onload: Function, // 表格 加载 方法
    column: Array,
    data: Array,
    params: Object,
    filter: Function,
    filterValue: String,
    showExpand: { type: Boolean, default: false }, // 是否显示展开行
    isFixed: { type: Boolean, default: true }, // 是否显示展开行
    showIndex: { type: Boolean, default: true }, // 是否显示索引
    indexType: { type: String, default: 'index' },
    showMenu: { type: Boolean, default: true }, // 是否显示操作栏
    showMenuDivider: { type: Boolean, default: true },
    showPagination: { type: Boolean, default: true }, // 是否显示分页
    menu: Array, // 控制单个菜单是否显示
    isLeaf: {
      type: Function,
      default: (n) => n.children == undefined || n.children.length == 0,
    },
    idKey: { type: String, default: "id" },
    menuWidth: { type: String, default: "120px" },
    menuModel: { type: String, default: "text" },
    menuAlign: { type: String, default: "center" },
    menulist: Array, // 菜单列表
    rowDelete: Function, // 行删除
    rowView: Function, // 行 查看
    rowEdit: Function, // 行 编辑
    rowApply: Function, // 行应用
    options: { type: Object, default: () => ({}) }, //  属性集合,
    authorityName: { type: String, default: "" }, // 操作权限必须配置
    showCheckbox: Boolean, // 是否多选
    loading: Boolean,
    operate: String,
    init: Function,
  },
  data() {
    return {
      list: this.data || [],
      tableHeight: 200,
      isLoading: false,
      multipleSelection: [],
      //
      rowKey: undefined,
      selectionData: {
        check: false,
        indeterminate: false,
        checkIds: new Set(),
        indeterIds: new Set(),
        leafCheckIds: new Set(),
      },
    };
  },
  computed: {
    lang() {
      const o = !!this.operate ? this.operate : this.$t("d.operate");
      // const table = this.$t("ai.table");
      return { index: this.$t("d.orderNum"), operator: o };
    },
    tableOption() {
      const ops = this.$attrs.table || this.options.table || {};
      const d = {
        border: true,
        stripe: true,
        highlightCurrentRow: true,
        headerCellStyle: { height: "38" },
        rowStyle: { height: "38px" },
        cellStyle: { padding: "0px" },
      };
      return { ...d, ...ops, border: true };
    },
    // menusAnthority(){
    //   const menusA = this.$store.state.login.pagePermission;
    //   console.log(menusA)
    //   return menusA;
    // },
    menuList() {
      const t = this.lang;
      const ms = [
        {
          index: 1,
          name: "view",
          label: t.viewBtn,
          type: "text",
          icon: "el-icon-view",
          handle: this.handleView,
        },
        {
          index: 2,
          name: "edit",
          label: t.editBtn,
          type: "text",
          icon: "el-icon-edit",
          handle: this.handleEdit,
        },
        {
          index: 3,
          name: "delete",
          label: t.deleteBtn,
          type: "text",
          icon: "el-icon-delete",
          handle: this.handleDelete,
        },
        {
          index: 4,
          name: "apply",
          label: t.applyBtn,
          type: "text",
          icon: "el-icon-thumb",
          handle: this.handleApply,
        },
      ];
      const defMenu = [];
      if (this.rowView) {
        defMenu.push("view");
      }
      if (this.rowEdit) {
        defMenu.push("edit");
      }
      if (this.rowApply) {
        defMenu.push("apply");
      }
      if (this.rowDelete) {
        defMenu.push("delete");
      }

      const menu = this.menu || defMenu;
      // const aName = this.authorityName.split('/');
      // let menusA = this.$store.state.login.pagePermission;
      // aName.forEach(k => menusA = menusA[k]?menusA[k]:menusA);

      let d = ms.filter((m) => menu.includes(m.name) || menu.includes(m.index));
      // d = d.filter(m => menusA[m.name]);
      const list = this.menulist || [];
      return [...d, ...list];
    },
    dataList() {
      const list =
        this.filter && typeof this.filter == "function"
          ? this.filter(this.list, this.filterValue)
          : this.list;
      if (!this.showPagination) return list;
      const len = list.length;
      const size = this.page.pageSize;
      if (len <= size) {
        return list;
      }
      const lists = chunk(list, size);
      const num = this.page.pageNum;
      return lists[num - 1];
      // const s = (num - 1) * size;
      // const e = s + size > len ? len : s + size;
      // let res = [];
      // for (let i = s; i < e; i++) {
      //   res.push(list[i]);
      // }
      // return res;
    },
    elTableHeight() {
      return this.tableHeight;
    },
    elemContext() {
      return {
        elsTable: this,
      };
    },
    listNodes() {
      const list = this.dataList;
      return this.getTreeNodes(list);
    },
  },
  methods: {
    onLoad({ pageNum, pageSize } = this.page, params = {}) {
      if (!this.onload) {
        this.page.pageNum = pageNum;
        return;
      }
      this.list = [];
      const p = this.params || {};
      this.isLoading = true;
      const promise = this.onload(
        { pageNum, pageSize, row: this.dataList.reverse()[0] },
        { ...params, ...p },
        this.list
      );
      if (promise) {
        promise.then((res) => {
          this.isLoading = false;
          if (!res || res.list === undefined) {
            return;
          }
          const total = Number(res.total || 0);
          const list = res.list;
          // console.log(total)
          this.page.pageNum = pageNum;
          this.page.total = total;
          this.list = list;
          if (list.length == 0 && this.page.pageNum > 1) {
            // this.page.pageNum--;
            this.apiOnload({
              pageNum: pageNum - 1,
              pageSize: this.page.pageSize,
            });
          } else if (this.showCheckbox) {
            this.initSelect();
          }
        });
      } else {
        this.isLoading = false;
      }
    },
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    //   this.$emit("setCheck", this.multipleSelection);
    // },
    sortChange(args) {
      this.$emit("sort-change", args);
    },
    rowClick(row, column, cell, event) {
      this.$emit("row-click", row, column, cell, event);
    },
    filterChange(args) {
      this.$emit("filter-change", args);
    },
    handleRowClick(c, s) {
      if (c.handle && typeof c.handle == "function") {
        c.handle(s);
      }
    },
    handleView(s) {
      this.rowView(s);
    },
    handleEdit(s) {
      const promise = this.rowEdit(s);
      if (promise) {
        promise
          .then((msg) => {
            this.apiOnload();
            if (msg) {
              this.$message.success(msg);
            }
          })
          .catch((msg) => this.$message.error(msg));
      }
    },
    async handleDelete(s) {
      try {
        await this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } catch (err) {
        return;
      }
      const promise = this.rowDelete(s);
      if (promise) {
        promise
          .then((msg) => {
            this.$message({
              type: "success",
              message: msg,
            });
            this.apiOnload();
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
          });
      }
    },
    //
    handleSelectAll(s) {
      this.selectionData.indeterminate = false; // 取消半选状态
      const b = this.selectionData.check; // 判断是全选还是全取消
      const status = { $check: b, $indeterminate: false };
      const nodes = this.getTreeNodes(this.list);
      this.resetStatus(nodes, status);
      this.emitChange(s);
    },
    handleSelect(scope) {
      const node = scope.row;
      // console.log(scope)
      this.selectOneNode(node);
      // 重置顶部checkbox 状态
      this.utilResetHeaderSelection();
      // 触发事件
      this.emitChange(scope);
      //
    },
    // 应用
    handleApply(s) {
      if (!this.rowApply) {
        return;
      }
      const p = this.rowApply(s);
      if (!p) {
        return;
      }
      p.then((msg) => {
        this.apiOnload();
        if (msg) {
          this.$message.success(msg);
        }
      }).catch((msg) => this.$message.error(msg));
    },
    /* util */
    utilFilter(list) {
      // const fs = this.filter;
      // const dict = this.dict;
      // const ks = Object.keys(dict)
      // if (fs.length > 0 || ks.length > 0) {
      //   list.forEach((li, i) => {
      //     let t = li;
      //     fs.forEach(f => {
      //       t = f.call(this, li, i);
      //     });
      //     ks.forEach(k => {
      //       const vk = t[k]
      //       const d = dict[k]
      //       for(let m = 0,len = d.length;m < len;m++){
      //         const di = d[m]
      //         if(di.value == vk){
      //           t[k] = di.label;
      //           break;
      //         }
      //       }
      //     })
      //     list[i] = t
      //   });
      // }
      // return list;
    },
    utilDict(prop, { row }, dicts) {
      const vk = row[prop];
      if(vk == undefined){
        return {label:""}
      }
      for (let i = 0, len = dicts.length; i < len; i++) {
        const di = dicts[i];
        if (di.value == vk) {
          return di;
        }
      }
      return { label: vk };
    },
    utilFormat(val, col, row) {
      if (!col.valueType || val === undefined || val === "" || val === null) {
        return val;
      }
      if (col.valueType === "date" && (val === 0 || val === null)) {
        return "--";
      }
      const t = col.valueType;
      const v = col.valueTypeValue || undefined;
      let d = undefined;
      switch (t) {
        case "date":
          try {
            d = new Date(val);
          } catch (err) {
            val = val.replace(/-/g, "/");
            val = val.replace(/T/g, " ");
            val = val.replace(/\.\d+\+\d+$/g, "");
            d = new Date(val);
          }
          if (d) {
            return dateFormat(d, v);
          }
          break;
        case "time":
          try {
            d = new Date(parseInt(val));
          } catch (err) {
            val = val.replace(/-/g, "/");
            val = val.replace(/T/g, " ");
            val = val.replace(/\.\d+\+\d+$/g, "");
            d = new Date(val);
          }
          if (d) {
            return dateFormat(d, v);
          }
          break;
        default:
          return tempReplace(t, { ...col, ...row });
      }
      return val;
    },
    /* api */
    apiSetPageNum(num = 1) {
      this.page.pageNum = num;
    },
    apiOnload(page = this.page, query = {}) {
      this.onLoad(page, query);
    },
    // 获取所有已选节点数据
    apiGetAllSelect() {
      return this.getAllCheckedNodes();
    },
    // 清除已选
    apiClearSelection(status = {}) {
      this.clearAllSelect(status);
    },
    /*  */
    // chang 事件触发
    emitChange(cn) {
      const {
        all,
        leaf,
        parent,
        indeterminate,
        leafCheckIds,
        checkIds,
        indeterIds,
      } = this.getAllCheckedNodes();
      cn && this.$emit("change-node", cn);
      this.$emit("change", all, this, cn);
      this.$emit("change-leaf", leaf, this, cn);
      this.$emit("change-leaf-id", leafCheckIds, this, cn);
      this.$emit("change-select-id", checkIds, this, cn);
      this.$emit("change-parent", parent, this, cn);
      this.$emit("change-indeterminate", indeterminate, this, cn);
      this.$emit("change-indeterminate-id", indeterIds, this, cn);
    },
    // 选中一个节点
    selectOneNode(node) {
      const c = node.$check;
      const tree = this.list;
      const childs = [node, ...this.getChildsNodes(node)];
      const parent = this.getParentNodes(tree, node);
      // console.log('-----',parent,childs,node,this.selectionData)
      if (childs.length > 0) {
        const status = { $check: node.$check, $indeterminate: false };
        this.resetStatus(childs, status);
      }
      if (parent.length > 0) {
        this.resetParentStatus(parent);
      }
    },
    // 默认选择
    defaultSelection(ids = []) {
      const nodes = this.listNodes;
      const key = this.rowKey;
      const ns = [];
      nodes.forEach((n) => {
        if (n && n[key] && ids.indexOf(n[key]) !== -1) {
          ns.push(n);
        }
      });
      if (ns.length > 0) {
        const s = { $check: true, $indeterminate: false };
        this.resetStatus(ns, s);
      }
    },
    getTreeNodes(tree = []) {
      let r = [];
      let s = [...tree];
      while (s.length > 0) {
        const n = s.pop();
        r.push(n);
        if (n.children && n.children.length > 0) {
          for (let i = n.children.length - 1; i >= 0; i--) {
            n.children[i].$parent = n;
            s.push(n.children[i]);
          }
        }
      }
      return r;
    },
    // 重置父节点的状态
    resetParentStatus(parents) {
      for (let i = parents.length - 1; i >= 0; i--) {
        const p = parents[i];
        const cs = p.children;
        let sn = "";
        let sck = true;
        for (let j = 0, l = cs.length; j < l; j++) {
          const c = cs[j];
          const ci = c.$indeterminate;
          const ck = c.$check;
          sck = sck && ck;
          if (ck) {
            sn = "$indeterminate";
          }
          if (ci) {
            sn = "$indeterminate";
            break;
          }
        }
        // 全选
        if (sck) {
          sn = "$check";
        }
        //
        let s = { $check: false, $indeterminate: false };
        s[sn] = true;
        this.resetStatus([p], s);
      }
    },
    getParentNodes(tree = [], node = {}) {
      // 利用数组实现树的深度优先遍历
      let l = [];
      let p = [];
      let s = [...tree];
      while (s.length !== 0) {
        let n = s.pop();
        if (n == node) {
          break;
        }
        if (n.children && n.children.length > 0) {
          p.push(n);
          l.push(n.length);
          for (let i = n.children.length - 1; i >= 0; i--) {
            s.push(n.children[i]);
          }
        } else if (l.length > 0 && --l[l.length - 1] == 0) {
          p.pop();
          l.pop();
        }
      }
      let r = [];
      let t = node;
      for (let i = p.length - 1; i >= 0; i--) {
        const f = p[i];
        if (f.children.indexOf(t) !== -1) {
          r.push(f);
          t = f;
        }
      }
      return r.reverse();
    },
    getChildsNodes(node = {}) {
      let c = [];
      let s = [node];
      while (s.length > 0) {
        const n = s.pop();
        if (n !== node) {
          c.push(n);
        }
        if (n.children && n.children.length > 0) {
          for (let i = n.children.length - 1; i >= 0; i--) {
            s.push(n.children[i]);
          }
        }
      }
      return c;
    },
    getKeys(nodes = []) {
      const k = this.rowKey;
      if (!k || nodes.length == 0) {
        return [];
      }
      let r = [];
      for (let i = 0, l = nodes.length; i < l; i++) {
        const v = nodes[i][k];
        r.push(v);
      }
      return r;
    },
    // 获取 所有已选的节点
    getAllCheckedNodes() {
      const nodes = this.listNodes;
      let all = [];
      let leaf = [];
      let parent = [];
      let indeterminate = [];
      nodes.forEach((n) => {
        if (n.$check) {
          all.push(n);
          if (n.children) {
            parent.push(n);
          } else {
            leaf.push(n);
          }
        }
        if (n.$indeterminate) {
          indeterminate.push(n);
        }
      });
      const { checkIds, indeterIds, leafCheckIds } = this.selectionData;
      return {
        all,
        leaf,
        parent,
        indeterminate,
        checkIds,
        indeterIds,
        leafCheckIds,
      };
    },
    getCheckedLeafNodes() {
      const ns = this.getAllCheckedNodes();
      return ns.leaf;
    },
    resetStatus(nodes = [], status = {}) {
      const ik = this.idKey;
      const ks = Object.keys(status);
      const isLeaf = this.isLeaf;
      nodes.forEach((n) => {
        const id = get(n, ik);
        const leaf = isLeaf(n);
        ks.forEach((k) => {
          const v = status[k];
          // n[k] = status[k]
          this.$set(n, k, v);
          if (k == "$check") {
            v
              ? this.selectionData.checkIds.add(id)
              : this.selectionData.checkIds.delete(id);
            leaf
              ? v
                ? this.selectionData.leafCheckIds.add(id)
                : this.selectionData.leafCheckIds.delete(id)
              : "";
          }
          if (k == "$indeterminate") {
            v
              ? this.selectionData.indeterIds.add(id)
              : this.selectionData.indeterIds.delete(id);
          }
        });
      });
    },
    // 清空已选
    clearAllSelect(status = {}) {
      const nodes = this.listNodes;
      // console.log(".........", nodes);
      this.selectionData.check = false;
      this.selectionData.indeterminate = false;
      this.selectionData.checkIds.clear();
      this.selectionData.indeterIds.clear();
      this.selectionData.leafCheckIds.clear();
      for (const n of nodes) {
        this.$set(n, "$check", false);
        this.$set(n, "$indeterminate", false);
      }
    },
    utilResetHeaderSelection() {
      const nodes = [...this.list];
      let ck = true;
      let ind = false;
      nodes.forEach((n) => {
        ck = ck && n.$check;
        ind = ind || n.$check || n.$indeterminate;
      });
      ind = ind && !ck;

      this.$set(this.selectionData, "check", ck);
      this.$set(this.selectionData, "indeterminate", ind);
    },
    initSelect() {
      const ik = this.idKey;
      const nodes = this.listNodes;
      const cks = this.selectionData.checkIds;
      const ids = this.selectionData.indeterIds;
      const isLeaf = this.isLeaf;
      let ac = nodes.length > 0;
      let ih = false;
      for (const n of nodes) {
        const id = get(n, ik);
        const pid = n.$parent ? get(n.$parent, ik) : "";
        const isleaf = isLeaf(n);
        //  console.log("========>>>", n, id, pid);
        if (cks.has(id) || cks.has(pid)) {
          this.$set(n, "$check", true);
          ih = true;
          if (!cks.has(id)) {
            this.selectionData.checkIds.add(id);
            isleaf && this.selectionData.leafCheckIds.add(id);
          }
        } else {
          this.$set(n, "$check", false);
          ac = false;
        }
        if (ids.has(id)) {
          this.$set(n, "$indeterminate", true);
          ih = true;
          ac = false;
        } else {
          this.$set(n, "$indeterminate", false);
          ac = false;
        }
      }
      //
      if (ac) {
        ih = false;
      }
      this.selectionData.check = ac;
      this.selectionData.indeterminate = ih;
      this.emitChange();
    },
  },
  watch: {
    data() {
      if (this.data && !this.onload) {
        this.list = [...this.data];
        this.page.total = this.data.length;
      }
    },
    listNodes(v) {
      // console.log('++++++',v?.length)
      this.initSelect();
    },
    loading(v) {
      this.isLoading = v;
    },
  },
  created() {
    this.rowKey = this.tableOption.rowKey || this.tableOption["row-key"] || "";
    this.apiOnload();
    this.$nextTick(() => {
      const bh = this.$refs.boxRef ? this.$refs.boxRef.offsetHeight : 0;
      const fh = this.$refs.footerRef ? this.$refs.footerRef.offsetHeight : 0;
      this.tableHeight =
        bh > 0 ? bh - fh : this.showPagination ? "calc(100% - 56px)" : "100%";
    });
  },
  mounted() {
    if (this.data && !this.onload) {
      this.list = [...this.data];
      this.page.total = this.data.length;
    }
    // console.log(this.$refs)
    const params = { _that: this, elTableRef: this.$refs.elTableRef };
    if (this.init) {
      this.init(params);
    }
    //
    this.$emit("init", params);
  },
};
</script>

<style scoped lang="scss">
.self-table {
  position: relative;
  width: 100%;
  height: 100%;
  ::v-deep .el-button.is-plain {
    &:hover {
      background: #eef8ff;
    }
    &:focus {
      background: #eef8ff;
    }
  }
  ::v-deep .el-table {
    border: 1px solid #ebeef5;
    border-bottom: none;
    .el-table__header {
      background: #fafafa;
    }
    th {
      background: #fafafa;
      &.gutter {
        display: table-cell !important;
      }
      &.is-center {
        .cell {
          &,
          * {
            text-align: center;
          }
        }
      }
    }
    td {
      &.is-center {
        .cell {
          &,
          * {
            text-align: center;
          }
        }
      }
      .t--dict {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .el-table__fixed,.el-table__fixed-right{
      .el-table__fixed-body-wrapper{
        top:50px !important;
      }
    }
    // .el-table__fixed {
    //   // height:auto !important;
    //   // bottom:0px;
    //   // bottom:10px;
    //   // border:none;
    // }
    .hover-row {
      > td {
        background-color: #eef8ff !important;
      }
    }
  }
  .c--loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(#fff, #00000018);
    z-index: 9999;
  }
  .c--pagination {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    ::v-deep .el-pagination {
      display: flex;
      align-items: center;
      .el-pager {
        li {
          &:not(.active) {
            background: transparent;
          }
          // background:transparent;
        }
      }
    }
  }
  //
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
    z-index: 9999;
  }
  ::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background: #00000020;
    border-radius: 5px;
    z-index: 9999;
    &:hover {
      background: #00000040;
    }
  }
}
.self-table--menu-btn {
  border: none;
  background: transparent;
  // &:hover {
  // background: transparent;
  // color: #00ccff;
  // }
  // &:focus{
  // background: transparent;
  // }
  ::v-deep .el-icon-delete {
    color: #f54622;
  }
}
.model--text {
  padding: 0;
}
</style>
