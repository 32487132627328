<template>
  <div class="els-monaco-editor">
    <div class="e--header" v-if="showHeader">
      <span>{{ title }}</span>
      <div class="h--toolbox" v-if="!readOnly">
        <!-- 语言类型选项 -->
        <el-select v-model="language" :disabled="langsDisabled" size="mini">
          <el-option
            v-for="item in langTypes"
            :key="item.value"
            :label="item.label || item.value"
            :value="item.value"
          ></el-option>
        </el-select>
        <!-- 分割线 -->
        <el-divider direction="vertical"></el-divider>
        <!-- 按钮组 -->
        <els-button-group v-bind="editorTools"></els-button-group>
      </div>
    </div>
    <div class="e--container">
      <div ref="monacoRef"></div>
    </div>
  </div>
</template>

<script>
//
import * as monaco from "monaco-editor/esm/vs/editor/editor.main.js";

//
export default {
  name: "els-monaco-editor",
  props: {
    options: { type: Object, default: () => ({}) },
    title: String,
    value: { type: [String, Object], default: "" },
    lang: { type: String, default: "javascript" },
    langs: { type: Array },
    langsDisabled: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    theme: { type: String, default: "vs-dark" },
    showHeader: { type: Boolean, default: true },
  },
  data: () => ({
    editorValue: "",
    language: "",
    editor: undefined,
    flag:false,
  }),
  computed: {
    editorOptions() {
      const ops = this.options
      const readOnly = this.readOnly || this.disabled;
      const theme = this.theme;
      return { language:this.language,readOnly, theme,minimap:{enabled:false},automaticLayout:true,autoIndent:true,autoSurround:true,...ops };
    },
    editorTools() {
      const attrs = {
        size: "mini",
      };
      const refresh = {
        text: "刷新",
        icon: "el-icon-refresh",
        handle: this.handleSubmit,
      };
      const column = [refresh];

      return { attrs, column };
    },
    langTypes() {
      const langs = this.langs;
      const d = monaco.languages
        .getLanguages()
        .map((item) => ({ value: item.id }));
      return langs ? d.filter((item) => langs.inclueds(item.value)) : d;
    },
  },
  methods: {
    init() {
      this.language = this.lang;
      // this.utilParseValue();
    },
    handleChange(e) {
      const v = this.editor.getValue();
      this.emitValue(v);
    },
    handleSubmit() {
      const v = this.editorValue;
      const d = this.langsDisabled ? v : { lang: this.language, code: v };
      this.$emit("submit", d);
    },
    emitValue(v) {
      this.editorValue = v;
      const d = this.langsDisabled ? v : { lang: this.language, code: v };
      // console.log(d)
      this.$emit("input", d);
    },
    apiSetValue(v) {
      if(this.editorValue === v) return;
      this.editorValue = v || '';
      // console.log('--------------------------------',1,v)
      if(this.editor){
        // console.log('--------------------------------',2,v,this.editor.getAction(['editor.action.formatDocument']))
        this.editor.setValue(this.editorValue);
        // this.editor.getAction(['editor.action.formatDocument'])._run();
        setTimeout(this.editor.trigger('anyString','editor.action.formatDocument'),1000)
        // this.editor.trigger('anyString','editor.action.formatDocument')
      }
      
    },
    apiResetLanguage(v) {
      if (this.editor && v) {
        const model = this.editor.getModel();
        monaco.editor.setModelLanguage(model, v);
      }
    },
    apiUpdateOptions(){
      const language = this.language;
      const ops = this.editorOptions;
      const options = {...ops,language}
      this.editor.updateOptions(options)
    },
    utilParseValue() {
      const v = this.value;
      const t = typeof v;
      switch (t) {
        case "string":
          this.apiSetValue(v);
          break;
        case "object":
          this.apiSetValue(v.code);
          this.language = v.lang || this.lang;
          break;
      }
    },
  },
  watch: {
    value() {
      if(this.flag){
        this.utilParseValue();
      }
    },
    language(v) {
      this.apiResetLanguage(v);
    },
    disabled(){
      this.apiUpdateOptions();
    }
  },
  created() {
    this.init();
  },
  mounted() {
    
    this.editor = monaco.editor.create(this.$refs.monacoRef, this.editorOptions);
    this.editor.onDidChangeModelContent((e) => {
      this.$nextTick(() => {
        this.handleChange(e);
      });
    });
    this.editor.onDidChangeModelLanguage((e) => {
      console.log(e);
    });
    // setTimeout(() => {this.utilParseValue()},1000)
    this.utilParseValue();
    // console.log('......',options,this.editor,this.editor.getValue())
    this.flag = true;
    //
    if(this.editor){
      setTimeout(this.editor.trigger('anyString','editor.action.formatDocument'),1000)
    }
    // console.log('..............',this.editor.editorOptions,this.editor)
  },
};
</script>

<style lang="scss" scoped>
.els-monaco-editor {
  width: 100%;
  height: 100%;
  // border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  .e--header {
    height: 48px;
    color: #f0f2f5;
    background: #3c3d3f;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    ::v-deep .h--toolbox {
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100px;
      }
      .el-button,
      .el-input,
      .el-input__inner {
        border-color: transparent;
        color: #f0f2f5;
        background: #3c3d3f;
        .el-select__caret {
          color: transparent;
        }
        &:hover {
          border-color: #f0f2f5;
          .el-select__caret {
            color: #f0f2f5;
          }
        }
      }
    }
  }
  .e--container {
    position:relative;
    width: 100%;
    height: calc(100%);
    min-width: 100px;
    min-height: 100px;
    >div{
      height:100%;
      width:100%;
    }
  }
}
</style>
