
export const names = {
"org.thingsboard.rule.engine.transaction.TbSynchronizationBeginNode":"synchronization start",
"org.thingsboard.rule.engine.updown.DataReciveNode":"接收数据记录",
"org.thingsboard.rule.engine.telemetry.TbMsgAttributesNode":"save attributes",
"org.thingsboard.rule.engine.action.TbCreateRelationNode":"create relation",
"org.thingsboard.rule.engine.debug.TbMsgGeneratorNode":"generator",
"org.thingsboard.rule.engine.delay.TbMsgDelayNode":"delay",
"org.thingsboard.rule.engine.action.TbCreateAlarmNode":"create alarm",
"org.thingsboard.rule.engine.protocol.Xm2mParserNode":"xm2m协议解析",
"org.thingsboard.rule.engine.action.TbLogNode":"log",
"org.thingsboard.rule.engine.action.TbUnassignFromCustomerNode":"unassign from customer",
"org.thingsboard.rule.engine.action.TbCopyAttributesToEntityViewNode":"copy to view",
"org.thingsboard.rule.engine.action.TbClearAlarmNode":"clear alarm",
"org.thingsboard.rule.engine.action.TbSaveToCustomCassandraTableNode":"save to custom table",
"org.thingsboard.rule.engine.flow.TbCheckpointNode":"checkpoint",
"org.thingsboard.rule.engine.updown.LogDeviceUpDownNode":"记录设备上下线",
"org.thingsboard.rule.engine.geo.TbGpsGeofencingActionNode":"gps geofencing events",
"org.thingsboard.rule.engine.offline.OfflineRequestsSendToDeviceNode":"离线下发",
"org.thingsboard.rule.engine.rpc.TbSendRPCRequestNode":"rpc call request",
"org.thingsboard.rule.engine.rpc.TbSendRPCReplyNode":"rpc call reply",
"org.thingsboard.rule.engine.action.TbMsgCountNode":"message count",
"org.thingsboard.rule.engine.flow.TbAckNode":"acknowledge",
"org.thingsboard.rule.engine.transaction.TbSynchronizationEndNode":"synchronization end",
"org.thingsboard.rule.engine.telemetry.TbMsgTimeseriesNode":"save timeseries",
"org.thingsboard.rule.engine.action.TbAssignToCustomerNode":"assign to customer",
"org.thingsboard.rule.engine.action.TbDeleteRelationNode":"delete relation",
"org.thingsboard.rule.engine.filter.TbJsFilterNode":"script",
"org.thingsboard.rule.engine.geo.TbGpsGeofencingFilterNode":"gps geofencing filter",
"org.thingsboard.rule.engine.filter.TbCheckAlarmStatusNode":"check alarm status",
"org.thingsboard.rule.engine.filter.TbMsgTypeSwitchNode":"message type switch",
"org.thingsboard.rule.engine.filter.TbMsgTypeFilterNode":"message type",
"org.thingsboard.rule.engine.filter.TbJsSwitchNode":"switch",
"org.thingsboard.rule.engine.filter.TbCheckMessageNode":"check existence fields",
"org.thingsboard.rule.engine.filter.TbOriginatorTypeFilterNode":"originator type",
"org.thingsboard.rule.engine.filter.TbOriginatorTypeSwitchNode":"originator type switch",
"org.thingsboard.rule.engine.filter.TbCheckRelationNode":"check relation",
"org.thingsboard.rule.engine.metadata.TbGetTenantDetailsNode":"tenant details",
"org.thingsboard.rule.engine.metadata.TbGetOriginatorFieldsNode":"originator fields",
"org.thingsboard.rule.engine.metadata.TbGetRelatedAttributeNode":"related attributes",
"org.thingsboard.rule.engine.metadata.TbGetAttributesNode":"originator attributes",
"org.thingsboard.rule.engine.metadata.TbGetCustomerAttributeNode":"customer attributes",
"org.thingsboard.rule.engine.metadata.TbGetTenantAttributeNode":"tenant attributes",
"org.thingsboard.rule.engine.metadata.TbGetTelemetryNode":"originator telemetry",
"org.thingsboard.rule.engine.metadata.TbGetDeviceAttrNode":"related device attributes",
"org.thingsboard.rule.engine.metadata.TbGetCustomerDetailsNode":"customer details",
"org.thingsboard.rule.engine.rabbitmq.TbRabbitMqNode":"rabbitmq",
"org.thingsboard.rule.engine.gcp.pubsub.TbPubSubNode":"gcp pubsub",
"org.thingsboard.rule.engine.mail.TbSendEmailNode":"send email",
"org.thingsboard.rule.engine.kafka.TbKafkaNode":"kafka",
"org.thingsboard.rule.engine.aws.sqs.TbSqsNode":"aws sqs",
"org.thingsboard.rule.engine.tcp.TbShuMengNode":"数梦数据推送",
"org.thingsboard.rule.engine.mqtt.TbMqttNode":"mqtt",
"org.thingsboard.rule.engine.rest.TbRestApiCallNode":"rest api call",
"org.thingsboard.rule.engine.mqtt.azure.TbAzureIotHubNode":"azure iot hub",
"org.thingsboard.rule.engine.aws.sns.TbSnsNode":"aws sns",
"org.thingsboard.rule.engine.mail.TbMsgToEmailNode":"to email",
"org.thingsboard.rule.engine.transform.TbChangeOriginatorNode":"change originator",
"org.thingsboard.rule.engine.transform.TbTransformLongNode":"long",
"org.thingsboard.rule.engine.transform.TbTransformMsgNode":"script",
"org.thingsboard.rule.engine.protocol.LoraParserNode":"XG-HY-LCT-B3协议解析"
}
