<template>
  <div class="els-form">
    <el-form ref="elFormRef" v-bind="elFormOptions" v-if="columnList.length > 0">
      <template v-for="(item, ins) in columnList">
        <els-elem
          :key="ins + '-' + itemKey(item)"
          :context="context"
          :elem="item"
          :defaultTag="defaultTag"
        ></els-elem>
      </template>
    </el-form>
  </div>
</template>

<script>
/*
 * 重点功能
 * * 1.数据联动 - 监听数据变化 - 如何监听值的变化 如何知道哪个值变化了？1：遍历，只到达表单元素层~。2，直接调用方法更新数据，有缺陷，会导致重复更新，
 * * 2.数据反馈 - 可以定向重置元素属性 最简单的做法：监听 change-attr => 改变 column 最具性能的做法：api 单独设定元素变化，此时 column 无变化，如果遇到数据刷新，会出现 bug 需要解决这个问题。
 * * 3.表单校验 - 支持元素自动校验 [blur | change] - 整体校验 -
 * * 4.
 */

import {
  isFormElem,
  clone,
  options,
  hooks,
  combine,
  validate,
  getValueFormObject,
  buildRules,
} from "./util.js";

import {has,get,set,unset,cloneDeep,difference} from "lodash"

export default {
  name: "els-form",
  props: {
    elForm: Object,
    column: Array,
    value: Object,
    attrs: Object,
    rules: Object,
    model: Object, // 兼容
    ctx:Object,
    itemKey:{type:Function,default:(d) => d.key || d.prop || ""},
    isInitValue: { type: Boolean, default: true }, // 在设置 值为 undefined 时，是否初始化为默认值
  },
  data: () => ({
    defaultTag: "el-input",
    elFormApp: undefined,
    formData: {},
    elems: [], // 被判定为表单元素
    // fields: [], // 需要执行验证的元素
    formElems: {},
    formProps:[],
    timeHandle: undefined,
    changeFlag: false,
  }),
  computed: {
    elFormOptions() {
      const o = this.elForm || {};
      const d = {
        labelWidth: "auto",
        labelPosition: "right",
        validateOnRuleChange: false,
      };
      return { ...combine(d, o) };
    },
    columnList() {
      const list = clone(this.column);
      return list;
    },
    context() {
      const created = function() {
        const tag = this.elem.tag || this.defaultTag;
        const pTag =
          this.context.parent &&
          (this.context.parent.tag || this.context.parent.elem.tag);
        if (this.context.source.isFormElem(tag, pTag)) {
          // 是否 mounted 标志位
          this.mountedFlag = false;
          // 设置标志位 为后续判断提供简单依据 例如 context hook
          this.isFormElem = true;
          // 重置 value 的函数
          this.resetValue = (v) => {
            const val = this.value;
            // const t = this.context.source.isInitValue;
            // const b = v == undefined && t && val != undefined;
            // v = b ? val : v;
            // this.$("value", v);
            if(v == undefined && val != undefined){
              this.handleInput(val)
            }else{
              this.$("value", v);
            }
          };
          // 初始化 handle ---
          this.handleInput = (v,p) => {
            // 再未进入 mounted 生命周期时，不允许将值置为 undefined
            if (!this.mountedFlag && v == undefined) return;
            // 过滤值
            v = (this.elem.valueFilter && typeof this.elem.valueFilter == 'function') ? this.elem.valueFilter(v) : v;
            this.$("value", v);
            this.$_utilHook("input", v);
            let prop = this.prop;
            if(p && typeof p === 'string'){
              prop = prop + '.' + p;
            }
            this.context.source.handleInput(this.$("value"), prop);
          };
          this.handleBlur = () => {
            setTimeout(() => {
              this.$_utilHook("blur", this);
              this.context.source.handleBlur(this.$("value"), this.prop, this);
            }, 500);
          };
          this.handleChange = () => {
            this.$_utilHook("change", this);
            this.context.source.handleChange(this.$("value"), this.prop, this);
          };
          // 初始化 props
          const parents = this.context.parents || [];
          const props = parents.filter((e) => e.elem.prop).map((e) => e.elem);
          this.elem.prop && props.push(this.elem);
          this.props = props;
          this.prop =
            props.length > 0 ? props.map((item) => item.prop).join(".") : "";
          // 初始化 rules
          this.rules = this.context.source.buildRules(this);
          // 初始化 value   --- --- ---
          if(this.elem.value != undefined){
            this.value = this.elem.value;
          }
          const val = this.context.source.getValueByProp(this.prop);
          this.resetValue(val)
          
        }
      };
      const context = function(ctx) {
        if (this.isFormElem) {
          const d = ctx.on || {}
          ctx.props.value = this.$("value");
          ctx.on = {
            ...d,
            input: this.handleInput,
            blur: this.handleBlur,
            change: this.handleChange,
          };
        }
      };
      const beforeContext = function(attrs) {
        if (this.isFormElem) {
          const o = this.context.attrs;
          if (!o) return;
          Object.keys(o).forEach((k) => {
            if (!Object.prototype.hasOwnProperty.call(attrs, k)) {
              attrs[k] = o[k];
            }
          });
        }
      };
      const mounted = function() {
        if (this.isFormElem) {
          this.mountedFlag = true;
          // 保持当前元素实例
          this.context.source.addElems(this);
        }
        //
        if (this.hasElFormItem) {
          this.elFormItem = this.$children[0];
          if (!this.elFormItem) return;
          // 覆盖 el-form-item 内部的 validate 方法
          this.elFormItem.validate = (...args) => {
            this.elFormItem.value = this.$("value");
            validate.call(this.elFormItem, this.context.source, ...args);
          };
        }
      };
      const updated = function(){
        //
        if (this.hasElFormItem && this.elFormItem != this.$children[0]) {
          this.elFormItem = this.$children[0];
          if (!this.elFormItem) return;
          // 覆盖 el-form-item 内部的 validate 方法
          this.elFormItem.validate = (...args) => {
            this.elFormItem.value = this.$("value");
            validate.call(this.elFormItem, this.context.source, ...args);
          };
        }
      }
      const beforeDestroy = function(){
        if(this.isFormElem && this.prop){
          this.context.source.removeElems(this.prop);
        }
      }
      const hookss = {
        created: [hooks.created, created],
        render: hooks.render,
        context,
        beforeContext,
        mounted,
        updated,
        beforeDestroy
      };
      //
      return {
        ...(this.ctx || {}),
        source: this,
        hooks: hookss,
        options: { ...options },
        attrs: this.attrs,
        $scopedSlots: this.$scopedSlots,
      };
    },
    fields(){
      if(this.$refs.elFormRef){
        return this.$refs.elFormRef.fields;
      }
      return []
    }
  },
  methods: {
    /* init */
    /* handle */
    // input 事件，传出表单元素值
    handleInput(v, prop) {
      this.setValueByProp(prop, v);
      //
      this.changeFlag = true;
      //
      this.emit(prop, v);
      //
    },
    // blur 事件，主要用于校验表单
    handleBlur(v, props, e) {},
    // change 事件 主要用于表单校验
    handleChange(v, props, e) {},
    /* utils */
    isFormElem(tag, pTag) {
      return isFormElem(tag, pTag);
    },
    // 添加元素
    addElems(e) {
      if (this.elems.includes(e)) return;
      this.elems.push(e);
      // this.addElemRule(e, true);
      const prop = e.prop;
      // console.log('----------',prop,new Date().getTime())
      this.formElems[prop] = e;
    },
    //
    removeElems(prop){
      if(has(this.formElems,prop)){
        delete this.formElems[prop];
      }
    },
    //
    setValueByProp(prop, value) {
      if(!prop || typeof prop !== 'string'){
        return;
      }
      const t = cloneDeep(this.formData);
      set(t,prop,value)
      this.formData = t;
      // const ps = prop.split(".");
      // const len = ps.length;
      // if (len == 0) return;
      // // 再初始化的时候追加传入的对象~
      // const nd = clone(this.formData);
      // let data = nd;
      // if (len == 1) {
      //   data[ps[0]] = value;
      // }
      // //
      // for (let i = 0; i < len - 1; i++) {
      //   const p = ps[i];
      //   const pn = ps[i + 1];
      //   const b = i + 1 == len - 1;
      //   const d = /^\d+$/.test(pn) ? [] : {};
      //   if (!data[p]) {
      //     data[p] = d;
      //   }
      //   data = data[p];
      //   if (b) {
      //     data[pn] = value;
      //     continue;
      //   }
      // }
      // //console.log('......set value.........',nd)
      // //
      // this.formData = nd;
    },
    //
    getValueByProp(prop, d = this.formData) {
      if(has(d,prop)){
        return get(d,prop)
      }
      return undefined;
      // const ps = prop.split(".");
      // let data = d;
      // ps.forEach((k) => {
      //   if (data && Object.prototype.hasOwnProperty.call(data, k)) {
      //     data = data[k];
      //   } else {
      //     data = undefined;
      //   }
      // });
      // return data;
    },
    //
    resetFormData(d = {}) {
      this.formData = d;
      this.elems.forEach((e) => {
        const v = this.getValueByProp(e.prop, d);
        if (e.$("value") !== v) {
          // console.log("......reset value.....", v, e.prop);
          e.resetValue(v);
        }
      });

      // console.log('++++++++++++++++++',d)
    },
    // 构建 rules
    buildRules(e) {
      const prop = e.prop;
      const rules = this.rules;
      const elem = e.elem;
      const itemRules = buildRules(elem, true,e.context.lang);
      //
      if (!prop || !rules) {
        return itemRules;
      }
      //
      const props = prop.split(".");
      if (!rules[prop] && !rules[props[0]]) return itemRules;
      //
      const res = itemRules ? [...itemRules] : [];
      //
      if (rules[prop]) {
        res.push(...rules[prop]);
      } else {
        let r = rules;
        for (let i = 0, len = props.length; i < len; i++) {
          const p = props[i];
          r = r[p];
          if (!r) {
            break;
          }
        }
        if (Array.isArray(r)) {
          res.push(...r);
        }
      }
      //
      return res;
    },
    /* emit */
    emit(prop, value) {
      clearTimeout(this.timeHandle);
      this.timeHandle = setTimeout(() => {
        this.emitInput();
        this.emitChange();
        this.$nextTick(() => {
          this.emitOnChange(prop, value);
          this.emitChangeAttr(prop, value);
        });
      }, 10);
    },
    emitInput() {
      this.$emit("input", this.formData);
      this.$emit("submit", this.formData);
    },
    emitChange() {
      this.$emit("change", this.formData);
    },
    emitOnChange(prop, value) {
      this.$emit("on-change", prop, value);
    },
    emitChangeAttr(prop, value) {
      this.$emit(`change-${prop}`, value, prop);
    },
    /* api */
    apiGetData() {
      return this.formData;
    },
    // 表单验证
    validate(callback) {
      let promise;
      let valid = true;
      if (typeof callback != "function" && window.Promise) {
        promise = new Promise((resolve, reject) => {
          callback = (valid) => {
            valid ? resolve(valid) : reject(valid);
          };
        });
      }
      let invalidFields = {};
      const ct = this.fields.length;
      this.fields.forEach((field, ins) => {
        if (field.validate) {
          field.validate("", (m, f) => {
            if (m) {
              valid = false;
            }
            f = f ? f : {};
            invalidFields = combine(invalidFields, f);
          });
          if (ins == ct - 1) {
            callback(valid, invalidFields);
          }
        }
      });

      //
      if (promise) {
        return promise;
      }
    },
    // 部分校验
    validateField(props, cb) {
      props = [].concat(props);
      var fields = this.fields.filter((field) => {
        return props.indexOf(field.prop) !== -1;
      });
      if (!fields.length) {
        console.warn("[Element Warn]please pass correct props!");
        return;
      }

      fields.forEach((field) => {
        field.validate && field.validate("", cb);
      });
    },
    // 清空校验结果
    clearValidate() {
      var props =
        arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var fields = props.length
        ? typeof props === "string"
          ? this.fields.filter(function(field) {
              return props === field.prop;
            })
          : this.fields.filter(function(field) {
              return props.indexOf(field.prop) > -1;
            })
        : this.fields;
      fields.forEach(function(field) {
        field.clearValidate && field.clearValidate();
      });
    },
  },
  watch: {
    value: {
      deep: true,
      handler(d) {
        if (!this.changeFlag) {
          this.resetFormData(d);
        }
        this.changeFlag = false;
      },
    },
    model: {
      deep: true,
      handler(d) {
        if (!this.changeFlag) {
          this.resetFormData(d);
        }
        this.changeFlag = false;
      },
    },
    // column:{
    //   deep:true,
    //   handler(){
    //     this.$nextTick(() => {
    //       const props = Object.keys(this.formElems);
    //       if(props.length == 0) {return ;}
    //       console.log(props,this.formData)
    //       for(let k in this.formData){
            
    //         if(!props.includes(k)){
    //           // delete this.formData[k]
    //         }
    //       }
    //       // this.emitInput();
    //     })
    //   }
    // }
  },
  created() {
    if (this.value) {
      this.formData = this.value;
    }
  },
  updated() {
    const cps = Object.keys(this.formElems);
    const props = this.formProps;
    
    if(props.length === 0){
      this.formProps = cps;
      return ;
    }
    //
    const delProps = difference(props,cps)
    // console.log(cps,props,delProps)
    this.formProps = cps
    if(delProps.length == 0){return ;}
    delProps.forEach(prop => {
      if(has(this.formData,prop)){
        unset(this.formData,prop)
      }
    })
    this.emitInput();
    
  },
  mounted() {
    // console.log('---------mounted--------')
    // 传出实例 便于使用，这样就不需要用 $refs 来使用了
    this.$emit("instance", this);
    // 获取 fields 实例
    this.elFormApp = this.$refs.elFormRef;
    // if (this.elFormApp) {
    //   this.fields = this.elFormApp.fields;
    // }
    //
    setTimeout(() => {
      this.resetFormData(this.value);
    }, 200);
  },
};
</script>
