<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { has, get } from "lodash";

export default {
  created() {
    if (window.__POWERED_BY_WUJIE__) {
      // 免登录方法挂载 这里免登录的内部实现需要依据实际情况去实现
      window.$noLogin = (data) => {
        this.$store.dispatch("noLogin", data);
      };

      // 手动跳转的方法挂载 这里提醒一点不能直接返回 $router.push
      // 这里不使用自动跳转的逻辑，也就是同一个子应用，著应用菜单变化，子应用 url 不变，采用手动跳转的方式
      // 自动跳转我遇到了 bug ,跳转路径总是不变，即使 url 变了！
      window.$jump = (data) => {
        console.log('$jump==========',this.$store.getters.hasRouter,data);
        this.$store.commit("setInitRouteData", undefined);
        if (this.$store.getters.hasRouter) {
          this.$nextTick(() => {
            this.$router.push(data);
          });
        } else {
          this.$store.commit("setInitRouteData", data);
        }
      };
      // 自动注入 token 实现免登录 has/get 方法来自 lodash
      const p = window.$wujie.props;
      if (has(p, "token")) {
        window.$noLogin(get(p, "token"));
        // $store.dispatch("noLogin", get(p, "token"));
      }
      // 挂在实例对象
      window.$vue = this;
      // 这里是把主应用里面的 jump 函数挂载到window 对象上，方便子应用调用。
      // 如果子应用不需要调用，可以去掉
      // has/get 方法来自 lodash
      if (has(p, "jump") && typeof get(p, "jump") === "function") {
        window.$jumpto = (name, data) => get(p, "jump")(name, data);
      }
    }
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>
