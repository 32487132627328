import {iconRect} from "@/components/els-antv-x6/cells"
//
import {nodeIcons} from "./icons"
import {nodeShapes} from "./shapes"
//
import {merge} from "lodash"
//
export const GROUPS = ['FILTER','ENRICHMENT','TRANSFORMATION','ACTION','EXTERNAL']
//
export const DEFAUT_EDGE_EDIT = 'edge-edit'
export const DEFAUT_EDGE_STATIC = 'edge-default'
export const DEFAULT_INPUT_ID = 'input-node'
// 边框颜色，填充颜色|背景色
export const COLORS = {
  FILTER:['#fcaf17','#f1e861'],
  // FILTER:['#fcaf17','#333'],
  ENRICHMENT:['#7fb80e','#cdf14e'],
  TRANSFORMATION:['#009ad6','#79cef1'],
  ACTION:['#f05b72','#f1928f'],
  EXTERNAL:['#f58220','#fbc766'],
}
// 分组节点样式
export const nodeGroupAttrs = Object.fromEntries(GROUPS.map(k => [k,{
  body:{
    stroke:COLORS[k][0],
    fill:COLORS[k][1],
  }
}]))

export const graphOptions = {
  connecting:{
    validateConnection({ edge, sourceCell, sourcePort, targetCell, targetPort }) {
      // 出边校验
      if (sourceCell && sourceCell.isNode()) {
        const sp = sourceCell.getPort(sourcePort);
        const spg = sp ? sp.group : undefined;
        if (spg && !spg.includes("out")) return false;
        // 单出边校验
        if (spg && spg === "outOne") {
          const ses = sourceCell.model
            .getEdges()
            .filter((e) => e.getSourceCell() == sourceCell && e.getSourcePortId() === sourcePort && e != edge);
          if (ses.length > 0) {
            ses.forEach((e) => sourceCell.model.removeCell(e));
          }
        }
      }
      // 入边校验
      if (targetCell && targetCell.isNode()) {
        const tp = targetCell.getPort(targetPort);
        const tpg = tp ? tp.group : undefined;
        if (tpg && !tpg.includes("in")) return false;
        // 已链接校验
        const edges = targetCell.model.getEdges().filter(e => e.getTargetCell() == targetCell)
        // 父节点校验
        // const isConnectCelll = sourceCell.model.getPredecessors(sourceCell).map(e => e.getSourceCell()).includes(targetCell)
        if(this.isPredecessor(sourceCell,targetCell)) return false;
        //
        if(edges.filter(e => e.getSourceCell() == sourceCell).length > 0) {return false;}
        // 单入边校验
        if (tpg && tpg === "inOne") {
          const tes = edges.filter((e) => e.getTargetPortId() === targetPort && e != edge);
          //
          if (tes.length > 0) {
            return false;
          }
        }
      }
      //
      return true;
    },
  }
}
// 单节点样式配置
export const nodeAttrs = {

}

// 自定义元素 节点和边
export const inputNode = iconRect.define({
  x:200,
  y:200,
  width:120,
  attrs:{
    body:{
      fill:'#67C23A',
      stroke:'#67C23A'
    }
  },
  ports:{
    items:[
      {id:'port-out',group:'outOne'}
    ]
  }
})

// 依据 components 生成 节点 列表，表单
export const test = (nodes) => {
  //
  const ruleNodes = [];
  //
  nodes.forEach(node => {
    const {type,name,clazz,actions,configurationDescriptor,id}  = node
    const key = type + '-' + clazz.split('.').pop()
    const shape = (nodeShapes[key] || 'edit') + '-' + key;
    const nodeDefinition = configurationDescriptor.nodeDefinition;
    // 节点
    const cell = createNode(key,name,type,nodeDefinition)
    //
    ruleNodes.push({name:shape,cell});
  })
}


export const createNode = (key,name,group,nodeDefinition) => {
  // const shape = 'edit-' + key;
  const icon = nodeIcons[key]
  const attrs = merge({},nodeGroupAttrs[group],nodeAttrs[key]);
  const {inEnabled,outEnabled} = nodeDefinition
  const items = []
  if(inEnabled){items.push({id:'port-in',group:'in'})}
  if(outEnabled){items.push({id:'port-out',group:'out'})}
  return {inherit:'icon-rect',icon,label:name,attrs,ports:{items}}
}




