import { decn } from "./default";

// 导出默认项
export const d = decn;
// 首页
export const homePage = {
  funcIntro: "功能介绍",
  platformName: "工业物联网平台",
  desc:
    "工业物联网平台是一个健壮、可扩展和用户友好的平台，为设备提供安全可靠的连接通信能力，向下连接海量工业设备，支撑设备数据采集、数据分析；向上提供云端API，服务端通过远程调用将指令下发至设备端，实现远程控制。工业物联网平台也提供了其他增值能力，如网关、设备管理、规则引擎、边缘计算、可视化报表等，为各类IoT场景、行业客户和开发者赋能",
  funData1: {
    icon: "#icon-shebeijieru1",
    title1: "设备接入",
    title2:
      "工业物联网平台支持海量设备连接上云，设备与云端进行稳定可靠地双向通信。",
    content: [
      "提供网关，将连接到传统和第三方系统的设备与H-IoT集成。",
      " 提供2G/ 3G /4G、NB-IoT、LoRaWAN、Wi-Fi等不同网络设备接入方案，解决企业异构网络设备接入管理痛点。",
      " 提供MQTT、CoAP、HTTP/S、OPC-UA等多种协议的设备端SDK，既满足长连接的实时性需求，也满足短连接的低功耗需求。",
    ],
  },
  funData2: {
    icon: "#icon-shebeiguanli1",
    title1: "设备管理",
    title2:
      "工业物联网平台提供完整的设备生命周期管理功能，支持设备注册、功能定义、数据解析、远程配置、固件升级、远程维护、实时监控、分组管理、设备删除等功能。",
    content: [
      "提供设备物模型，基于设备生命周期事件的触发操作。例如，如果设备处于联机/脱机状态，则创建警报。",
      "将遥测或属性从设备复制到相关资源，以便可以聚合遥测。例如，来自多个设备的数据可以聚合到相关资产中。",
      "提供数据存储能力，方便用户海量设备数据的存储及实时访问。",
      " 提供设备多租户管理机制，安全可靠。",
    ],
  },
  funData3: {
    icon: "#icon-shishixiangying",
    title1: "规则引擎",
    title2:
      "工业物联网平台规则引擎是一个易于使用的框架，用于构建基于事件的工作流。",
    content: [
      "操作简单，可通过拖拽操作配置基于事件的工作流。",
      "功能强大，配有大量特殊功能的节点。",
      "支持复制、导入、导出等快捷操作。 ",
    ],
  },
  funData4: {
    icon: "#icon-xuanzesuanfa",
    title1: "边缘计算",
    title2:
      "工业物联网平台边缘计算将云端的能力下沉到边缘侧，解决边缘实时性、可靠性、运维经济性等方面遇到的问题。 对于运维，云端提供一体化的运维工具，可以在云端集中运维，降低运维成本，提升运维效率。",
    content: [
      "快速编程，可通过场景规则、函数计算、流数据分析提升开发应用，并将其部署到边缘节点。",
      "可在本地对设备数据进行聚合清洗、分级处理。处理后的数据进入大数据平台进行分析存储。",
    ],
  },
};
// 登录页
export const loginPage = {
  userLogin: "用户登录",
  uMess: "请输入 [ 用户名 ]",
  pMess: "请输入 [ 密码 ]",
  login: "登 录",
  forgetPass: "忘记密码",
  reAcc: "注册账户",
  hanYun: {
    copyright:
        "版权所有：江苏徐工汉云技术股份有限公司   地址： 江苏省徐州市经济技术开发区科技路6号  电话：400-620-9999",
  },
  jiuYuan: {
    copyright:
        "版权所有：中国工程物理研究院机械制造工艺研究所",
  },
  copyright:
    "版权所有：江苏徐工汉云技术股份有限公司   地址： 江苏省徐州市经济技术开发区科技路6号  电话：400-620-9999",
  XCMG: "",
  ICP: "",
  sys: "",
};

// 设备类型管理
export const clientTypePage = {
  addType: "新增设备类型",
  terType: "设备类型",
  typeDetail: "设备类型详情",
  netMode: "连网方式",
  conProt: "连接协议",
  auMode: "认证方式",
  dataFormat: "数据格式",
  max20: "长度不能超过20个字符",
  proType: "协议类型",
  max50: "长度不能超过50个字符",
  mqtt: "MQTT主题列表",
  customize: "自定义解析",
  property: "基础属性",
  addTheme: "新增主题",
  modifyTheme: "修改主题",
  themeTab: "主题标签",
  theme: "主题",
  opeAuth: "操作权限",
  basicPro: "基础属性",
  themeTabMsg: "请输入20位以内的数字，字母，_结合",
  themeMsg: "输入不能为空格、制表符、换行符等空字符,字符长度不超过200",
  max30: "长度不能超过30个字符",
  themeFormat:
    "主题格式必须以”/”进行层级分割。”+”用于单个主题层级匹配的通配符，”#”用于主题中任意层级的通配符，${deviceSn}通配设备编号。",
  paraCon: "参数配置",
  addPara: "新增参数",
  paraName: "参数名",
  paraDes: "参数说明",
  unit: "单位",
  paraClass: "参数分类",
  editPara: "编辑参数",
  category: "所属分类",
  inputLetDasUnder: "只能输入字母、数字、中划线和下划线",
  paraClassMain: "参数分类维护",
  currentLine: "当前已有编辑行",
  cannotEmpty: "不能为空",
  paraClassName: "参数分类名称",
  paraClassNotNull: "参数分类名称不能为空！",
  paraClassLimit: "参数分类名称长度不能超过20个字符！"
};

// 设备管理
export const clientManagePage = {
  selTerType: "请选择设备类型",
  terTotal: "设备总数",
  terOnline: "在线设备",
  terOff: "离线设备",
  allTerType: "所有设备类型",
  addTer: "新增设备",
  terNumName: "设备编号/设备名称",
  authen: "认证",
  terNum: "设备编号",
  terName: "设备名称",
  terType: "设备类型",
  accPro: "接入协议",
  dataFormat: "数据格式",
  lastOn: "最后上线时间",
  SIMNum: "SIM卡号",
  isGateway: "是网关",
  detInfor: "详细信息",
  attribute: "属性",
  latestTel: "最新遥测",
  warning: "警告",
  event: "事件",
  relation: "关联",
  auditLog: "审计日志",
  getListError: "获取列表失败！",
  tokenError: "访问令牌未作修改",
  RSAError: "RSA公钥未做修改",
  succAuth: "成功修改认证",
  basicPro: "基础属性",
  authMethod: "认证方式",
  accToken: "访问令牌",
  max80: "长度不能超过80个字符",
  RSAKey: "RSA公钥",
  max50: "长度不能超过50个字符",
  terNumMsg: clientTypePage.themeTabMsg,
  manageCer: "管理认证",
  assetNum: "资产编号",
  assetName: "资产名称",
  assetType: "资产类型",
  max20: "长度不能超过20个字符",
  inCate: "行业分类",
  inSecCate: "行业二级分类",
  copyID: "复制设备ID",
  copyToken: "复制访问令牌",
  deviceRange: "设备属性范围",
  searchInput: "请输入检索条件",
  addAttr: "添加属性",
  editAttr: "修改属性",
  delNotNull: "删除项不能为空",
  clientPro: "客户端属性",
  serverPro: "服务端属性",
  sharePro: "共享属性",
  lastUpdate: "最后更新时间",
  key: "键",
  value: "值",
  latestTele: "最新遥测",
  activated: "已激活",
  cleared: "已清除",
  answered: "已应答",
  notAnswered: "未应答",
  createTime: "创建时间",
  cause: "起因",
  severity: "严重程度",
  lifeEvents: "生命周期事件",
  typeSta: "类型统计",
  eventTime: "事件时间",
  server: "服务器",
  method: "方法",
  assDir: "关联方向",
  from: "从",
  to: "到",
  outAss: "向外的关联",
  inAss: "向内的关联",
  enType: "实体类型",
  enName: "实体名称",
  searchInputType: "请输入类型查询",
  time: "时间",
  user: "用户",
  assType: "关联类型",
  addMess: "附加信息（JSON）",
  notNull: "不能为空",
  addRelation: "添加关联",
};

// 资产管理
export const sourceManagePage = {
  selSourceType: "请选择资产类型",
  totalAssets: "资产总数",
  addAss: "新增资产",
  assNumName: "资产编号/资产名称",
  assNum: "资产编号",
  assName: "资产名称",
  assType: "资产类型",
  basicPro: "基础属性",
  max20: clientManagePage.max20,
  inCate: clientManagePage.inCate,
  inSecCate: clientManagePage.inSecCate,
  max50: clientManagePage.max50,
  allAssType: "所有资产类型",
  detInfor: "详细信息",
  attribute: "属性",
  latestTel: "最新遥测",
  warning: "警告",
  event: "事件",
  relation: "关联",
  auditLog: "审计日志",
};

// 接入网关管理
export const inGatewayManagePage = {
  addGateway: "新增网关",
  gateSerName: "网关服务名称",
  startUp: "启动",
  startUpMsg: "是否启动？",
  stop: "停止",
  stopMsg: "是否停止",
  server: "服务器",
  container: "容器",
  starting: "启动中",
  notStart: "未启动",
  started: "已启动",
  proType: "协议类型",
  terType: "设备类型",
  depLoc: "部署位置",
  IpDomain: "IP/域名",
  portNum: "端口号",
  inputGateName: "请输入网关服务名称",
  inputLetNumUn: "只能输入字母、数字和下划线",
  max50: clientManagePage.max50,
  selProType: "请选择协议类型",
  selTerType: "请选择设备类型",
  inFileEncode: "输入文件编码搜索",
  selFile: "请选择文件",
  SDKFile: "SDK文件",
  selDepLoc: "请选择部署位置",
  IPAndDomain: "IP(域名)",
  selIPAndDomain: "请选择IP(域名)",
  selPortNum: "请选择端口号",
  inExecute: "执行中...",
  editGateway: "编辑网关",
};
// 规则链管理
export const ruleManagePage = {
  addRule: "新增规则链",
  importRule: "导入规则链",
  yes: "是",
  no: "否",
  ruleName: "规则链名称",
  isRootRule: "是否根规则链",
  isDebugMode: "是否调试模式",
  setRoot: "设为根",
  design: "设计",
  export: "导出",
  inputRuleName: "请输入规则链名称搜索",
  dragJSON: "将 JSON 文件拖到此处，或",
  clickImport: "点击导入",
  name: "名称",
  max15: "长度不能超过15个字符",
  enableDebug: "是否启用调试模式",
  success: "成功!",
  resetRoot: "重置根节点成功",
  fileFailCheck: "文件数据校验失败，请检查数据内容是否正确",
  JSONFailCheck: "JSON 数据转换失败，请确认数据是否是 JSON 格式！",
  editRule: "编辑规则链",
  isRuleChain: "该规则链已经是根规则链了",
};
// 规则链设计
export const ruleDesignPage = {
  saveData: "保存数据",
  clearDebugSave: "清除  debug  模式并保存",
  nodeAttr: "节点属性",
  nodeEvents: "节点事件",
  helpInfo: "帮助信息",
  edgeAttrEdit: "【边】 属性编辑",
  testScriptBasic: "测试脚本基础功能",
  pathOpt: "路径选项",
  noGraph: "没找到 graph 实例！",
  start: "开始",
  waveFilter: "滤波器",
  attrSet: "属性集",
  trans: "变换",
  action: "动作",
  integrate: "集成",
  debugMode: "是否启用调试模式",
  eventType: "事件类型",
  timeFrame: "时间范围",
  lastDay: "最近一天",
  today: "今天",
  JSScript: "请完成 JS 脚本",
  direction: "方向",
  assType: "关联类型",
  dirFrom: "方向:  从",
  assFilter: "关联过滤器",
  entityType: "实体类型",
  receiveData: "接收数据记录1",
  deviceRange: "设备属性范围",
  key: "键",
  value: "值",
  inputNum: "只能输入数字",
  selIndModel: "选择工业机理模型",
  keyName: "键名",
  success: "成功",
  fail: "失败",
  debug: "调试",
  typeSta: "类型统计",
  lifeEvents: "生命周期事件",
  error: "错误",
  device: "设备",
  assets: "资产",
  entView: "实体视图",
  tenant: "租户",
  customer: "客户",
  dashboard: "仪表板",
  acAns: "激活已应答",
  acNotAns: "激活未应答",
  clearUnac: "清除未应答",
  clearAck: "清除已应答",
  clientPro: "客户端属性",
  serverPro: "服务端属性",
  sharePro: "共享属性",
  user: "用户",
  ruleChain: "规则链",
  ruleNode: "规则节点",
  from: "从",
  to: "到",
  danger: "危险",
  important: "重要",
  minor: "次要",
  warning: "警告",
  indeterminacy: "不确定",
  external: "外部的",
  eventTime: "事件时间",
  server: "服务器",
  entity: "实体",
  messID: "消息ID",
  messType: "消息类型",
  reType: "关系类型",
  data: "数据",
  metadata: "元数据",
  messPro: "消息处理",
  errOcc: "错误发生",
  event: "事件",
  method: "方法",
  message: "消息",
  funBody: "函数体",
  returnRes: "返回结果",
  group: {
    waveFilter: "滤波器",
    attrSet: "属性集",
    trans: "变换",
    action: "动作",
    integrate: "集成",
  },
  funMsg: "消息 msg",
  funMet: "元数据 metadata",
  funRes: "运行结果 result",
  run: "运行",
  searchNode: "搜索节点",
};

// 审计日志
export const auditLogPage = {
  timeStamp: "时间戳",
  entityType: "实体类型",
  entityName: "实体名称",
  user: "用户",
};

//文件资源管理
export const documentSourceManagePage = {
  documentCode: "文件编码",
  disable: "禁用",
  enable: "启用",
  addFile: "新增文件",
  fileType: "文件类型",
  fileCode: "文件编码",
  fileDes: "文件说明",
  version: "版本",
  fileSize: "文件大小",
  lastModTime: "最后修改时间",
  inputLetDasUnder: "只能输入字母、数字、中划线和下划线",
  max50: clientManagePage.max50,
  fileVersion: "文件版本",
  fileVersionRule: "输入字母、数字、点和中划线的组合,例如 v1、V1.1 或 v-1",
  fileUpload: "文件上传",
  fileUpError: "文件未上传成功或未上传",
  fileNotNull: "文件内容不能为空！",
  fileSizeEx: "文件大小不能超过200M",
  editFile: "编辑文件",
  uploadFile: "上传文件",
};

// 菜单项
export const sourceMenus = {
  home: "首页",
  terTypeManage: "设备类型管理",
  terManage: "设备管理",
  assManage: "资产管理",
  accGateManage: "接入网关管理",
  ruleManage: "规则链管理",
  auditLog: "审计日志",
  fileResManage: "文件资源管理",
  driveManage: "驱动管理",
  modelDisManage: "模型下发管理",
  deviceStatusRule: "设备状态规则配置",
  devTypeManage: "设备类型管理",
};

// 字典项
export const DICT = {
    // 终端管理 - 状态栏样式更改
    SYSTEM_COMPANY_STATUS: [
        { key: '0', value: "在线", className: "dot--success" },
        { key: '1', value: "离线", className: "dot--error" },
    ],
    SYSTEM_CPMPANY_STYLE: [
        { key: '0', value: '订阅' },
        { key: '1', value: '发布' },
        { key: '2', value: '订阅和发布' }
    ],
    SYSTEM_SOURCETYPE: [
        { key: '0', value: '添加' },
        { key: '1', value: '同步' }
    ],
    DOCUMENT_TYPE: [
        { key: '0', value: '接入网关jar包' },
        { key: '1', value: '终端升级包' },
        { key: '2', value: '控制器升级包' },
        { key: '3', value: '显示器升级包' },
        { key: '4', value: '其他' },
    ],
    DOCUMENT_STATUS: [
        { key: '0', value: '正常' },
        { key: '1', value: '禁用' }
    ],
    ALARM_GRADE:[
      {key:"1",value:"一级"},
      {key:"2",value:"二级"},
      {key:"3",value:"三级"},
      {key:"4",value:"四级"},
    ],
    ALARM_TYPE:[
      {key:"1",value:"故障告警"},
      {key:"2",value:"阈值告警"},
      {key:"3",value:"状态超限"},
    ],
    DEVICE_STATUS:[
      {key:"1",value:"运行"},
      {key:"2",value:"待机"},
      {key:"3",value:"停机"},
      {key:"4",value:"故障"},
      {key:"101",value:"运行-切削"},
      {key:"102",value:"运行-空转"},
      {key:"103",value:"运行-空运行"},
      {key:"104",value:"运行-焊接"},
      {key:"105",value:"运行-清枪"},
      {key:"106",value:"运行-空走"},
    ],
    ALARM_RULE_TYPE:[
      {key:"1",value:"超限幅度"},
      {key:"2",value:"累积时长"},
      {key:"3",value:"累积次数"},
      {key:"4",value:"持续累积时长"},
      {key:"5",value:"连续时长"},
    ],
    NODE_ARG_CLASS:[
      {key:"1",value:"工艺参数"},
      {key:"2",value:"报警参数"}
  ]
}
/*
alarmCode (string, optional),
cycleTime (integer, optional),
id (string, optional),
ruleDesc (string, optional),
ruleType (string, optional),
sid (integer, optional),
thresholdStandard (string, optional)
*/
// 规则链节点配置
export const RULE_NODES = {
  Redis: {
    redisType: "redis 类型",
    standalone: "单机 standalone",
    sharded: "分片 sharded",
    cluster: "集群 cluster",
    maxTotal: "jedis实例数",
    maxIdle: "最大空闲数",
    maxWaitMillis: "最大等待时间",
    testOnBorrow: "是否校验链接有效",
    testWhileIdle: "是否在空闲时检查链接有效",
    password: "密码",
    hostAndPortsNodes: "集群配置",
    HostAndPort: "IP地址和端口号",
    timeout: "超时时间",
    expireSecond: "过期时间",
    dataStoreType: "存储类型",
  },
};

// 驱动管理
export const driveManagePage = {
  fileNotNull: "文件内容不能为空！",
  fileSizeEx: "文件大小不能超过200M",
  driverPro: "驱动名称/协议",
  issue: "下发",
  selGateway: "已选择网关数",
  addDriver: "新增驱动",
  driverName: "驱动名称",
  protocol: "协议",
  fileName: "文件名",
  programClass: "程序类名",
  version: "版本号",
  lastUpdateTime: "最后更新时间",
  success: "成功",
  fail: "失败",
  driveFile: "驱动文件",
  uploadFile: "上传文件",
  fileNotUpload: "文件未上传成功或未上传",
  gatewayNum: "网关编号",
  gatewayName: "网关名称",
  issuedVersion: "已下发版本",
  issueResults: "下发结果",
  editDriver: "编辑驱动",
  successDel: "删除成功!",
  issueDrive: "下发驱动",
  currentVersion: "当前版本",
  selAGateway: "请选择网关",
  max50: "长度不能超过50个字符",
  issueCom: "下发完成",
  conPara: "配置参数",
  driveDes: "驱动说明",
  fileMess: "文件内容不符合配置规范!",
  viewCon: "查看配置"
};

// 模型下发管理
export const modelIssueManagePage = {
  issue: "下发",
  selDevice: "已选择设备数",
  success: "成功",
  fail: "失败",
  issueResults: "下发结果",
  currentVersion: "当前版本",
  modelCNEN: "模型中文名称/模型英文名称",
  conDis: "配置下发",
  modelConf: "模型配置",
  issueGateway: "下发网关",
  save: "保存",
  cnName: "模型中文名称",
  enName: "模型英文名称",
  version: "版本号",
  modelDes: "模型说明",
  lastUpdate: "最后更新时间",
  thrTrigger: "阈值触发",
  thresholdMess: "请至少填写一个阈值下限或阈值上限",
  lowerThr: "阈值下限",
  lowerMess: "只能输入整数和小数",
  upperThr: "阈值上限",
  upperMess: "阈值上限不能小于阈值下限",
  thrDuration: "阈值持续时长",
  durMess: "只能输入数字",
  triInter: "触发接口调用",
  reqMode: "请求方式",
  interAdd: "接口地址",
  notNull: "不能为空",
  gateNum: "网关(设备)编号",
  gateName: "网关(设备)名称",
  pointSel: "点位选择",
  disVersion: "已下发版本",
  saveConfig: "请先保存模型配置",
  selDeviceFir: "请先选择设备",
  operSucc: "操作成功",
  refresh: "刷新",
  max50: "长度不能超过50个字符",
  max30: "长度不能超过30个字符",
  max10: "长度不能超过10个字符",
};

// 设备状态规则配置
export const deviceStatusRulePage = {
  addStatusRule: "新增状态规则",
  deviceType: "设备类型",
  deviceName: "设备名称",
  deviceNum: "设备编号",
  deviceStatus: "设备状态",
  confLevel: "配置级别",
  byDeviceType: "按设备类型",
  byDevice: "按设备",
  devNameNum: "设备名称(编号)",
  algFormula: "算法公式",
  auxDuration: "辅助运行时长(S)",
  durMess: "只能输入整数和小数",
  pointSel: "参数选择",
  avaPoint: "已选参数",
  addDeviceRule: "新增设备状态配置规则",
  editDeviceRule: "编辑设备状态配置规则",
  max50: "长度不能超过50个字符",
  max10: "长度不能超过10个字符",
  statusMaintenance: "设备状态维护",
  addStatus: "新增状态",
  addSubordinates: "添加下级",
  calMethod: "计算方式",
  accFormula: "根据公式计算",
  byParentSibling: "根据父级和同级状态推算",
  byChild: "根据子级状态推算",
  add: "添加",
  subStatus: "下级状态",
  editStatus: "编辑状态",
  deviceNameNum: "设备名称/设备编号",
};
