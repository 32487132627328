// 中文
export const decn = {
    webTitle:"汉云工业物联网平台",
    jiuYuanTitle: "工业物联网平台",
    add: '新增',
    edit: '编辑',
    delete: '删除',
    view: '查看',
    save: '保存',
    select: '选择',
    upload: '上传',
    import: '导入',
    export: '导出',
    platformName: '汉云工业物联网平台',
    userName: '用户名',
    password: '密 码',
    IIoT:"工业物联网平台",
    createTime: '创建时间',
    describe: '描述',
    pleaseSelect: "请选择",
    cancel: '取 消',
    confirm: '确 定',
    orderNum: '序号',
    msgPrefix: '请输入',
    operate: '操作',
    close: '关闭',
    delMsg: "此操作将永久删除该条数据, 是否继续?",
    tips: "提示",
    delSuccess: '删除成功',
    saveSuccess: '保存成功',
    source: "来源",
    state: "状态",
    to: "至",
    startDate: "开始日期",
    endDate: "结束日期",
    all: "所有",
    type: "类型",
    details: "详情",
    error: "错误",
    goTo: "前往",
    page: "页",
    itemPage: "条/页",
    totalItem:"共 {total} 条",
    loading: "加载中...",
    noData:"暂无数据",
    search: "查询",
    reset: "重置"
}

export const deen = {
    webTitle:"HANYUN Industrial IoT Platform",
    jiuYuanTitle: "Industrial IoT Platform",
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    view: 'view',
    save: 'Save',
    select: 'select',
    upload: 'upload',
    import: 'import',
    export: 'export',
    platformName: 'HANYUN Industrial IoT Platform',
    userName: 'user name',
    password: 'password',
    IIoT:'Industrial IoT',
    createTime: 'Create Time',
    describe: 'Describe',
    pleaseSelect: 'Please select',
    cancel: 'Cancel',
    confirm: 'Confirm',
    orderNum: 'Number',
    msgPrefix: 'Please Input',
    operate: 'Operate',
    close: 'Close',
    delMsg: 'This operation will permanently delete this data. Do you want to continue?',
    tips: 'Tips',
    delSuccess: 'Deleted successfully',
    saveSuccess: 'Save successful',
    source: "Source",
    state: 'State',
    to: "to",
    startDate: "start date",
    endDate: "end date",
    all: "All",
    type: "Type",
    details: "Details",
    error: "Error",
    goTo: "go to",
    page: "page",
    itemPage: " item per page",
    totalItem:"a total of {total} ",
    loading: "loading",
    noData: "no data",
    search: "search",
    reset: "reset"
}
