import {saveLocal,getLocal} from '@/utils/util'
//
import {parseNodes} from "../../views/components/rulesDesign/utils"

const state = {
  nodes:undefined,
  ruleList:undefined,
  ruleNodes:undefined,
  ruleForm:undefined,
  ruleDirective:undefined,
};
const getters = {
  nodes:state => state.nodes || getLocal('ruleDesign.nodes'),
  ruleList:state => state.ruleList || getLocal('ruleDesign.ruleList'),
  ruleNodes:state => state.ruleNodes || getLocal('ruleDesign.ruleNodes') ,
  ruleForm:state => state.ruleForm || getLocal('ruleDesign.ruleForm'),
  ruleDirective:state => state.ruleDirective || getLocal('ruleDesign.ruleDirective'),
};
const mutations = {
  setNodes(state,val){
    //
    state.nodes = val;
    saveLocal('ruleDesign.nodes',val)
    // 解析数据
    const {ruleList,ruleForm,ruleNodes,ruleDirective} = parseNodes(val);
    //
    state.ruleNodes = ruleNodes;
    saveLocal('ruleDesign.ruleNodes',ruleNodes)
    //
    state.ruleForm = ruleForm;
    saveLocal('ruleDesign.ruleForm',ruleForm)
    //
    state.ruleList = ruleList;
    saveLocal('ruleDesign.ruleList',ruleList)
    //
    state.ruleDirective = ruleDirective;
    saveLocal('ruleDesign.ruleDirective',ruleDirective)
  },
};



export default {
  namespaced:true,
  state,
  getters,
  mutations
}