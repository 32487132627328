import store from "@/store/index"
import { Message } from 'element-ui'
//
let ws = undefined;
//
let closeFlag = false;
let isConnect = false;
let count = 0;
//
let subscribe = { tsSubCmds: {}, attrSubCmds: {}, historyCmds: {} };
//
let callbacks = {}
const callbackLib = { tsSubCmds: undefined, attrSubCmds: undefined, historyCmds: undefined }
// socket url 连接地址前缀，注意！这里只有前缀，我之前被坑过，因为明明后端有给我返回上下文，但莫名其妙的被去除了
// const socketUrl = 'ws://10.90.9.187:10623/api/ws/plugins/telemetry?token='
// export const socketUrl = 'ws://10.90.12.246:10623/api/ws/plugins/telemetry?token='
const socketUrl = (/https/.test($location.protocol) ? 'wss://' : 'ws://') + $location.host + process.env.VUE_APP_SOCKET_URL;
//
const createSocket = (url, protocols) => {
  if ("WebSocket" in window) {
    return new WebSocket(url, protocols)
  }
  console.log('浏览器不支持 websocket ....');
}


//
// scope 类型映射
const scopeLib = {
  LATEST_TELEMETRY: 'tsSubCmds', //最新遥测
  CLIENT_SCOPE: 'attrSubCmds',  //客户端
}

const sendData = (tsSubCmds = [], attrSubCmds = [], historyCmds = []) => {
  isConnect && ws && ws.send(JSON.stringify({ tsSubCmds, attrSubCmds, historyCmds }))
}
const closeSocket = () => { }


const socket = {
  // 
  connect() {
    // 获取 websocket 实例
    if (!isConnect || !ws) {
      console.log('---------create socket------------', isConnect, ws)
      const token = store.getters.token;
      ws = createSocket(`${socketUrl}?${window.__POWERED_BY_WUJIE__ ? 'sunnyToken=' : 'token='}${token}`);
      //
      ws.onopen = () => { isConnect = true;socket.refresh(); }
      ws.onmessage = (d) => {
        if (!d) return;
        const data = JSON.parse(d.data);
        if (data.errorCode) {
          Message.error(data.errorMsg)
          return;
        }
        const ct = data.subscriptionId;
        const f = callbacks[ct]
        // console.log(data, ct, callbacks, f)
        if (f && typeof f == 'function') {
          f(data.data, data)
        }
      }
      ws.onclose = (e) => {
        // console.log('socket connect close ---- ',e,ws)
        isConnect = false;
        ws = undefined;
        // 尝试重新建立链接
        if(closeFlag){
          closeFlag = false;
        }else{
          socket.connect();
        }
        
        // try { ws = createSocket(socketUrl + store.getters.token); }
        // catch (e) { console.error(e) }
      }
      ws.onerror = (e) => { closeFlag = true;console.log('socket connect error ---- ',e,ws) }
    } else {
      socket.reset();
    }

    return ws;
  },
  close() { 
    closeFlag = true;
    isConnect && ws && ws.close(); 
  },
  // 重置 socket
  reset(calls = {}) {
    // 取消所有订阅
    const subs = { tsSubCmds: [], attrSubCmds: [], historyCmds: [] }
    Object.keys(subscribe).forEach(k => {
      Object.keys(subscribe[k]).forEach(n => {
        subs[k].push(...subscribe[k][n].map(item => ({ ...item, unsubscribe: true })))
      })
    })
    // 取消所有订阅
    sendData(subs.tsSubCmds, subs.attrSubCmds, subs.historyCmds);
    // 初始化订阅数据
    subscribe = { tsSubCmds: {}, attrSubCmds: {}, historyCmds: {} };
    // 初始化回调函数
    Object.keys(calls).forEach(k => {
      const t = scopeLib[k]
      if (t) {
        callbackLib[t] = calls[k];
      }
    })
  },

  // 
  getData(entityId, entityType = 'DEVICE', scope = 'LATEST_TELEMETRY', callback) {
    // console.log('+++++++++++++++++', entityId, entityType, scope, callback)
    const t = scopeLib[scope];
    const s = subscribe[t]
    const i = Object.keys(scopeLib).indexOf(scope)
    if (!entityId || Object.keys(s).includes(entityId)) return;
    // 构造数据
    const ds = []
    const d = [{ cmdId: ++count, entityId, entityType, scope }]
    ds[i] = d
    //
    callbacks[count] = callback || callbackLib[t]
    //
    // console.log('+++++++++++++++++', t, d, ds)
    //
    sendData(...ds)
    // 取消之前的订阅
    const p = Object.keys(s).map(k => {
      const item = s[k][0]
      return { ...item, unsubscribe: true }
    })[0]
    if (p) {
      ds[i] = p
      // console.log('++++++++unsubscribe+++++++++', p, ds)
      sendData(...ds)
    }
    //
    subscribe[t] = {}
    subscribe[t][entityId] = d;
  },
  // 刷新所有订阅
  refresh(){
    const d = Object.keys(subscribe).map(t => {
      const s = subscribe[t];
      return Object.keys(s).map(k => s[k][0])
    })
    //
    // console.log(d);
    sendData(...d);
  } 

  //
}


function install(Vue) {
  Vue.prototype.$socket = socket;
}

export default install;
