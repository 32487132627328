import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {getLocal} from '@/utils/util.js'
import locale from 'element-ui/lib/locale'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from "element-ui/lib/locale/lang/en";
import * as cn from "./lang/zh-cn"
import * as en from "./lang/en"
//
Vue.use(VueI18n)
//
const i18n =  new VueI18n({
    locale: getLocal('i18nLocal')||'zh-cn',
    messages:{'zh-cn':Object.assign(cn,zhLocale),en:Object.assign(en,enLocale)}
})
// 为了实现element插件的多语言切换
locale.i18n((key, value) => i18n.t(key, value));
//
export default i18n;
